// libraries
import * as React from "react";
import { buildRgbString } from "~/library/color-tools";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks & context
import { useSelector } from "react-redux";
// types & models
import { CWColor, ICWAlertCategoryDto, ICWColorDto } from "@algo/network-manager/models/v3/cw";
// components
import LegendItem  from "../legend-item/LegendItem";
// styles
import * as SC from "../Styled";

export type IProps = {
    //
};

export const WeatherLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();

    const activeWeatherStore: any = useSelector( (state: any) => state["weather-alert"]);
    const activeAlertsCategories: ICWAlertCategoryDto[] = activeWeatherStore.data?.map(
        (feature: any) => {
            return feature.properties.category;
        }
    ); 
    const uniqueCategories: {[key: string]: ICWAlertCategoryDto} = {};

    activeAlertsCategories?.forEach(
        (category: ICWAlertCategoryDto) => {
            let name: string = category.name || "";
            if (!uniqueCategories[name]){
                uniqueCategories[name] = category;
            }
        }
    );

    return (
        <SC.Legend>

            <SC.Title isMediumMax={isMediumMax} weather={!isSmallMax}>
                {"Weather Legend"}
            </SC.Title>

            <SC.LegendItems isMediumMax={isMediumMax} weather={!isSmallMax}>
                {   uniqueCategories &&
                    Object.keys(uniqueCategories).map(
                        (key: string, mapIndex: number) => {

                            let category: ICWAlertCategoryDto = uniqueCategories[key];

                            let alertFillColor: ICWColorDto = category.fillColor || new CWColor();
                            let alertStrokeColor: ICWColorDto = category.strokeColor || new CWColor();

                            let fillColor: string = buildRgbString(alertFillColor);
                            let strokeColor: string = buildRgbString(alertStrokeColor);
                            let name: string = category.name || "Unknown Weather Alert";

                            return (
                                <LegendItem 
                                    key={name+mapIndex} name={name}
                                    fillColor={fillColor} strokeColor={strokeColor} />
                            )
                        }
                    )
                }
            </SC.LegendItems>

        </SC.Legend>
    );
};

export default WeatherLegend;