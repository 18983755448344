// libraries
import * as React from "react";
// hooks & context
// styles
import * as SC from "./Styled";
// types & models
import { IMarkerTapObject } from "~/interfaces";
// components
import ApiObjectView from "../../api-object-details/ApiObjectView";
import ExtendedObjectDetails from "../../extended-object-details/ExtendedObjectDetails";
import { mapPageTypeToIcon } from "~/resources";
// resources
// constants

export type IProps = {
    object: IMarkerTapObject;
    doneCallback?: any;
    loading?: boolean;
};

export const ObjectDetailModal: React.FC<IProps> = (props) => {

    const { type, data } = props.object ?? {};

    const icon = mapPageTypeToIcon("traffic reports");

    return (
        <SC.StyledObjectDetailModal>

            <SC.DoneText onClick={props.doneCallback}>
                {`Done`}
            </SC.DoneText>

            {props.loading &&
                <SC.Loading>
                    <SC.Icon>{icon}</SC.Icon>
                    <SC.NoResultsText>Loading...</SC.NoResultsText>
                </SC.Loading>
            }

            {!props.loading &&
                <SC.DetailRow>
                    <ApiObjectView object={data} type={type} showChevron={false} />
                </SC.DetailRow>
            }

            {!props.loading &&
                <ExtendedObjectDetails object={data} type={type} />
            }
            

        </SC.StyledObjectDetailModal>
    );
};

export default ObjectDetailModal;