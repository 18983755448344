export const colors = {
    "bs-blue": {
        "hex": "007bff",
        "hex-string": "#007bff",
        "rgb": {
            "red": "0",
            "green": "123",
            "blue": "255"
        },
        "rgba": "rgba(0, 123, 255, 1)",
        "alpha": 1.0
    },
    "bs-indigo": {
        "hex": "6610f2",
        "hex-string": "#6610f2",
        "rgb": {
            "red": "102",
            "green": "16",
            "blue": "242"
        },
        "rgba": "rgba(102, 16, 242, 1)",
        "alpha": 1.0
    },
    "bs-purple": {
        "hex": "6f42c1",
        "hex-string": "#6f42c1",
        "rgb": {
            "red": "111",
            "green": "66",
            "blue": "193"
        },
        "rgba": "rgba(111, 66, 193, 1)",
        "alpha": 1.0
    },
    "bs-pink": {
        "hex": "e83e8c",
        "hex-string": "#e83e8c",
        "rgb": {
            "red": "232",
            "green": "62",
            "blue": "140"
        },
        "rgba": "rgba(232, 62, 140, 1)",
        "alpha": 1.0
    },
    "bs-red": {
        "hex": "dc3545",
        "hex-string": "#dc3545",
        "rgb": {
            "red": "220",
            "green": "53",
            "blue": "69"
        },
        "rgba": "rgba(220, 53, 69, 1)",
        "alpha": 1.0
    },
    "bs-orange": {
        "hex": "fd7e14",
        "hex-string": "#fd7e14",
        "rgb": {
            "red": "253",
            "green": "126",
            "blue": "20"
        },
        "rgba": "rgba(253, 126, 20, 1)",
        "alpha": 1.0
    },
    "bs-yellow": {
        "hex": "ffc107",
        "hex-string": "#ffc107",
        "rgb": {
            "red": "255",
            "green": "193",
            "blue": "7"
        },
        "rgba": "rgba(255, 193, 7, 1)",
        "alpha": 1.0
    },
    "bs-green": {
        "hex": "28a745",
        "hex-string": "#28a745",
        "rgb": {
            "red": "40",
            "green": "167",
            "blue": "69"
        },
        "rgba": "rgba(40, 167, 69, 1)",
        "alpha": 1.0
    },
    "bs-teal": {
        "hex": "20c997",
        "hex-string": "#20c997",
        "rgb": {
            "red": "32",
            "green": "201",
            "blue": "151"
        },
        "rgba": "rgba(32, 201, 151, 1)",
        "alpha": 1.0
    },
    "bs-cyan": {
        "hex": "17a2b8",
        "hex-string": "#17a2b8",
        "rgb": {
            "red": "23",
            "green": "162",
            "blue": "184"
        },
        "rgba": "rgba(23, 162, 184, 1)",
        "alpha": 1.0
    },
}

// template 
// "color-name": {
//     "hex": "000000",
//     "hex-string": "#000000",
//     "rgb": {
//         "red": "0",
//         "green": "0",
//         "blue": "0"
//     },
//     "rgba": "rgba(0, 0, 0, 1)",
//     "alpha": 1.0
// }