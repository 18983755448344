// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import AutoSizer from 'react-virtualized-auto-sizer';
// styles
import * as SC from "./Styled";
// types & models
import { ATCamera, IATCameraDto } from "@algo/network-manager/models/v3";
// components
import Snapshot from "~/components/views/snapshot/Snapshot";
import VideoboardModal from "~/components/views/modal/videoboard-modal/VideoboardModal";
import VideoboardManager from "~/components/views/videoboard/VideoboardManager";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
// constants
import { SS_ROW_HEIGHT, SS_WIDTH } from "~/constants";
import { GridOnScrollProps } from "react-window";

export type IProps = {
    objectList?: any[]
};

export const SnapshotSection: React.FC<IProps> = (props) => {

    const videoboardModalContext: any = React.useContext(VideoboardModalContext);

    const playOneClickHandler = (camera: IATCameraDto) => {
        const SingleVM: VideoboardManager = new VideoboardManager([camera]);

        videoboardModalContext.setModalContent(
            <VideoboardModal 
                videoboard={SingleVM.videoboard}
                doneCallback={() => videoboardModalContext.setShowModal(false)} />
        );
        videoboardModalContext.setShowModal(true);
    }
    
    const [lastRowHeight, setLastRowHeight] = React.useState(0);
    
    const updateLastRowHeight = (contentHeight: number) => {
        // Set the height of the last row based on the content height
        setLastRowHeight(contentHeight);
      };
    
    if (!nuonoe(props.objectList)) return null;

    return (
        <SC.Container>
            <SC.StyledAutoSizer>
                <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => { 
                        const itemCount = props.objectList ? props.objectList.length : 0;
                        const columnNumber = Math.floor(width / (SS_WIDTH + 30)) != 0 ? Math.floor(width / (SS_WIDTH + 30)) : 1;
                        const rowCount = Math.ceil(itemCount / columnNumber);
                        const columnWidth = Math.floor(width / columnNumber);

                        return(
                            <SC.StyledGrid
                                columnCount={columnNumber}
                                rowCount={rowCount + 1}
                                height={height}
                                width={width}
                                columnWidth={columnWidth}
                                rowHeight={SS_ROW_HEIGHT}
                            >
                                {
                                    ({ columnIndex, rowIndex, style }) => {
                                        const index = rowIndex * columnNumber + columnIndex;
                                        const camera: IATCameraDto = props.objectList && index >= 0 ? props.objectList[index] : null;
                                        
                                        if(rowIndex === rowCount){
                                            if(columnIndex === 0){
                                                return (
                                                    <div style={{...style, width: '100%', height: lastRowHeight}}>
                                                        <SC.CameraDisclaimer ref={(disclaimerRef) => disclaimerRef && updateLastRowHeight(disclaimerRef.getBoundingClientRect().height)}>
                                                            ALGO Traffic cameras are a public safety tool for use in real time and not intended to be recorded or 
                                                            otherwise used for any commercial purpose. All unauthorized photography, recording, storing, or 
                                                            transmitting of visual material, data, or information gathered from ALGO traffic cameras without the 
                                                            permission of ALDOT is expressly prohibited.
                                                        </SC.CameraDisclaimer>
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null;
                                            }
                                        }
                                        return (
                                            <div style={style}>
                                                {camera && (
                                                    <SC.SnapshotWrap>
                                                        <Snapshot
                                                            key={camera.id}
                                                            camera={new ATCamera(camera)}
                                                            showPlayButton={true}
                                                            clickCallback={() => {
                                                                playOneClickHandler(camera);
                                                            }}
                                                        />
                                                    </SC.SnapshotWrap>
                                                )}
                                            </div>
                                        );
                                    }
                                }
                            </SC.StyledGrid>

                        )
                    }}
                </AutoSizer>
            </SC.StyledAutoSizer>
            {/* {isAtBottom &&
                <SC.CameraDisclaimer>
                    ALGO Traffic cameras are a public safety tool for use in real time and not intended to be recorded or 
                    otherwise used for any commercial purpose. All unauthorized photography, recording, storing, or 
                    transmitting of visual material, data, or information gathered from ALGO traffic cameras without the 
                    permission of ALDOT is expressly prohibited.
                </SC.CameraDisclaimer>
            } */}
        </SC.Container>
    );
};

export default SnapshotSection;