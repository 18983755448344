import { useEffect } from "react";

export const useResetReCaptcha = (recaptchaRef: any) => {

    // reset the captcha instance on component load
    useEffect(
        () => {
            recaptchaRef.current.reset();
        }, []
    );
}