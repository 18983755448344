// libraries
import * as React from "react";
import { IATVideoBoardDto, IATVideoPageDto
} from "@algo/network-manager/models/v3";
// hooks & context
import { useBreakpoint } from "~/library/useBreakpoint";
import { useStreamTimeout } from "./useStreamTimeout";
// styles
import * as SC from "./Styled";
// types & models
// components
import VideoboardManager from "./VideoboardManager";
import VBUpperUI from "./upper-ui/VBUpperUI";
import VBLowerUI from "./lower-ui/VBLowerUI";
import { VBTimeoutCard } from "./timeout-card/VBTimeoutCard";
import VideoGrid from "./video-grid/VideoGrid";
import { AlgoTrafficLogo } from "../algo-traffic-logo/AlgoTrafficLogo";
// constants
import { PAGE_DURATION } from "~/constants";

export type IProps = {
    videoboard?: IATVideoBoardDto;
    streamTimeout?: number;
    closeCallback: () => void;
};

export const Videoboard: React.FC<IProps> = (props) => {

    const { videoboard, streamTimeout, closeCallback } = props;
    const pages = videoboard?.pages || [];
    const pageCount = pages?.length || 0;

    const [ lockOverlays, setLockOverlays ] = React.useState<boolean>(true);
    const [ fullScreen, setFullScreen ] = React.useState<boolean>(false);
    const [ viewingTimeout, setViewingTimeout ] = React.useState<boolean>(false);
    const [ watchTimerKey, setWatchTimerKey ] = React.useState<number>(Math.random());
    const [ curPageIndex, setCurPageIndex ] = React.useState<number>(0);
    const [ lastPageIndex, setLastPageIndex ] = React.useState<number>(pageCount - 1);

    const { sm: isSmallMax } = useBreakpoint();
    const privilegeLevelTimeout: number = useStreamTimeout();

    // timeout value should be overwritten by prop if passed in,
    // otherwise use privilegeLevelTimeout
    const timeoutValue: number = streamTimeout || privilegeLevelTimeout || 0;

    const [ fullTimeoutCount, setFullTimeoutCount ] = React.useState<number>(0);

    const VM: VideoboardManager = new VideoboardManager(videoboard || []);

    const doc: any = document;
    const fullScreenAvailable = doc.fullscreenEnabled || 
                            doc.mozFullscreenEnabled ||
                            doc.webkitFullscreenEnabled ||
                            doc.msFullscreenEnabled;

    // function triggered when user clicks fullscreen button
    const fullScreenCallback = () => {
        let videoGrid = document.getElementById("videoBoard") as HTMLElement;
        let element: any = videoGrid;
        if(!fullScreen){
            if(doc.fullscreenEnabled)
                videoGrid.requestFullscreen();
            else if(doc.msFullscreenEnabled)
                element.msRequestFullscreen();
            else if(doc.mozFullscreenEnabled)
                element.mozRequestFullScreen();
            else if(doc.webkitFullscreenEnabled)
                element.webkitRequestFullscreen();
        }
        else{
            if(doc.fullscreenEnabled)
                document.exitFullscreen();
            else if(doc.msFullscreenEnabled)
                doc.msExitFullscreen();
            else if(doc.mozFullscreenEnabled)
                doc.mozCancelFullScreen();
            else if(doc.webkitFullscreenEnabled)
                doc.webkitCancelFullScreen();
        }
    }

    // listens for full screen open/close to set fullscreen const (used for button icon)
    document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
            setFullScreen(true);
        } else {
            setFullScreen(false);
        }
    });
    document.addEventListener('MSFullscreenChange', (event) => {
        if (document.fullscreenElement) {
            setFullScreen(true);
        } else {
            setFullScreen(false);
        }
    });

    // function triggered when user's allotted viewing time runs out
    const timeoutCallback = () => {
        //Calculate total number of pages to loop through
        if(timeoutValue > 60 && fullTimeoutCount === 0){
            setFullTimeoutCount(Math.ceil(timeoutValue / 60 ));
        }
        
        //logic for next page or timeout popup
        if(curPageIndex !== lastPageIndex || fullTimeoutCount > 1 || timeoutValue === -1){
            if(curPageIndex == (pageCount - 1))
                setCurPageIndex(0);
            else
                setCurPageIndex(curPageIndex => curPageIndex  + 1);
            
            setWatchTimerKey(Math.random());

            if(fullTimeoutCount > 1 && pageCount > 1)
                setFullTimeoutCount(fullTimeoutCount => fullTimeoutCount - 1);
        }
        //if timeout on final page
        else{
            setViewingTimeout(true);
        }
    }

    // function triggered when user clicks the play button
    const playCallback = () => {
        Object.keys(VM.playerMap).map(
            (key: string) => {
                if(VM.playerMap[key]){
                    if(VM.playerMap[key].paused()){
                        VM.playerMap[key].play();
                    }
                    else{
                        VM.playerMap[key].pause();
                    }
                }
            }
        );
    }

    // function triggered when user clicks the resume button
    const resumeCallback = () => {
        setViewingTimeout(false);
        if(lastPageIndex == (pageCount - 1))
            setCurPageIndex(0);
        else
            setCurPageIndex(lastPageIndex + 1);
        setWatchTimerKey(Math.random());
    };

    const pageSelectCallback = (newIndex: number) => {
        if(newIndex == 0){
            setLastPageIndex(pageCount - 1);
        }
        else{
            setLastPageIndex(newIndex - 1);
        }
        setFullTimeoutCount((timeoutValue !== -1 && timeoutValue > 60) ? Math.ceil(timeoutValue / 60 ) : 0);
        setCurPageIndex(newIndex);
        setWatchTimerKey(Math.random());
    };


    if (!videoboard || pageCount === 0) 
        return null;

    const curPage: IATVideoPageDto | null = VM.getPageByNumberOrNull(curPageIndex);
    const twoVideos: boolean = curPage?.items.length === 2 ? true : false;

    return (
        <SC.StyledVideoboard id="videoBoard">

            <SC.UpperUIRow>

                {   videoboard.name &&
                    <SC.VideoboardTitle>
                        {videoboard.name}
                    </SC.VideoboardTitle>
                }

                <SC.UpperUI>
                    <VBUpperUI closeCallback={() => {closeCallback()}} fullScreenAvailable={fullScreenAvailable}
                        lockOverlays={lockOverlays} setLockOverlays={setLockOverlays}
                        fullScreen={fullScreen} setFullScreen={fullScreenCallback} />
                </SC.UpperUI>

            </SC.UpperUIRow>

            <SC.VideoAndTimeoutRow>

                {viewingTimeout 
                    ?   <SC.TimeoutItem fullscreen={fullScreen} twoVideos={twoVideos}>
                            <VBTimeoutCard resumeCallback={resumeCallback} />
                        </SC.TimeoutItem>
                    :   <VideoGrid lockOverlays={lockOverlays}
                            page={curPage}
                            updatePlayerMap={VM.updatePlayerMap}
                            showRouteOverlay={true}
                            fullscreen={fullScreen}
                        />
                }

            </SC.VideoAndTimeoutRow>

            { !isSmallMax && <AlgoTrafficLogo bottom={"20px"} left={"20px"} /> } 

            <SC.LowerUIRow>

                {   !viewingTimeout &&
                    <VBLowerUI 
                        playCallback={playCallback}
                        pages={videoboard.pages || []} activePageIndex={curPageIndex}
                        pageSelectCallback={pageSelectCallback}
                        watchTimerKey={watchTimerKey}
                        streamTimeout={
                            pageCount == 1 ? 
                            timeoutValue : 
                            videoboard.pageOnSeconds || PAGE_DURATION} 
                        timeoutCallback={timeoutCallback}
                    />
                }

            </SC.LowerUIRow>

        </SC.StyledVideoboard>
    )
};

export default Videoboard;