// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { IATMessageSignLine, IATMessageSignPage } from "@algo/network-manager/models/v3";
// components
import MessageSignLine from "./message-sign-line/MessageSignLine";

export type IProps = {
    page: IATMessageSignPage;
    incrementPageIndex: () => void;
};

export const MessageSignPage: React.FC<IProps> = (props) => {

    const { page, incrementPageIndex } = props;

    const lineCount: number = page.lines?.length || 0;

    const [ pageIsOn, setPageIsOn ] = React.useState<boolean>(true);

    React.useEffect(
        () => {

            let pageOnTimeoutId: NodeJS.Timeout | null = null;
            let pageOffTimeoutId: NodeJS.Timeout | null = null;

            let pageOnSeconds: number = page.pageOnSeconds || 0;
            let pageOffSeconds: number = page.pageOffSeconds || 0;

            if (!pageOnSeconds){
                incrementPageIndex();
                setPageIsOn(true);
            }

            else {
                // wait for pageOnSeconds number of seconds...
                pageOnTimeoutId = setTimeout(
                    () => {
                        // then turn the page content off and wait for pageOffSeconds...
                        setPageIsOn(false);
                        pageOffTimeoutId = setTimeout(
                            () => {
                                // then increment the page list
                                incrementPageIndex();
                                setPageIsOn(true);
                            }, pageOffSeconds * 1000
                        )
                    }, 
                    pageOnSeconds * 1000
                )
            }

            // cleanup function
            return () => {
                if (pageOnTimeoutId) clearTimeout(pageOnTimeoutId);
                if (pageOffTimeoutId) clearTimeout(pageOffTimeoutId);
            }
        }, [page]
    );

    return (
        <SC.StyledMessageSignPage>

            <SC.LinesWrapper pageIsOn={pageIsOn}>

                {lineCount > 0 && page.lines.map(
                    (line: IATMessageSignLine, index: number) => {
                        return <MessageSignLine key={`${line.text}${index}`} line={line} />
                    }
                )}

            </SC.LinesWrapper>

        </SC.StyledMessageSignPage>
    );
};

export default MessageSignPage;