// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";
import { whenSmallMax } from "~/theme";

export const UpperUI = styled.div`
    z-index: 10000;
    opacity: 0;
    transition: opacity 0.5s;
`;

export const LowerUIRow = styled.div`
    height: 0px;
    width: 100%;
    z-index: 10000;

    ${flexSmart("center center")}

    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.5s;
`;

export const StyledVideoboard = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("space-between center")}
    flex-direction: column;

    box-sizing: border-box;

    background-color: black;

    &:hover ${LowerUIRow} {
        opacity: 1;
    }

    &:hover ${UpperUI} {
        opacity: 1;
    }
`;

export const UpperUIRow = styled.div`
    width: 100%;
    height: 0px;

    ${flexSmart("space-between flex-start")}

    box-sizing: border-box;

    > :last-child{
        margin-left: auto;
    }
`;

export const VideoboardTitle = styled.div`
    max-height: 100px;
    max-width: calc(100% - 80px);

    ${flexSmart("center center")}

    padding: 14px;
    box-sizing: border-box;

    font-size: 17px;
    line-height: 22px;
    font-weight: 600;

    border-radius: ${BORDER_RADIUS}px;

    color: #fff;

    background-color: rgba(30, 30, 30, 0.75);

    ${whenSmallMax(`
        font-size: 14px;
        line-height: 15px;
    `)}
`;

export const VideoAndTimeoutRow = styled.div`
    width: 100%;
    height: 100%;

    ${flexSmart("center center")}
`;

export const TimeoutItem = styled.div<{fullscreen?: boolean, twoVideos?: boolean}>`
    ${props => props.fullscreen ? 
        `
            width: 100%;
            aspect-ratio: 16:9;
        ` 
        : 
        `
            width: 1280px;
            aspect-ratio: ${props.twoVideos ? '16/4.5' : '16/9'};
        `}

    ${flexSmart("center center")}

    box-sizing: border-box;
`;

export const TimeoutCard = styled.div`
    width: 258px;
    height: 85px;

    ${flexSmart("space-around center")}
    flex-direction: column;

    padding: 10px;
    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;

    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const TimeoutText = styled.div`
    
`;

export const TimeoutButton = styled.button`
    border-radius: 8px;
    border-width: 0px;

    padding: 4px 8px;
    box-sizing: border-box;

    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 128, 93, 1);

    :hover{
        cursor: pointer;
    }
`;