// libraries
import * as React from "react";
import { clearSubmitSuccess, createFeedback } from "~/store/algo-api/slices/feedback";
// hooks & context
import { useResetReCaptcha } from "./hooks";
// styles
import * as SC from "./Styled";
// types & models
import { ATFeedbackFormObject, EInputFieldName, IATFeedbackFormObject } from "./ATFeedbackFormObject";
// components
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
// constants
const captchaErr: string = "The form cannot be submitted without a valid reCAPTCHA value."
const nameErr: string = "Name field must contain a value, with more than 1 character.";
const emailErr: string = "The email provided is invalid.";
const subjectErr: string = "The subject field must contain a value.";
const commentsErr: string = "The comments field must contain a value.";

// below is injected recaptcha api key for ReCaptcha component
declare var __RECAPTCHA_SITE_KEY__: string;

export type IProps = {
    //
};

export const CommentsForm: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const dispatch = useDispatch();

    // track the feedback store loading state
    const feedbackStore: any = useSelector( (state: any) => state.feedback);
    const loading: boolean = feedbackStore.loading;
    const submitSuccess: boolean = feedbackStore.submitSuccess;

    // tracks the reCAPTCHA element to get value for form submission validation
    const recaptchaRef = React.createRef<any>();

    // input states
    const [name, setName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [company, setCompany] = React.useState<string>("");
    const [subject, setSubject] = React.useState<string>("");
    const [comments, setComments] = React.useState<string>("");

    // object constructed from the input fields, used for validation and subsequently 
    // for building an ATFeedback object to dispatch to the API
    const [feedbackFormObject, setFeedbackFormObject] = 
        React.useState<IATFeedbackFormObject>(new ATFeedbackFormObject());

    // handles the logic that occurs when the User attempts to submit the Comments/Feedback form
    const submitCommentForm = () => {

        // build a validation object out of the current state values
        let newFeedbackFormObject: IATFeedbackFormObject = 
            new ATFeedbackFormObject({name, email, company, subject, comments});

        // extracts the current 'value' from the recaptcha element
        // if valid, should be some type of giant string value, otherwise undefined
        let recaptchaValue: string = recaptchaRef?.current?.getValue();

        // if the form content is valid and recaptcha contains a value
        if (newFeedbackFormObject.isValid() && recaptchaValue){
            // submit the form
            dispatch(createFeedback(newFeedbackFormObject) as any);
        }
        else {
            // update the state value of form to trigger visual representation of errors
            let errorMsg: string = "";

            if (!recaptchaValue){
                errorMsg += captchaErr;
            }

            else {
                errorMsg += "The form contains invalid input: \n";
                if (!newFeedbackFormObject.isValidName()) 
                    errorMsg += "\t" + nameErr + "\n";
                if (!newFeedbackFormObject.isValidEmail())
                    errorMsg += "\t" + emailErr + "\n";
                if (!newFeedbackFormObject.isValidSubject())
                    errorMsg += "\t" + subjectErr + "\n";
                if (!newFeedbackFormObject.isValidComments())
                    errorMsg += "\t" + commentsErr + "\n";
            }

            setFeedbackFormObject(newFeedbackFormObject);
            alert(errorMsg);
        }
    };

    // handles logic that needs to fire when the value of a form field changes
    const inputChangeHandler = (evt: any, fieldName: EInputFieldName) => {

        // get the current form field value
        let newVal: string = evt.currentTarget.value;

        // update the respective field value in state
        // if we have a valid feedbackFormObject already in state, and
        // the given field is a validated field, then update the corresponding isValid prop
        switch(fieldName){

            case EInputFieldName.name:
                if (feedbackFormObject) feedbackFormObject.isValidName(newVal);
                setName(newVal);
                break; 
            case EInputFieldName.email:
                if (feedbackFormObject) feedbackFormObject.isValidEmail(newVal);
                setEmail(newVal);
                break;
            case EInputFieldName.company:
                setCompany(newVal);
                break;
            case EInputFieldName.subject:
                if (feedbackFormObject) feedbackFormObject.isValidSubject(newVal);
                setSubject(newVal);
                break;
            case EInputFieldName.comments:
                if (feedbackFormObject) feedbackFormObject.isValidComments(newVal);
                setComments(newVal);
                break;
            default:
                return;
        }
    };

    // reset the recaptcha on component initial render to prevent disappearing bug
    useResetReCaptcha(recaptchaRef);

    // check if there has been a successful submission, if so, reset all form values
    React.useEffect( 
        () => {

            if (submitSuccess){
                alert("Submitted Successfully!");
                dispatch(clearSubmitSuccess() as any);
                recaptchaRef.current.reset();
                setName("");
                setEmail("");
                setCompany("");
                setSubject("");
                setComments("");
            }

        }, [submitSuccess]
    );

    return (
        <SC.Form id="comments-form">

            <SC.FormRow>

                <SC.FormInput 
                    isValid={feedbackFormObject.nameValid} 
                    placeholder="Name" value={name} 
                    onChange={(evt) => inputChangeHandler(evt, EInputFieldName.name)} />

                <SC.FormInput 
                    isValid={feedbackFormObject.emailValid} 
                    placeholder="Email" value={email} 
                    onChange={(evt) => inputChangeHandler(evt, EInputFieldName.email)} />

                <SC.FormInput  
                    placeholder="Company(Optional)" value={company} 
                    onChange={(evt) => inputChangeHandler(evt, EInputFieldName.company)} />

            </SC.FormRow>

            <SC.FormRow>

                <SC.FormInput 
                    isValid={feedbackFormObject.subjectValid} 
                    placeholder="Subject" value={subject} 
                    onChange={(evt) => inputChangeHandler(evt, EInputFieldName.subject)} />

            </SC.FormRow>

            <SC.FormRow>

                <SC.FormTextArea 
                    isValid={feedbackFormObject.commentsValid} 
                    placeholder="Comments" value={comments} 
                    onChange={(evt) => inputChangeHandler(evt, EInputFieldName.comments)} />

            </SC.FormRow>

            <SC.FormSubmitRow>

                <ReCAPTCHA ref={recaptchaRef} sitekey={__RECAPTCHA_SITE_KEY__} />                    

                {   loading 
                    ?   <FontAwesomeIcon icon={faSpinner} spin={true} color={"green"} size={"2x"} /> 
                    :   <SC.SubmitButton onClick={submitCommentForm}>{"Submit"}</SC.SubmitButton>
                }

            </SC.FormSubmitRow>

        </SC.Form>
    );
};

export default CommentsForm;