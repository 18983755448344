// libraries
import styled from "styled-components";
import { whenSmallMax } from "~/theme";

export const TitleItem = styled.div<{lockOverlays?: boolean}>`
    opacity: ${props => props.lockOverlays ? '1' : '0'};
    transition: opacity 0.5s;
`;

export const VideoGrid = styled.div<{oneColumn?: boolean, fullscreen?: boolean, twoVideos?: boolean}>`
    width: ${props => props.fullscreen ? '100%' : '1280px'};
    ${props => props.fullscreen ? !props.twoVideos ? 'height: 100%;' : '' : ''}

    box-sizing: border-box;
    
    display: grid;
    grid-template-columns: repeat(${props => props.oneColumn ? 1 : 2}, 1fr);
    grid-auto-rows: auto;

    &:hover ${TitleItem} {
        opacity: 1;
    }

    ${whenSmallMax(`
        width: 100%;
    `)}
`;

export const VideoItem = styled.div<{fullscreen?: boolean, twoVideos?: boolean}>`
    position: relative;
    ${props => props.fullscreen ? !props.twoVideos ? '' : 'aspect-ratio: 16/9' : 'aspect-ratio: 16/9'};
    width: 100%;
    height: 100%;
`;