// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, BOX_SHADOW, CARD_PADDING } from "~/constants";

export const StyledStartEndSection = styled.div<{isSmallMax?: boolean}>`
    height: 86px;
    min-width: 250px;

    padding: 15px 15px;
    box-sizing: border-box;
    pointer-events: auto;

    border-radius: ${BORDER_RADIUS}px;
    box-shadow: ${BOX_SHADOW};

    ${flexSmart("space-between center")}

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    :hover {
        cursor: pointer;
    }

    ${props => props.isSmallMax ? `width: 350px;` : `width: 387px;`}
`;

export const IconColumn = styled.div`
    height: 100%;
    width: 20px;
    ${flexSmart("center center")}
    flex-direction: column;
`;

export const LocationColumn = styled.div`
    height: 100%;
    width: calc(100% - 70px);

    ${flexSmart("space-between center")}
    flex-direction: column;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

export const Location = styled.div`
    width: 100%;
    ${flexSmart("start center")}
`;

export const LocationText = styled.span<{styleOverwrite?: string}>`
    width: 98%;
    color: ${props => props.theme.colors["text-primary"].rgba};
    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    border-top: thin solid #C7C7CC;
`;

export const RefreshColumn = styled.div`
    height: 100%;
    width: 20px;
    ${flexSmart("center center")}
    flex-direction: column;

    > {
        :hover{
            cursor: pointer;
        }

        :focus{
            outline: none;
        }
    }
`;

