// libraries
import { useEffect } from "react";
import { useSelector } from "react-redux";
// types & models
import { EAlgoLayerType } from "~/interfaces";
import { IMapLayers } from "~/store/map-layers/map-layers";

// a hook for watching changes in a given map layer display status
// and adds/removes objects to/from map as necessary
export const useFeatureToggle = (
    type: EAlgoLayerType,
    feature: H.map.Group | H.map.Marker | H.map.Polygon | null
) => {

    // watch map layers
    const mapLayers: IMapLayers = 
        useSelector( (state: any) => state["map-layers"].mapLayers );
        
    // check if given layer type is active
    const isActive: boolean = mapLayers[type];

    useEffect(
        () => {

            // if map exists, group exists and layer is active
            if (feature && isActive){
                feature.setVisibility(true);
            }
            // if map exists, group exists and layer inactivated
            else if (feature && !isActive){
                feature.setVisibility(false);
            }

            // cleanup logic
            return () => {
                //
            };

        },[feature, isActive]
    );

};