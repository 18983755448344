import { absoluteBuilder, flexSmart } from "@caps-mobile/styled-lib"
import { BORDER_RADIUS } from "~/constants";
import * as BP from "./breakpoints";
import globals from "./globals";

export const Navbar = (colors: any) => {

    return {
        navbar: `
            width: 100%;
            height: 96px;

            ${flexSmart("space-between center")}
            background-color: #FFFFFF;

            font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;
        `,
        logo: `
            ${flexSmart("flex-start center")}
        `,
        routes: {
            routes: `
                ${flexSmart("space-between center")}

                ${BP.whenLargeMax(`
                    display: none;
                `)}
            `
        },
        utilSection: {
            utilSection: `
                ${flexSmart("flex-end center")}
                
            `,
            utilButtons: `

                ${flexSmart("flex-end center")}
                width: 100%;

                ${BP.whenXLargeMax(`
                    display: none;
                `)}
            `
        },
        menuSection: {
            menuSection: ``,
            menuButton: `
                ${flexSmart("center center")}
            
                :hover{
                    cursor: pointer;
                }
            
                ${BP.whenXLargeMin(`
                    display: none;
                `)}
            `,
            menu: {
                menuWrapper: `
                    ${globals.globals['page-level-wrapper']}
                `,
                menu: `
                    ${absoluteBuilder(null, ["96px", "auto", "auto", "0"])}
                    z-index: 999;

                    height: auto;
                    width: 100%;

                    ${flexSmart("start center")}

                    padding: 10px 0;

                    background-color: ${colors['bg-menu']['hex-string']};

                    color: ${colors['text-primary']['hex-string']};

                    border-bottom: thin solid rgba(0,0,0,.25);

                    ${BP.whenXSmallMax(`
                        ${flexSmart("center start")}
                        flex-direction: column;
                    `)}

                `,
                "menu-header": `
                    ${flexSmart("start center")}

                    margin-bottom: 10px;

                    font-weight: 600;
                    font-size: 16px;
                    line-height: 25px;
                `,
                siteLink: {
                    wrapper: `
                        position: relative;

                        width: auto;
                    
                        ${flexSmart("center start")}
                        flex-direction: column;
                        
                        padding: 0 144px;
                            
                        ${BP.whenMediumMax(`
                            padding: 0 48px;
                        `)}
                    
                        ${BP.whenSmallMax(`
                            padding: 0 36px;
                        `)}
                    
                        ${BP.whenXSmallMax(`
                            padding: 0 18px;
                            width: calc(100% - 36px);
                        `)}
                    `,
                    siteLink: `
                        width: auto;

                        ${flexSmart("start center")}

                        padding: 10px;
                        margin: 8px 0;
                    
                        color: ${colors["text-primary"]["rgba"]};
                        text-decoration: none;
                        
                        :hover{
                            cursor: pointer;
                            background-color: ${colors["bg-highlight"]["rgba"]};
                            color: ${colors["text-primary"]["rgba"]};
                            border-radius: ${BORDER_RADIUS}px;
                        }
                    `,
                    icon: `
                        ${flexSmart("center center")}
                        color: ${colors["text-primary"]["hex-string"]};
                        margin-right: 10px;
                    `,
                    label: `
                        margin-left: 0px;
                    `,
                    selected: `
                        background-color: ${colors["bg-highlight"]["rgba"]};
                        color: ${colors["text-primary"]["rgba"]};
                        border-radius: ${BORDER_RADIUS}px;
                    `
                },
                "line-rule": `
                    ${absoluteBuilder(null, [0,0,0,0])}
                    z-index: -1;
                    
                    border-right: thin solid ${colors["border-secondary"]["rgba"]};
                
                    ${BP.whenXSmallMax(`
                        ${absoluteBuilder(null, ["auto", "10px", 0, "10px"])}
                
                        border-right: none;
                        border-right: thin solid ${colors["border-secondary"]["rgba"]};
                    `)}
                `
            }
        }
    };
}

export default Navbar;