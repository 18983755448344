 //libraries
import * as React from 'react';
import styled from 'styled-components';
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
// hooks & contexts
import { useInitializePlayer } from './hooks';
//interfaces
export type IProps = {
    config?: VideoJsPlayerOptions;                                      // config option fed to videojs libarary
    rounded?: boolean;                                                  // boolean for rounding component corners (rounds control bar as well)
    playButton?: boolean;                                               // boolean for showing the standard vjs playbutton (centered)
    videoClassNames?: string;                                           // allows you to append vjs class names to the video component
    videoDimensions?: {width: string, height: string};                  // customize the size of the video component
    playerCallback?: (player: VideoJsPlayer) => void;                   // hand the player object back to the parent
} & React.AllHTMLAttributes<HTMLElement>;

export const VideoJs: React.FC<IProps> = (props) => {
           
    /*********************************************
        PROPS
     ********************************************/
    const {
        id, className, style,
        config, rounded, videoClassNames, playButton,
        videoDimensions, playerCallback
    } = {...props};

    const d_config = config || DefaultConfig;
    
    /*********************************************
        REFS
     ********************************************/
    const videoRef = useInitializePlayer(d_config, playerCallback);

    const videoClassName: string = 
        `video-js ` +
        `${playButton 
            ? "vjs-big-play-centered " 
            : ""
        }` +
        `${videoClassNames ? videoClassNames : ""}`;


    return (
        <StyledVideoJs id={id} className={className} style={style} rounded={rounded}>
            <div 
                data-vjs-player 
                style={{width: videoDimensions?.width, height: videoDimensions?.height}}
            >
                <video id={"videoElement"} style={{objectFit: "fill", width: videoDimensions?.width, height: videoDimensions?.height}}
                    width={videoDimensions?.width} height={videoDimensions?.height} 
                    ref={videoRef} className={videoClassName} playsInline muted
                />
            </div>
        </StyledVideoJs>
    );
}

export default VideoJs;


// apply a rounded styling to the component if prop provided
export const StyledVideoJs = styled.div<{rounded?: boolean}>`
    ${props => `
        ${props.rounded 
            ? `
                & .video-js {
                    border-radius: 1rem;
                    border-radius: 1rem;
        
                    & video {
                        border-radius: 1rem;
                    }
                }
        
                & .video-js .vjs-control-bar {
                    border-bottom-left-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                }
            `
            : ``
        }
    `}

    .vjs-poster {
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
`;

// This is a ready-made configuration object for 
// testing the above VideoJs component
export const DefaultConfig = {
    autoplay: true,
    controls: true,
    sources: [{
        src: "https://wowzaprod280-i.akamaihd.net/hls/live/1021747/83c14890/playlist.m3u8",
        type: 'application/x-mpegURL'
    }],
};