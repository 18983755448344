// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const RegionalEventGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "156"} height={height || "147"} 
            viewBox={ viewBox || "0 0 156 147" } fill="none" 
        >
            <path fill={color || "white"}
                d="M77.9999 118.139L125.817 147L113.126 92.61L155.371 56.014L99.7419 51.296L77.9999 0L56.2579 51.296L0.628906 56.014L42.8739 92.61L30.1829 147L77.9999 118.139Z"
            />
        </svg>
    );
};

export default RegionalEventGlyph;