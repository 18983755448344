// libraries
import { Fragment } from "react";
// enums
import { IDocObject } from "~/interfaces";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
import { Header } from "~/components/views/page-template/Styled";
// components
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";
// constants
import capsLogo from "~/resources/ui/logos/logo-caps.png";

const DOC_STRINGS: IDocObject[] = [
    {
        icon: { src: capsLogo},
        title: "Center for Advanced Public Safety",
        url: `https://www.caps.ua.edu`
    }
];

export type IProps = {
    //
};

export const DevelopedBySection: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    return (
        <Fragment>

            <Header> { `Developed By` } </Header>

            <BannerStyles.BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                clickCallback={() => { window.open(docObject.url)}}
                                icon={
                                    <img
                                        width={docObject.icon?.width || 60} 
                                        height={docObject.icon?.height || 60} 
                                        src={docObject.icon?.src}
                                    />
                                }
                                label={docObject.title} 
                                defaultValue={EDefaultValue["external-link"]}
                            />
                        );
                    }
                )}
                
            </BannerStyles.BannerOptionSection>

        </Fragment>
    );
};

export default DevelopedBySection;