// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
import {
    ReactComponent as LogoSvgDark
} from "~/resources/icons/algo-traffic-icons/logo/algo-traffic-dark.svg";
import {
    ReactComponent as LogoSvgLight
} from "~/resources/icons/algo-traffic-icons/logo/algo-traffic-light.svg";

export type IProps = {
    logoWidth?: number;
    logoHeight?: number;
    textWidth?: number;
    textHeight?: number;
};

export const Logo: React.FC<IProps> = (props) => {

    const { 
        logoWidth = 36, logoHeight = 36, 
        textWidth = 64, textHeight = 64 
    } = props;

    const [theme, setTheme] = React.useState<'light' | 'dark'>('light');
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    React.useEffect(() => {
        setTheme(mediaQuery.matches ? 'dark' : 'light');
    }, [mediaQuery]);

    return(
       <div>
            {theme === 'dark' &&
                <SC.StyledLogo>
                    <LogoSvgDark height={logoHeight} width={logoWidth + textWidth} />
                </SC.StyledLogo>
            }
            {theme === 'light' && 
                <SC.StyledLogo>
                    <LogoSvgLight height={logoHeight} width={logoWidth + textWidth} />
                </SC.StyledLogo>
            }
       </div>
    );
};

export default Logo;