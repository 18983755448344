// libraries
import * as React from "react";
// types & models
import {
    EATLaneType, IATLane, IATLaneDirection
} from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import {
    getDividerGraphic, isShoulder, Lane
} from "@caps-mobile/traffic-graphics";

export type ILanesProps = {
    direction?: IATLaneDirection;
};

export const Lanes: React.FC<ILanesProps> = (props) => {

    const { direction } = props;

    if (!direction) return null;

    return (
        <SC.StyledLaneGraphicSection>

            {/* Render Left Shoulder Divider */}
                <Lane
                    type={EATLaneType.LeftShoulder} 
                    state={undefined}
                />
            {
                /* render the remaining lanes and dividers */
                direction.lanes?.map(
                    (lane: IATLane, index: number) => {
                        
                        // take note of next lane, or note if it does not exist
                        let nextLane = (
                            direction.lanes &&
                            index + 1 <= (direction.lanes.length - 1)
                        ) 
                            ? direction.lanes[index + 1]
                            : undefined;

                        return (
                            <React.Fragment key={`${lane.type}${lane.placement}`}>

                                {/* Render the appropriate lane */}
                                {   
                                    !isShoulder(lane.type) && 
                                    <Lane
                                        type={lane.type} 
                                        state={lane.state}
                                    />
                                }

                                {/* Then, get any necessary following divider lane */}
                                { getDividerGraphic(lane, nextLane) }

                            </React.Fragment>
                        );
                    }
                )
            }

            {/* Render Right Shoulder Divider */}
            <Lane
                type={EATLaneType.RightShoulder} 
                state={undefined}
            />

        </SC.StyledLaneGraphicSection>
    );
};

export default Lanes;