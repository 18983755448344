// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// hooks & contexts
import { useSelector } from "react-redux";
import DetailModalContext, { IDetailModalContext } from "~/contexts/DetailModalContext";
// types & models
import { ICWAlertDto } from "@algo/network-manager/models/v3/cw";
import { EAlgoApiObjectType } from "~/interfaces";
// components
import ObjectDetailModal from "../modal/object-detail-modal/ObjectDetailModal";
import { Ticker } from "./Ticker";
// constants
import { blue_ticker } from "~/constants";

export type IProps = {
    //
};

export const WeatherTicker: React.FC<IProps> = (props) => {

    const activeAlertStore: any = 
        useSelector( (state: any) => state["active-weather-alert"]);

    const detailModalContext: IDetailModalContext = React.useContext(DetailModalContext);

    return (
        <Ticker 
            color={blue_ticker} 
            content={mapDataToContentString(detailModalContext, activeAlertStore.data)} 
        />
    );

};

export default WeatherTicker;

const mapDataToContentString = (detailModalContext: IDetailModalContext, data?: ICWAlertDto[]) => {

    if (!data || data.length === 0) return "";

    // array to hold the spans of alert text
    let contentArray: any[] = [];

    for(let i = 0; i < data.length; i++){
        let nextAlert: any = data[i];
        
        // first, if we are NOT dealing with the first content list candidate, we should
        // pre-pend a middot for readability
        if (contentArray.length > 0)
            contentArray.push(
                <span key={nextAlert.id + i}>
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                </span>
            );

        contentArray.push(
            <SC.TickerItem key={nextAlert.id}
                onClick={ () => {
                    detailModalContext.setModalContent(
                        <ObjectDetailModal 
                            object={{ type: EAlgoApiObjectType["weather-alert"], data: nextAlert}} 
                            doneCallback={() => detailModalContext.setShowModal(false)} 
                        />
                    );
                    detailModalContext.setShowModal(true);
                }}
            >
                {buildFormattedTitleString(nextAlert)}
            </SC.TickerItem>
        );
    }

    return contentArray;
}

function timeFormatter(date: Date) {
    var hours: any = date.getHours();
    var minutes: any = date.getMinutes();
    var ampm: any = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const dateFormatter = (date: Date) => {
    let month = date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)

    let day = date.getDate() > 9
        ? date.getDate()
        : '0' + date.getDate();

    let year = date.getFullYear();

    return `${month}/${day}/${year}`;
}

const buildFormattedDateString = (dateCreated: Date) => {
    var dateObject = new Date(dateCreated);
    return (dateCreated === null || dateCreated === undefined)
        ? "N/A"
        : `${dateFormatter(dateObject)}\n${timeFormatter(dateObject)}`;
}

const sortObjectListByProperties = (list: any[], property1: any, property2: any) => {

    let sortedList = list.slice().sort(
        (a, b) => (a[property1] > b[property1])
            ? 1
            : (a[property1] === b[property1])
                ? ((a[property2] > b[property2])
                    ? 1
                    : -1
                )
                : -1
    );

    return sortedList;
}

const buildFormattedTitleString = (weatherAlert: ICWAlertDto): string => {

    let areas: any[] = weatherAlert.affectedAreas || [];
    let sortedAreas: any[] = (areas && areas.length > 0) 
        ? sortObjectListByProperties(areas, "area", "name") 
        : [];

    let titleString: string = "";

    let alertEndString: string = (weatherAlert.end)
        ? `until ${buildFormattedDateString(new Date(weatherAlert.end))}`
        : ``
    ;
    
    const isUniqueArea = (areaDescription: string) => {
        return titleString.indexOf(areaDescription) === -1
    }

    titleString +=
        `${weatherAlert.name} ` +
        `issued ${buildFormattedDateString(new Date(weatherAlert.effective))}  ` +
        `${alertEndString} ` +
        `for `;

    sortedAreas.map(
        (area, index) => {
            if (isUniqueArea(area.description)) {
                titleString += `${ index === 0 ? "" : "," } ${area.description}`;
            }
        }
    );

    return titleString;
}