// libraries
import { Fragment } from "react";
// enums
import { IDocObject } from "~/interfaces";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
import { Header } from "~/components/views/page-template/Styled";
// components
import BannerOption from "~/components/views/banner-option/BannerOption";
// constants

const DOC_STRINGS: IDocObject[] = [
    { title: "3.1.0", url: ""}
];

export type IProps = {
    //
};

export const CopywriteSection: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    return (
        <Fragment>

            <Header> { `Version` } </Header>

            <BannerStyles.BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                label={docObject.title}
                                styleOverwrite={`:hover{cursor: default};`}
                            />
                        );
                    }
                )}
                
            </BannerStyles.BannerOptionSection>

        </Fragment>
    );
};

export default CopywriteSection;