// libraries
import * as React from "react";

export type ISiteNavContext = {
    selectedSiteLink: number[];
    setSelectedSiteLink: (newVal: number[]) => void;

    selectedRoute: string;
    setSelectedRoute: (newVal: string) => void;

    navigate: (routeString: string) => void;

    panelOpen: boolean;
    setPanelOpen: (newVal: boolean) => void;
    togglePanelOpen: () => void;
};

export const SiteNavContext = React.createContext<ISiteNavContext>({
    // nav index selection
    selectedSiteLink: [0,0],
    setSelectedSiteLink: (newVal: number[]) => {},

    // nav route
    selectedRoute: "/",
    setSelectedRoute: (newVal: string) => {},

    navigate: (routeString: string) => {},

    // nav panel ui state
    panelOpen: true,
    setPanelOpen: (newVal: boolean) => {},
    togglePanelOpen: () => {}
});

export default SiteNavContext;

export const isNavLinkSelected = (
    contextSelectedSection: number[],
    sectionIndex: number, 
    linkIndex: number
): boolean => {
    return (
        sectionIndex === contextSelectedSection[0] &&
        linkIndex === contextSelectedSection[1]
    );
}

export const getNavString = (
    navIndex: number[], 
    navStrings: string[][]
) => {

    let navSection: number = navIndex[0];
    let navLink: number = navIndex[1];

    if (!navStrings[navSection] || !navStrings[navSection][navLink])
        return "/";

    else 
        return navStrings[navSection][navLink];
};