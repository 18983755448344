// libraries
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// styles
import * as SC from "./Styled";
// constants
import { navBarMap } from "~/navigation/navigation-map";
import { NavBarSection } from "./nav-bar-section/NavBarSection";

export type IProps = {
    //
};

export const NavBar: React.FC<IProps> = (props) => {

    return (
        <SC.StyledNavBar>

            {/* Render each of the nav sections, if any exist */}
            {   navBarMap && navBarMap.navSections && navBarMap.navSections.map(
                        (navSection: any, mapIndex: number) => {
                            return (
                                <SC.StyledNavBarSection 
                                    key={`${navSection.title || "anonymous-section"}${mapIndex}`}
                                >
                                    <NavBarSection 
                                        navSection={navSection}
                                    />
                                </SC.StyledNavBarSection>
                            );
                        }
                    )
                }

        </SC.StyledNavBar>
    );

};

export default NavBar;