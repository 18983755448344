// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants

export const StyledMessageSign = styled.div`
    height: 100%;
    width: 100%;
`;

export const PageIndicatorRow = styled.div`
    width: 100%;
    height: 20px;

    ${flexSmart("center center")}
    box-sizing: border-box;
`;

export const PageIndicatorBubble = styled.div<{isActive?: boolean}>`
    width: 10px;
    height: 10px;

    border-radius: 50%;

    background-color: rgba(0,0,0, ${props => props.isActive ? "0.6" : "0.3"});

    :not(:last-child){
        margin-right: 10px;
    }
`;

export const PageContents = styled.div<{fullHeight?: boolean}>`
    height: calc(100% - ${props => props.fullHeight ? "0px" : "20px"});
    width: 100%;
`;