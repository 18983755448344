// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledNavBar = styled.div`
    width: 100%;

    ${flexSmart("space-around center")}

    color: green;
    font-size: 24px;

    
`;

export const StyledNavBarSection = styled.div`
    width: 100%;
`;