// libraries
import * as React from "react";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
// context
import WeatherLegend from "../map/map-overlay-views/legend-section/legends/WeatherLegend";
import TrafficSpeedLegend from "../map/map-overlay-views/legend-section/legends/TrafficSpeedLegend";
// hooks & context
import { useSelector } from "react-redux";
// styles
import * as SC from "./Styled";
import SiteNavContext from "~/navigation/SiteNavContext";
import { navBarMap } from "~/navigation/navigation-map";
import MapMarkerLegend from "../map/map-overlay-views/legend-section/legends/MapMarkerLegend";
import { EAlgoLayerType } from "~/interfaces";
import PrecipitationLegend from "../map/map-overlay-views/legend-section/legends/PrecipitationLegend";
import { useBreakpoint } from "~/library/useBreakpoint";

export type IProps = {
    //
};

export const LegendPage: React.FC<IProps> = (props) => {
    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();
    
    // map layers
    const mapLayersStore: any = useSelector( (state: any) => state["map-layers"]);

    // active weather
    const activeWeatherStore: any = useSelector( (state: any) => state["weather-alert"]);
    const hasWeatherAlerts: boolean = activeWeatherStore.data?.length > 0;

    // crash
    const crashStore: any = useSelector( (state: any) => state["crash"]);
    const hasCrashAlerts: boolean = crashStore.data?.length > 0;

    // incident
    const incidentStore: any = useSelector( (state: any) => state["incident"]);
    const hasIncidentAlerts: boolean = incidentStore.data?.length > 0;

    // regional event
    const regionalEventStore: any = useSelector( (state: any) => state["regional-event"]);
    const hasRegionalEventAlerts: boolean = regionalEventStore.data?.length > 0;

    // road condition
    const roadConditionStore: any = useSelector( (state: any) => state["road-condition"]);
    const hasRoadConditionAlerts: boolean = roadConditionStore.data?.length > 0;

    // roadwork
    const roadworkStore: any = useSelector( (state: any) => state["roadwork"]);
    const hasRoadworkAlerts: boolean = roadworkStore.data?.length > 0;

    // camera
    const cameraStore: any = useSelector( (state: any) => state["camera"]);
    const hasCameraAlerts: boolean = cameraStore.data?.length > 0;

    // ferry
    const ferryStore: any = useSelector( (state: any) => state["ferry"]);
    const hasFerryAlerts: boolean = ferryStore.data?.length > 0;

    // message sign
    const messageSignStore: any = useSelector( (state: any) => state["message-sign"]);
    const hasMessageSignAlerts: boolean = messageSignStore.data?.length > 0;

    // other 511
    const other511Store: any = useSelector( (state: any) => state["other511"]);
    const hasOther511Alerts: boolean = other511Store.data?.length > 0;

    // state facility
    const stateFacilityStore: any = useSelector( (state: any) => state["state-facility"]);
    const hasStateFacilityAlerts: boolean = stateFacilityStore.data?.length > 0;

    const navContext: any = React.useContext(SiteNavContext);
    const map: any = navBarMap.navSections[0].links?.find((element) => element.name === "Map");
    
    return (
        <PageTemplate>

            <SC.CloseButtonTitleWrapper>
                <SC.Title>Legend</SC.Title>
                <SC.CloseButton onClick={
                        (evt) => {
                            evt.stopPropagation();
                            navContext.setSelectedSiteLink([map.id, map.id]);
                            navContext.setSelectedRoute(map.route);
                        }
                    }>
                    {"Close"}
                </SC.CloseButton>
            </SC.CloseButtonTitleWrapper>

            { /* Contains  the weather legend explaining weather polygon colors */ }
            { /* Should only appear when there are active weather alerts */}
            {  hasWeatherAlerts && mapLayersStore.mapLayers["weather-alert"] &&
                <SC.StyledLegend isMediumMax={isMediumMax} weather={!isSmallMax}>
                    <WeatherLegend />
                </SC.StyledLegend>
            }

            {   (
                    (hasCrashAlerts && mapLayersStore.mapLayers["crash"]) ||
                    (hasIncidentAlerts && mapLayersStore.mapLayers["incident"]) ||
                    (hasRegionalEventAlerts && mapLayersStore.mapLayers["regional-event"]) ||
                    (hasRoadConditionAlerts && mapLayersStore.mapLayers["road-condition"]) ||
                    (hasRoadworkAlerts && mapLayersStore.mapLayers["roadwork"]) ||
                    (hasCameraAlerts && mapLayersStore.mapLayers["camera-group"]) ||
                    (hasFerryAlerts && mapLayersStore.mapLayers["ferry"]) ||
                    (hasMessageSignAlerts && mapLayersStore.mapLayers["message-sign"]) ||
                    (hasOther511Alerts && mapLayersStore.mapLayers["other511"]) ||
                    (hasStateFacilityAlerts && mapLayersStore.mapLayers["state-facility"])
                ) && 
                <SC.StyledLegend>
                    <MapMarkerLegend /> 
                </SC.StyledLegend>
            }

            { /* Contains  the traffic speed legend explaining traffic speed colors */ }
            {  mapLayersStore.mapLayers["traffic-layer"] && 
                <SC.StyledLegend>
                    <TrafficSpeedLegend />
                </SC.StyledLegend> 
            }

            { /* Contains  the precipitation legend explaining precipitation colors */ }
            {  mapLayersStore.mapLayers["weather-radar"] &&  
                <SC.StyledLegend>
                    <PrecipitationLegend />
                </SC.StyledLegend>
            }

        </PageTemplate>
    );
};

export default LegendPage;