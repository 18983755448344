// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS } from "~/constants";

export const StyledWeatherAlertDetails = styled.div`
    width: 100%;

    position: relative;

    border-top: thin solid rgba(0,0,0,0.2);;
    padding-top: 10px;
    box-sizing: border-box;
`;

export const ScrollFadeWrap = styled.div`
    height: 300px;

    overflow: auto;
    ${RemoveScrollbar}

    padding-bottom: 12px;
    
    :after {
        width: 100%;
        height: 3rem;

        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: ${props => `linear-gradient(to bottom, ${props.theme.colors["drop-shadow"].rgba} 0%, 75%, ${props.theme.colors["bg-primary"].rgba} 100%)`};
    }

    > :not(:last-child){
        margin-bottom: 20px;
    }

    > :not(:first-child){
        border-top: thin solid rgba(0,0,0,0.2);
        padding-top: 10px;
        box-sizing: border-box;
    }
`;

export const StyledAlert = styled.div`
    
`;

export const AlertTitleRow = styled.div`
    height: 32px;
    width: 100%;

    margin-bottom: 10px;

    ${flexSmart("flex-start center")}

    font-size: 24px;

    > :first-child{
        margin-right: 20px;
    }
`;

export const AlertName = styled.div`
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const AreasHeader = styled.div`

    margin-top: 20px;

    font-weight: 700;
    font-size: 16px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const AreasText = styled.span`
    font-weight: 400;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Description = styled.div`
    margin-top: 20px;

    padding: 10px;
    box-sizing: border-box;

    border: thin solid  rgba(0,0,0,0.15);
    border-radius: ${BORDER_RADIUS}px;

    white-space: pre;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;