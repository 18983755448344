// libraries
import { Fragment, useContext, useEffect, useState } from "react";
// enums
// hooks & contexts
import DetailModalContext, { IDetailModalContext } from "~/contexts/DetailModalContext";
// styles
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import { Header } from "../Styled";
// components
import BannerOption from "~/components/views/banner-option/BannerOption";
import ToggleInstructions from "./permission-instructions/ToggleInstructions";

export type IProps = {
    //
};

export const PermissionsSection: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const detailModalContext: IDetailModalContext = useContext(DetailModalContext);

    const [ locationServices, setLocationServices ] = useState<boolean>(false);

    const promptLocationServices = () => {
        navigator.geolocation.getCurrentPosition( 
            () => { setLocationServices(true) }, 
            () => { }
        );
    }

    const clickToggleLocationServices = () => {
        if (!locationServices) promptLocationServices();
        else {
            // present the user with an explaination of how to disable location services
            detailModalContext.setModalContent(<ToggleInstructions doneCallback={() => detailModalContext.setShowModal(false)} />);
            detailModalContext.setShowModal(true);
        }
    };

    useEffect(
        () => {
            navigator.permissions.query({ name: 'geolocation' })
            .then(
                (permissionStatus: PermissionStatus) => {
                    setLocationServices(permissionStatus.state === "granted" ? true : false);
                    
                }
            )
        }, []
    )

    return (
        <Fragment>

            <Header> { `Site Permissions` } </Header>

            <BannerOptionSection>

                <BannerOption 
                    clickCallback={() => { clickToggleLocationServices() }}
                    label={"Location Services"} value={`${locationServices ? "On" : "Off"}`} />

            </BannerOptionSection>

        </Fragment>
    );
};

export default PermissionsSection;