// libraries
import styled from "styled-components";
import { RemoveScrollbar } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledVideoboardModal = styled.div`
    position: relative;

    border-radius: ${BORDER_RADIUS}px;

    overflow: auto;

    ${RemoveScrollbar}

    background-color: black;

    ${whenSmallMax(`
        width: 100%;
        height: 100%;
        border-radius: 0;
    `)}
`;