// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledFerryDetails = styled.div`
    height: 290px;
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${flexSmart("space-around flex-start")}
    flex-direction: column;
`;

export const PropertyRow = styled.div`
    ${flexSmart("flex-start center")}
`;

export const PropertyName = styled.span`
    font-size: 22px;
    font-weight: 600;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;

export const PropertyValue = styled.span`
    font-size: 22px;
    font-weight: 400;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;