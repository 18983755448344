// libraries
import styled from "styled-components";
import { EThemeOptions } from "~/theme";

// Wrapper for image and shimmer effect
export const ImgLoadingWrapper = styled.div<{ height?: string, width?: string }>`
    position: relative;
    width: ${props => props.width || `100%`}; /* Ensure width is set */
    height: ${props => props.height || '198px'}; /* Ensure height is set */
    border-radius: 12px;
    overflow: hidden; /* Prevent shimmer effect from overflowing */
`;

// Shimmer effect for loading state
export const LoadingImg = styled.div<{ height?: string }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.height || '198px'};
    background-color: ${props => props.theme.mode === EThemeOptions.Dark ? `#696969` : `#E0E0E0`};
    border-radius: 12px;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    
    @keyframes shimmer {
    100% {-webkit-mask-position:left}
    }
`;

// Styled image
export const StyledImgWithDefault = styled.img`
    object-fit: cover;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    display: block;
`;
