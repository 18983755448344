// libraries
import * as React from "react";
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import PageTemplate from "../../views/page-template/PageTemplate";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
// types & models
import { IDocObject } from "~/interfaces";
// hooks and contexts
import { ThemeContext } from "~/theme";

declare var __CLIENT_URL__: string;

const DOC_STRINGS: IDocObject[] = [
    {
        title: "Statewide ITS Architecture",
        subtitle: "Info on intelligent transporation systems at ALDOT",
        url: `${__CLIENT_URL__}/documents/alabama_statewide_its_architecture_for_cd_101214_withcover.pdf`
    },
    {
        title: "Alabama TIM Guidelines",
        subtitle: "Alabama traffic incident management guidelines",
        url: `${__CLIENT_URL__}/documents/alabama_TIM_Guidelines_v12_091516.pdf`
    },
    {
        title: "ALDOT ITS Strategic Business Plan",
        subtitle: "Intelligent transportation systems business plan for ALDOT",
        url: `${__CLIENT_URL__}/documents/ALDOT_ITS_Strategi_Business_Plan_Final%20Draft_Updated%20May%2025,%202016.pdf`
    }
];

const subtitleStyle: string = "font-size: 14px; line-height: 140%; font-weight: 400;";

export type IProps = {
    //
};

export const DocumentsPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const theme: any = React.useContext(ThemeContext);

    return (
        <PageTemplate title={"Documents"}>

            <BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                clickCallback={() => { window.open(docObject.url)}}
                                label={
                                    <React.Fragment>
                                        <BannerStyles.OptionLabel color={theme.colors["primary"].rgba}>{docObject.title}</BannerStyles.OptionLabel>
                                        <BannerStyles.OptionLabel styleOverwrite={subtitleStyle}>{docObject.subtitle}</BannerStyles.OptionLabel>
                                    </React.Fragment>
                                } 
                                defaultValue={EDefaultValue["external-link"]}
                            />
                        );
                    }
                )}
                
            </BannerOptionSection>

        </PageTemplate>
    );
};

export default DocumentsPage;