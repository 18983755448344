// hooks & context
import { useWatchCountdown } from "./useWatchCountdown";
// components
import ProgressBar from "../../progress-bar/ProgressBar";

export type IProps = {
    streamTimeout: number;  // number of seconds before timeout callback fires
    timeoutCallback?: () => void;   // logic to execute when timeout is reached
    playing: boolean;       // don't perform countdown if playing is false
    height?: string;        // css height of the progress bar
    color?: string;         // foreground 'progress' color of the progress bar
    bgColor?: string;       // background 'empty bar' color of the progress bar
};

export const WatchTimer: React.FC<IProps> = (props) => {

    const { 
        streamTimeout, timeoutCallback = () => {},
        playing,
        height = "3px",
        color = "rgba(235, 235, 245, 0.6)",
        bgColor = "rgba(255, 255, 255, 0.1)"
    } = props;

    const timerProgress = useWatchCountdown(streamTimeout, timeoutCallback, playing);
    const progressPercent: number = streamTimeout == -1 ? 0 : timerProgress / streamTimeout * 100;

    return (
        <ProgressBar height={height} progress={progressPercent.toString()}
            bgColor={bgColor} color={color} />
    );
};

export default WatchTimer;