// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { CardPartial } from "~/styles/Card";
import { whenSmallMax } from "~/theme";

export const ModalCard = styled.div<{large?: boolean}>`
    position: relative;

    ${CardPartial}

    background-color: ${props => props.theme.colors["bg-primary"]["rgba"]};

    max-height: 100%;
    max-width: ${props => props.large ? '95%' : '45%'};

    & > :not(:last-child){
        margin-bottom: 20px;
    }

    overflow: auto;

    ${RemoveScrollbar}

    ${whenSmallMax(`
        max-width: 100%;
        border-radius: 0;
    `)}
`;

export const Wrapper = styled.div`
    width: 100%;
`;