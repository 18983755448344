// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledApiObjectIcon = styled.div`
    margin-right: 20px;
    box-sizing: border-box;

    ${flexSmart("flex-start center")}

    padding-bottom: 10px;

    flex-direction: column;
`;