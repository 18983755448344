// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";
import { EThemeOptions } from "~/theme";

export const StyledMapImageWrap = styled.div<{height: number, isCamera?: boolean}>`
    height: ${props => props.height + "px"};

    ${props => props.isCamera ? `` : `${flexSmart("center center")}`}

    ${props => (props.theme.mode === EThemeOptions.Dark)
        ? `
            background-color: ${props.theme.colors["input-background"]["rgba"]};`
        : `
            background-color: ${props.theme.colors["bg-grey"]["rgba"]};
        `
    }

    border-radius: ${BORDER_RADIUS}px;

    box-sizing: border-box;

    > img {
        width: 100%;
        border-radius: ${BORDER_RADIUS}px;
    }
`;