// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledObjectDetailModal = styled.div`
    position: relative;

    width: 1000px;

    padding: 20px;
    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;

    ${flexSmart("flex-start center")}
    flex-direction: column;

    overflow: auto;

    ${RemoveScrollbar}

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${whenSmallMax(`
        width: 100%;
        height: 100%;

        max-width: 100%;
        max-height: 100%;

        border-radius: 0;
    `)}
`;

export const DoneText = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 4;

    font-weight: 700;
    font-size: 17px;
    line-height: 147%;

    color: ${props => props.theme.colors["primary"].rgba};

    :hover{
        cursor: pointer;
    }
`;

export const DetailRow = styled.div`
    height: auto;
    width: 100%;

    ${whenSmallMax(`
        padding-top: 25px;
    `)}
`;

export const Icon = styled.div`
    text-align: center;
`;

export const NoResultsText = styled.div`
    margin-top: 10px;
    text-align: center;
`;

export const Loading = styled.div`
    height: 945px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;