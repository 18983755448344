// libraries
import * as React from "react";
import SchemeSelectOption from "./scheme-select-option/SchemeSelectOption";
// styles
import * as SC from "./Styled";
import { EThemeOptions, ThemeContext } from "~/theme";
// constants
import { NORMAL_DAY_MAP_SCHEME, NORMAL_NIGHT_MAP_SCHEME, NORMAL_VECTOR_DAY_MAP_SCHEME, NORMAL_VECTOR_NIGHT_MAP_SCHEME, SATELLITE_MAP_SCHEME, TERRAIN_MAP_SCHEME } from "~/constants";
import { EHereMapScheme } from "~/interfaces";
import { useSelector } from "react-redux";

export type IProps = {
    title?: string;
};

export const SchemeSelectList: React.FC<IProps> = (props) => {

    const { title } = props;

    const theme: any = React.useContext(ThemeContext);
    const mapLayersStore = useSelector( (state: any) => state["map-layers"]);

    const [showVectorOption, setShowVectorOption] = React.useState<boolean>(() => {
        // Initialize state from local storage
        return localStorage.getItem('showVectorOption') === 'true';
    });

    React.useEffect(() => {
        const currentScheme = EHereMapScheme[mapLayersStore.mapScheme as keyof typeof EHereMapScheme];
        const isVectorScheme = currentScheme === NORMAL_VECTOR_NIGHT_MAP_SCHEME || currentScheme === NORMAL_VECTOR_DAY_MAP_SCHEME;

        // If it's a vector scheme, show the option
        if (isVectorScheme) {
            setShowVectorOption(true);
        } else {
            // If not a vector scheme, remove local storage and hide the option
            localStorage.removeItem('showVectorOption');
            setShowVectorOption(false);
        }
    }, [mapLayersStore.mapScheme]); // Dependency only on mapScheme

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const currentScheme = EHereMapScheme[mapLayersStore.mapScheme as keyof typeof EHereMapScheme];
            const isVectorScheme = currentScheme === NORMAL_VECTOR_NIGHT_MAP_SCHEME || currentScheme === NORMAL_VECTOR_DAY_MAP_SCHEME;

            if (event.key === '`' && !isVectorScheme) {
                const newValue = !showVectorOption;
                setShowVectorOption(newValue);
                localStorage.setItem('showVectorOption', String(newValue));
            }
        };

        // Add the event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showVectorOption, mapLayersStore.mapScheme]); // Dependency on showVectorOption and mapScheme

    return (
        <SC.StyledSchemeSelectList>
            
            { title && <SC.Header> {title} </SC.Header> }

            <SC.SchemeOptionList>

                { showVectorOption &&
                    <SchemeSelectOption 
                        label={"Normal (Vector)"}
                        value={(theme.mode === EThemeOptions.Dark)
                            ? NORMAL_VECTOR_NIGHT_MAP_SCHEME
                            : NORMAL_VECTOR_DAY_MAP_SCHEME}
                    />
                }

                <SchemeSelectOption 
                    label={"Normal"}
                    value={(theme.mode === EThemeOptions.Dark)
                        ? NORMAL_NIGHT_MAP_SCHEME
                        : NORMAL_DAY_MAP_SCHEME}
                />

                <SchemeSelectOption 
                    label={"Satellite"}
                    value={SATELLITE_MAP_SCHEME}
                />

                {/* <SchemeSelectOption 
                    label={"Terrain"}
                    value={TERRAIN_MAP_SCHEME}
                /> */}

            </SC.SchemeOptionList>

        </SC.StyledSchemeSelectList>
    )
};

export default SchemeSelectList;