// libraries
import { RemoveScrollbar, flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";
import { whenSmallMax, whenSmallMin } from "~/theme";

export const StyledRadioOptionsCard = styled.div<{styleOverwrite?: string}>`
    positon: relative;
    height: auto;
    width: 350px;

    padding-top: ${CARD_PADDING}px;
    box-sizing: border-box;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${whenSmallMax(`
        min-width: 100%;
        height: 100%;
        border-radius: 0;
    `)}

    ${props => props.styleOverwrite ? props.styleOverwrite : ""};
`;

export const OptionsContainer = styled.div`
    width: 100%;

    ${whenSmallMax(`
            ${RemoveScrollbar}
    `)}
`;

export const Header = styled.div`
    font-size: 24px;
    font-weight: 600;

    padding-bottom: 10px;
    box-sizing: border-box;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Cancel = styled.div`
    box-sizing: boder-box;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 700;
    font-size: 17px;
    line-height: 147%;
    
    :hover{
        cursor: pointer;
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - ${CARD_PADDING * 2}px);

    padding-left: ${CARD_PADDING}px;
    padding-right: ${CARD_PADDING}px;
`;

export const OptionRow = styled.div`
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-top: 2px solid ${props => props.theme.colors["divider"].rgba};

    ${flexSmart("space-between center")}
    flex-direction: row;

    :hover{
        cursor: pointer;
        background-color: ${props => props.theme.colors["hover"].rgba};
    }

    ${whenSmallMin(`
        :last-child {
            border-bottom-left-radius: ${BORDER_RADIUS}px;
            border-bottom-right-radius: ${BORDER_RADIUS}px;
        }
    `)}

    ${props => 
        whenSmallMax(`
            :last-child {
                border-bottom: 2px solid ${props.theme.colors["divider"].rgba};
            }
        `)
    }
`;

export const RadioLabel = styled.div`
    text-transform: capitalize;
    color: ${props => props.theme.colors["primary"].rgba};
`;

