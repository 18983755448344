// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledOther511Details = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("space-around center")}
    flex-direction: column;
`;

export const RedirectText = styled.div`
    width: 100%;

    font-size: 24px;
    font-weight: 500;

    text-align: center;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const LinksRow = styled.div`
    width: 100%;
    margin-top: 20px;
    
    ${flexSmart("space-around center")}
`;

export const LinkText = styled.div`
    width: 100%;
    
    font-size: 24px;
    font-weight: 600;

    text-align: center;

    color: ${props => props.theme.colors["primary"].rgba};

    text-decoration: underline;

    :hover{
        cursor: pointer;
        opacity: 1;
    }
`;