// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, CARD_PADDING } from "../../../constants";
import { whenLargeMax, whenLargeMin, whenMediumMax, whenMediumMin, whenSmallMax, whenSmallMin } from "~/theme";

export const StyledPageTemplate = styled.div`
    height: 100%;
    width: 100%;

    box-sizing: border-box;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${RemoveScrollbar}

    padding-top: 20px;
    padding-left: calc(env(safe-area-inset-left) + 20px);
    padding-right: calc(env(safe-area-inset-right) + 20px);
`;

export const BackAndTitleWrap = styled.div<{travelTimes?: boolean}>`
    width: 100%;
    ${flexSmart("flex-start center")}
    flex-direction: row;
    padding-bottom: 20px;

    ${props => props.travelTimes ? 
        `gap: 20px;
        ${whenLargeMax(`
            flex-direction: column;

            > :first-child {
            margin-right: 0;
            align-self: flex-start; /* Aligns the first child to the start (left) */
        }`)}` : ``
    };
`;

export const Title = styled.div`
    flex: 1 0 auto;

    box-sizing: border-box;

    font-size: 48px;
    line-height: 108.36%;
    font-weight: 600;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;

    padding-bottom: 20px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: contents;
`;

export const SearchRow = styled.div`
    width: 100%;
    ${flexSmart("flex-start center")}
`;

export const FilterRow = styled.div`
    width: 100%;
    margin-top: 20px;

    ${flexSmart("space-between center")}

    > :not(:last-child){
        margin-right: 10px;
        box-sizing: border-box;
    }
`;

export const PageText = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 147%;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const PageH1 = styled.div`
    
    padding: 20px 0;
    box-sizing: border-box;

    font-weight: 600;
    font-size: 40px;
    line-height: 110%;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const PageH2 = styled.div`
    font-weight: 600;
    font-size: 32px;
    line-height: 112%;
`;

export const PageH3 = styled.div`
    font-weight: 500;
    font-size: 28px;
    line-height: 110%;
`;

export const FilteredContentSection = styled.div<{twoColumns?: boolean}>`
    ${props => props.twoColumns ? 
        `
            display:flex;
            flex-wrap:wrap;
        ` : 
        `
        height: 100%;
        `
    }
    
    width: 100%;
    margin-top: 20px;

    overflow: auto;

    ${RemoveScrollbar}
`;

export const NoResults = styled.div`

    display: none;

    :only-child{
        display: block;

        width: 100%;
    
        padding: 10px 20px;
        box-sizing: border-box;

        ${flexSmart("flex-start center")}

        border-radius: ${BORDER_RADIUS}px;

        background-color: rgba(117, 117, 117, 0.1);
    }
`;

export const NoResultsPage = styled.div`
    position: relative;
    top: calc(45% - 32px);
    left: calc(50% - 100px);
    opacity: 50%;
    width: 200px;
`;

export const Icon = styled.div`
    text-align: center;
`;

export const NoResultsText = styled.div`
    margin-top: 10px;
    text-align: center;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledLegend = styled.div<{isMediumMax?: boolean}>`
    width: 100%;
    min-width: ${props => props.isMediumMax ? '100px' : '225px'};
    padding: 10px;
    flex: 1;
    box-sizing: border-box;
    border-radius: ${BORDER_RADIUS}px;
    background-color: ${props => props.theme.colors["input-background"].rgba};
`;