// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
import { useSelector } from "react-redux";
// constants
import _accidentPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_accident_pin.png";
import _incidentPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_incident_pin.png";
import _eventPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_event_pin.png";
import _weatherPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_weather_pin.png";
import _roadConditionPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_road_condition_pin.png";
import _constructionPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_construction_pin.png";
import _cameraPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_camera_pin.png";
import _ferryPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_ferry_pin.png";
import _signOffPin from "~/resources/icons/algo-traffic-icons/map-pin/ic_sign_off_pin.png";
import _welcomeCenterOriginClosed from "~/resources/icons/algo-traffic-icons/map-pin/ic_welcomeCenter_origin_closed_pin.png";
import _restAreaOrigin from "~/resources/icons/algo-traffic-icons/map-pin/ic_restArea_origin_pin.png";


export type IProps = {
    //
};

export const MapMarkerLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();

    // map layers
    const mapLayersStore: any = useSelector( (state: any) => state["map-layers"]);

    // crash
    const crashStore: any = useSelector( (state: any) => state["crash"]);
    const hasCrashAlerts: boolean = crashStore.data?.length > 0;

    // incident
    const incidentStore: any = useSelector( (state: any) => state["incident"]);
    const hasIncidentAlerts: boolean = incidentStore.data?.length > 0;

    // regional event
    const regionalEventStore: any = useSelector( (state: any) => state["regional-event"]);
    const hasRegionalEventAlerts: boolean = regionalEventStore.data?.length > 0;

    // road condition
    const roadConditionStore: any = useSelector( (state: any) => state["road-condition"]);
    const hasRoadConditionAlerts: boolean = roadConditionStore.data?.length > 0;

    // roadwork
    const roadworkStore: any = useSelector( (state: any) => state["roadwork"]);
    const hasRoadworkAlerts: boolean = roadworkStore.data?.length > 0;

    // camera
    const cameraStore: any = useSelector( (state: any) => state["camera"]);
    const hasCameraAlerts: boolean = cameraStore.data?.length > 0;

    // ferry
    const ferryStore: any = useSelector( (state: any) => state["ferry"]);
    const hasFerryAlerts: boolean = ferryStore.data?.length > 0;

    // message sign
    const messageSignStore: any = useSelector( (state: any) => state["message-sign"]);
    const hasMessageSignAlerts: boolean = messageSignStore.data?.length > 0;

    // other 511
    const other511Store: any = useSelector( (state: any) => state["other511"]);
    const hasOther511Alerts: boolean = other511Store.data?.length > 0;

    // state facility
    const stateFacilityStore: any = useSelector( (state: any) => state["state-facility"]);
    const hasStateFacilityAlerts: boolean = stateFacilityStore.data?.length > 0;


    return (
        <SC.Legend>

            <SC.Title isMediumMax={isMediumMax}>
                {"Map Marker Legend"}
            </SC.Title>

            <SC.LegendItems isMediumMax={isMediumMax}>
                {   hasCrashAlerts && mapLayersStore.mapLayers["crash"] &&
                    <SC.Icon>
                        <img width={30} src={_accidentPin} />
                        <div>Crashes</div>
                    </SC.Icon>
                }

                {   hasIncidentAlerts && mapLayersStore.mapLayers["incident"] &&
                    <SC.Icon>
                        <img width={30} src={_incidentPin} />
                        <div>Incidents</div>
                    </SC.Icon>
                }

                {   hasRegionalEventAlerts && mapLayersStore.mapLayers["regional-event"] &&
                    <SC.Icon>
                        <img width={30} src={_eventPin} />
                        <div>Regional Events</div>
                    </SC.Icon>
                }

                {   hasRoadConditionAlerts && mapLayersStore.mapLayers["road-condition"] &&
                    <SC.Icon>
                        <img width={30} src={_roadConditionPin} />
                        <div>Road Conditions</div>
                    </SC.Icon>
                }

                {   hasRoadworkAlerts && mapLayersStore.mapLayers["roadwork"] &&
                    <SC.Icon>
                        <img width={30} src={_constructionPin} />
                        <div>Roadwork</div>
                    </SC.Icon>
                }

                {   hasCameraAlerts && mapLayersStore.mapLayers["camera-group"] &&
                    <SC.Icon>
                        <img width={30} src={_cameraPin} />
                        <div>Camera</div>
                    </SC.Icon>
                }

                {   hasFerryAlerts && mapLayersStore.mapLayers["ferry"] &&
                    <SC.Icon>
                        <img width={30} src={_ferryPin} />
                        <div>Ferry</div>
                    </SC.Icon>
                }

                {   hasMessageSignAlerts && mapLayersStore.mapLayers["message-sign"] &&
                    <SC.Icon>
                        <img width={30} src={_signOffPin} />
                        <div>Message Sign</div>
                    </SC.Icon>
                }

                {   hasOther511Alerts && mapLayersStore.mapLayers["other511"] &&
                    <SC.Icon>
                        <img width={30} src={_welcomeCenterOriginClosed} />
                        <div>Other 511 Systems</div>
                    </SC.Icon>
                }

                {   hasStateFacilityAlerts && mapLayersStore.mapLayers["state-facility"] &&
                    <SC.Icon>
                        <img width={30} src={_restAreaOrigin} />
                        <div>State Facility</div>
                    </SC.Icon>
                }
            </SC.LegendItems>

        </SC.Legend>
    );
};

export default MapMarkerLegend;