import { EHereMapScheme } from "~/interfaces";
// schemes
import normalday from "./normal-day.png";
import normaldaygrey from "./normal-day-grey.png";
import normaldaymobile from "./normal-day-mobile.png";
import normalnight from "./normal-night.png";
import hybridday from "./satellite.png";
import terrainday from "./terrain.png";

export const D_PREV_WIDTH = 170;
export const D_PREV_HEIGHT = 96;

export const mapSchemePreview = (
    name: EHereMapScheme, 
    width: number = D_PREV_WIDTH, 
    height: number = D_PREV_HEIGHT
) => {

    switch(name){
        case EHereMapScheme["normal.day"]:
        case EHereMapScheme["vector.normal.day"]:
            return <img width={width} height={height} src={normalday} />
        case EHereMapScheme["normal.day.grey"]:
            return <img width={width} height={height} src={normaldaygrey} />
        case EHereMapScheme["satellite.day"]:
            return <img width={width} height={height} src={hybridday} />
        case EHereMapScheme["terrain.day"]:
            return <img width={width} height={height} src={terrainday} />
        case EHereMapScheme["normal.day.mobile"]:
                return <img width={width} height={height} src={normaldaymobile} />
        case EHereMapScheme["normal.night"]:
        case EHereMapScheme["vector.normal.night"]:
                return <img width={width} height={height} src={normalnight} />
        default:
            return <img width={width} height={height} src={normaldaygrey} />
    }
};