// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledNotFound = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const ErrorCodeText = styled.div`
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 10px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const NotFoundText = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 10px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const LogoWrap = styled.div`
    padding: 50px 0 30px 0;
    box-sizing: border-box;
`;

export const NoticeText = styled.div`
    font-size: 14px;
    line-height: 160%;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const ReturnLinkText = styled.div`
    margin-top: 10px;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline;

    :hover{
        cursor: pointer;
    }
`;