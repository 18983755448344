// libraries
import { getAll as getAllCities } from "~/store/algo-api/slices/cities";
import { getAll as getAllPlaces } from "~/store/algo-api/slices/places";
import { getAll as getAllTravelTimes } from "~/store/algo-api/slices/travel-times";
import { filterBySelections } from "~/store/algo-api/slices/travel-times";
// hooks & context
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

// dispatches store action creators for loading city and place data
// this data is used to populate the filter dropdowns for searching travel times
export const useCitiesAndPlaces = (placeStore: any, cityStore: any) => {

    const dispatch = useDispatch();

    useEffect(
        () => {

            if (!placeStore.data)
                dispatch(getAllPlaces() as any);
            if (!cityStore.data)
                dispatch(getAllCities() as any);

        }, [placeStore.data, cityStore.data]
    );
};

export const useLoadTravelTimes = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(getAllTravelTimes() as any);
        }, []
    );
}

export const useFilterContent = (
    cityIndices: number[], roadwayIndices: number[],
    cityStrings: string[], searchText: string, 
    placeStore: any, travelTimeStore: any
) => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(filterBySelections(
                cityIndices.length === 0 ? [] : cityIndices.map( (index: number) => cityStrings[index]), 
                roadwayIndices.length === 0 ? [] : roadwayIndices.map( (index: number) => placeStore.data[index].id),
                searchText
            ) as any);
        }, [cityIndices, roadwayIndices, searchText, travelTimeStore.data]
    );
}

// hook to get data for react viewport list
// need array to have two objects per index for styling purposes
export const useGetData = (filteredTravelTimes: any[]) => {
    const [data, setData] = React.useState<[any, any][]>([]);

    useEffect(() => {
        if (filteredTravelTimes) {
            const newData: [any, any][] = [];
            for (let i = 0; i < filteredTravelTimes.length; i += 2) {
                const firstItem = filteredTravelTimes[i];
                const secondItem = filteredTravelTimes[i + 1];
                newData.push([firstItem, secondItem]);
            }
                
            setData(newData);
        } else {
            setData([]);
        }
    }, [JSON.stringify(filteredTravelTimes)]);

    return data;
};