// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenMediumMax } from "~/theme";

export const StyledSchemeSelectList = styled.div`
    width: 100%;
`;

export const Header = styled.div`
    font-size: 24px;
    font-weight: 600;

    padding-bottom: 10px;
    box-sizing: border-box;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const SchemeOptionList = styled.div`
    width: 100%;
    ${flexSmart("start flex-start")}
    flex-direction: row;
    flex-wrap: wrap;

    ${whenMediumMax(`
        gap: 10px;
    `)}
`;