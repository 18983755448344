// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { 
    ATMessageSignPage, IATMessageSignDto, IATMessageSignPage, IATMessageSignPageDto 
} from "@algo/network-manager/models/v3";
// components
import MessageSignPage from "./message-sign-page/MessageSignPage";

export type IProps = {
    sign: IATMessageSignDto;
};

export const MessageSign: React.FC<IProps> = (props) => {

    const { sign } = props;

    /*  Start off by applying a 5-second pageOnSeconds to any page that has a falsey pageOnSeconds value
        this prevents the scenario of either never displaying an existing page, or displaying the page forever
        and never looping back through other signs in its list */
    const pages: IATMessageSignPageDto[] = sign.pages?.map(
        (page: IATMessageSignPageDto) => page.pageOnSeconds  ? page : {...page, pageOnSeconds: 5}
    ) || [];

    const pageCount: number = pages?.length || 0;
    const [ pageIndex, setPageIndex ] = React.useState<number>(0);

    const curPage: IATMessageSignPage = (pageCount > 0) 
        ? new ATMessageSignPage(pages![pageIndex]) 
        : new ATMessageSignPage();

    const hasMorePages: boolean = pageCount > 1;

    const incrementPageIndex = React.useCallback( 
        () => {
            if (pageCount <= 1) return;
            setPageIndex( pageIndex => ( pageIndex < pageCount - 1  )
                ? pageIndex + 1 
                : 0
            );
        }, [pageIndex, setPageIndex, pageCount]
    );

    return (
        <SC.StyledMessageSign>

            { hasMorePages &&
                <SC.PageIndicatorRow>
                    { pages?.map(
                        (page: IATMessageSignPageDto, index: number) => {
                            if (page.pageOnSeconds)
                                return (
                                    <SC.PageIndicatorBubble 
                                        key={`${sign.id}${index}`} isActive={index === pageIndex} />
                                );
                            else return null;
                        }
                    )}
                </SC.PageIndicatorRow>
            }

            <SC.PageContents fullHeight={!hasMorePages}>
                <MessageSignPage page={curPage} incrementPageIndex={incrementPageIndex} />
            </SC.PageContents>

        </SC.StyledMessageSign>
    );
};

export default MessageSign;