import { faChevronDown, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCombobox, useMultipleSelection } from "downshift"
import React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
  options: string[];
  placeholder: string;
  showPlaceholder: boolean;
  scrollThreshold?: number;
  multiSelect?: boolean;
  callback: (index: number) => void;
};

export const AlgoDropdown: React.FC<IProps> = (props) => {

  const multiSelect = props.multiSelect ? props.multiSelect : false;
  const [placeholder, setPlaceholder] = React.useState(props.placeholder);
  const initialSelectedItems: string[] = [];
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItems, setSelectedItems] = React.useState<string[]>(initialSelectedItems);
  const items = getItemsFilter(inputValue);

  function getItemsFilter(inputValue: string) {
    const lowerCasedInputValue = inputValue.toLowerCase();
  
    return props.options ? props.options.filter(
      option =>
        option.toLowerCase().startsWith(lowerCasedInputValue),
    ) : [];
  }

  function removeSelectedItem(selectedItem: string){
    const newSelectedItems = selectedItems.filter((item) => item !== selectedItem);
    setSelectedItems(newSelectedItems);
    let removeIndex: number = props.options.findIndex(x => x === selectedItem);
    props.callback(removeIndex);
  }

  function AlgoSelect() {
    const {
      getSelectedItemProps,
      getDropdownProps
    } = useMultipleSelection()
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    toggleMenu,
    highlightedIndex
  } = useCombobox({
    items,
    defaultHighlightedIndex: -1,
    inputValue,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const {changes, type} = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputFocus:
          return {
            ...changes,
            ...(changes.selectedItem && {isOpen: true, highlightedIndex: -1}),
          }
        default:
          return changes
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if(newSelectedItem){
            if(selectedItems.includes(newSelectedItem)){
              removeSelectedItem(newSelectedItem);
            }
            else{
              let newSelectedItems = [];
              if(!multiSelect){
                newSelectedItems = [newSelectedItem];
              }
              else{
                newSelectedItems = [...selectedItems, newSelectedItem];
              }
              setSelectedItems(newSelectedItems);
              let newIndex: number = props.options.findIndex(x => x === newSelectedItem);
              props.callback(newIndex);
              setInputValue('');
            }
          }
          break
        case useCombobox.stateChangeTypes.ToggleButtonClick:
        case useCombobox.stateChangeTypes.FunctionToggleMenu:
        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.InputFocus:
          isOpen ? setPlaceholder(props.placeholder) : setPlaceholder('Search...'); 
          break
        case useCombobox.stateChangeTypes.InputChange:
          newInputValue ? setInputValue(newInputValue) : setInputValue('');
          break
        default:
          break
      }
    },
  })
    return (
      <SC.StyledDropdown menuOpened={isOpen} items={items.length > 0} selectedItems={selectedItems.length > 0}>
        <SC.StyledSelector onClick={(e) => { e.stopPropagation(); toggleMenu(); } }>
          <SC.StyledSelected isHidden={isOpen || props.showPlaceholder} {...getDropdownProps({ preventKeyAction: isOpen })}>
            {selectedItems.map(function renderSelectedItem(
              selectedItemForRender,
              index,
            ) {
              return (
                <SC.StyledSelectedValue  {...getDropdownProps({ preventKeyAction: isOpen })}
                  key={`selected-item-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                  onClick={e => {
                    e.stopPropagation();
                    removeSelectedItem(selectedItemForRender);
                  }}
                >
                  <SC.StyledSelectedValueText>
                    {selectedItemForRender}
                  </SC.StyledSelectedValueText>
                    <SC.StyledSelectedXIcon>
                      <FontAwesomeIcon className="fa-solid fa-xmark" icon={faXmark} />
                    </SC.StyledSelectedXIcon>
                </SC.StyledSelectedValue>
              )
            })}
          </SC.StyledSelected>
          <SC.StyledSelectionInput 
            {...getInputProps(getDropdownProps({preventKeyAction: isOpen}))}
            placeholder={placeholder} 
            isHidden={!isOpen && !props.showPlaceholder}
            onClick={(e) => { e.stopPropagation(); } } />
          <SC.StyledDiv>
            <SC.StyledXIcon 
              isHidden={isOpen || props.showPlaceholder} 
              onClick={e => { e.stopPropagation(); 
                              setSelectedItems(initialSelectedItems); 
                              setPlaceholder(props.placeholder); 
                              props.callback(-1); }}>
              <FontAwesomeIcon className="fa-solid fa-xmark" icon={faXmark} />
            </SC.StyledXIcon>
            <SC.StyledDropIcon {...getToggleButtonProps()} onClick={(e) => { e.stopPropagation(); toggleMenu(); } }>
              <FontAwesomeIcon className="fa-light fa-chevron-down" icon={faChevronDown} />
            </SC.StyledDropIcon>
          </SC.StyledDiv>
        </SC.StyledSelector>
        <SC.StyledList menuOpened={isOpen} items={items.length > 0} scrollThreshold={props.scrollThreshold} {...getMenuProps()}>
          {items.map((item, index) => (
            <SC.StyledListOption
              isSelected={selectedItems.includes(item)}
              isHighlighted={highlightedIndex === index}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              <span>{item}</span>
            </SC.StyledListOption>
          ))}
        </SC.StyledList>
      </SC.StyledDropdown>
    )
  }
  return AlgoSelect()
}
