// libraries
import * as React from "react";
import navMap, { getNavMapValue } from '~/navigation/navigation-map';

export type IDataSearchOverlayContext = {
    tickerCount: number;
    showOverlay: boolean;
    showLegend: boolean;
    setShowOverlay: (newVal: boolean) => void;
    setShowLegend: (newVal: boolean) => void;
    toggleShowOverlay: () => void;
};

// data search overlay ui state
export const DataSearchOverlayContext = React.createContext({
    // tracks number of alert tickers currently active (for sizing overlay height)
    tickerCount: 0,
    showOverlay: false,
    showLegend: false,
    setShowOverlay: (newVal: boolean) => {},
    setShowLegend: (newVal: boolean) => {},
    toggleShowOverlay: () => {}
});

// hook to initialize the context
export const useSiteNavContext = (
    pathName: string, 
    panelOpen: boolean, setPanelOpen: any
) => {

    // tracks which nav link is currently selected
    const [selectedSiteLink, setSelectedSiteLink] = 
        React.useState<number[]>(getNavMapValue(pathName, navMap));

    // tracks which nav route currently selected
    const [selectedRoute, setSelectedRoute] = 
        React.useState<string>((pathName === "" || pathName === "/") ? "/map" : pathName);

    const siteNavContextInit = {
        // nav index selection
        selectedSiteLink: selectedSiteLink, 
        setSelectedSiteLink: (newVal: number[]) => {setSelectedSiteLink(newVal)},
        
        // nav route
        selectedRoute: selectedRoute,
        setSelectedRoute: (newVal: string) => { setSelectedRoute(newVal)},

        navigate: (routeString: string) => { 
            setSelectedSiteLink(getNavMapValue(routeString, navMap)); 
            setSelectedRoute(routeString);
        },

        // nav panel ui state
        panelOpen: panelOpen,
        setPanelOpen: (newVal: boolean) => { setPanelOpen(newVal)},
        togglePanelOpen: () => setPanelOpen(!panelOpen)
    };

    return siteNavContextInit;
}

export default DataSearchOverlayContext;