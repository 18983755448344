// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS } from "~/constants";

export const StyledDetailsSection = styled.div`
    width: 100%;
    height: 100%;
`;

export const ShowMore = styled.div`
    width: 100%;
    
    padding: 10px 20px;
    box-sizing: border-box;

    ${flexSmart("flex-start center")}

    border-radius: ${BORDER_RADIUS}px;

    background-color: rgba(0, 117, 86, 0.1);

    :hover{
        cursor: pointer;
    }
`;

export const MoreLessText = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.colors["text-primary"].rgba}
`;