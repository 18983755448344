// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// component
import Logo from "~/components/views/logo/Logo";
import { useNavigate } from "react-router-dom";

export type IProps = {
    //
};

export const AccessDeniedPage: React.FC<IProps> = (props) => {

    const navigate = useNavigate();

    return (
        <SC.StyledNotFound>

            <SC.ErrorCodeText>{"Forbidden"}</SC.ErrorCodeText>

            <SC.NotFoundText>
                {"Access Denied"}
            </SC.NotFoundText>

            <SC.LogoWrap>
                <Logo logoWidth={128} logoHeight={128} textWidth={128} textHeight={128} />
            </SC.LogoWrap>

            <SC.NoticeText>
                {"You lack the authentication or privilege to view this content"}
            </SC.NoticeText>

            <SC.ReturnLinkText
                onClick={() => navigate("/")}
            >
                {"Return to ALGO Traffic"}
            </SC.ReturnLinkText>

        </SC.StyledNotFound>
    );
};

export default AccessDeniedPage;