// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export const StyledRoute = styled.div<{isSelected?: boolean, isMediumMax?: boolean}>`

    padding: ${CARD_PADDING}px;
    margin-bottom: 10px;
    box-sizing: border-box;

    ${props => props.isSelected ? `border: 2px solid ${props.theme.colors["primary"].rgba}` : `border: 2px solid transparent`};

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);

    cursor: pointer;

    position: relative;
    margin-right: 20px;
    pointer-events: auto;

    :first-child{
        margin-left: 10px;
    }

    :last-child{
        margin-right: 10px;
    }
`;

export const Title = styled.div<{isMediumMax?: boolean}>`

    ${props => props.isMediumMax ?
        `font-size: 14px;
        line-height: 16px;
        padding-bottom: 6px;
        font-weight: 600;
        width: 180px;`
        : 
        `font-size: 17px;
        line-height: 16px;
        padding-bottom: 10px;
        font-weight: 600;`  
    }

    color: ${props => props.theme.colors["text-primary"].rgba};
    ${flexSmart("space-between center")}
    
`;

export const TitleText = styled.span<{isSmallMax?: boolean}>``;

export const CameraLink = styled.span<{isSmallMax?: boolean}>`
    color: ${props => props.theme.colors["primary"].rgba};
    padding-left: 20px;
`;

export const RouteItems = styled.div<{isMediumMax?: boolean}>`

    font-size: ${props => props.isMediumMax ? '10px' : 'auto'};

`;

export const StyledRouteItem = styled.div`
    ${flexSmart("flex-start")}
    flex-direction: column;
`;

export const Name = styled.div`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400; 
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Line = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    border-top: thin solid #C7C7CC;
`;

export const Time = styled.div`
    padding-left: 8.75px;
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #636366;

    ${flexSmart("flex center")}
`;

export const TravelIcon = styled.div`
    ${flexSmart("flex center")}
`;

export const TravelTimeSection = styled.div`

    width: fit-content;
    height: 30px;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;

    border: 1px solid #636366;
    border-radius: 12px;

    ${flexSmart("flex-center center")}
`;