// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const ServiceAssistancePatrolGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg width={width || "229"} height={height || "152"} viewBox={ viewBox || "0 0 2000 2000" } xmlns="http://www.w3.org/2000/svg">
            <path id="Path-copy-3" fill={ color || "white" } stroke="none" d="M 941.124512 434.518311 L 337.63916 434.518311 L 941.763184 1000.285034 L 337.63916 1566.052002 L 941.124512 1566.052002 L 1545.24707 1000.285034 L 941.124512 434.518311 Z"/>
            <path id="Path-copy-2" fill={ color || "white" } stroke="none" d="M 47.046387 545.735107 L 532.414063 1000.285156 L 47.046387 1454.835205 L 324.974609 1454.835205 L 810.342285 1000.285156 L 324.974609 545.735107 L 47.046387 545.735107 Z"/>
            <path id="Path-copy" fill={ color || "white" } stroke="none" d="M 1467.939209 545.735107 L 1190.010498 545.735107 L 1675.378418 1000.285156 L 1190.010498 1454.835205 L 1467.939209 1454.835205 L 1953.307129 1000.285156 L 1467.939209 545.735107 Z"/>
        </svg>
    );
};

export default ServiceAssistancePatrolGlyph;