// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const WeatherRadarGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"}
            viewBox={viewBox || "0 0 500 500"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M316.4 161.7C299 150.5 278.2 144 256 144c-61.9 0-112 50.1-112 112s50.1 112 112 112s112-50.1 112-112h48c0 78.2-56.1 143.3-130.3 157.2c-7.3-8.1-17.9-13.2-29.7-13.2s-22.4 5.1-29.7 13.2c-64.5-12.1-115.4-63-127.5-127.5c8.1-7.3 13.2-17.9 13.2-29.7s-5.1-22.4-13.2-29.7C112.7 152.1 177.8 96 256 96c35.5 0 68.3 11.6 94.9 31.2l34.2-34.2C349.7 64.8 304.8 48 256 48C152.7 48 66.9 123.4 50.7 222.1C39.5 229.2 32 241.7 32 256s7.5 26.8 18.7 33.9c14.4 87.7 83.6 157 171.4 171.4c7.1 11.3 19.6 18.7 33.9 18.7s26.8-7.5 33.9-18.7C388.6 445.1 464 359.3 464 256h48c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0c62.1 0 118.9 22.1 163.3 58.8L463 15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-182 182c3.3 7.7 5.1 16.1 5.1 25c0 35.3-28.7 64-64 64s-64-28.7-64-64s28.7-64 64-64c8.9 0 17.3 1.8 25 5.1l35.4-35.4z"
                fill={color}
            />
        </svg>
    );
};

export default WeatherRadarGlyph;