// libraries
import { 
    commonFilterBySearch, commonGetAll_api, commonInit_api,
    commonGetById_api, createAlgoSlice 
} from "./common";
import * as DateFormat from "@caps-mobile/date-time";
// types & models
import { AleaAlertNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "~/store/algo-api/test-data/alea-alerts/all-alea-alerts-10-5-22.json";
import { IATAleaAlertDto } from "@algo/network-manager/models/v3";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aleaAlerts}`;

// create list slice
export const aleaAlertSlice = 
    createAlgoSlice(EAlgoApiObjectType["alea-alert"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit
} = aleaAlertSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new AleaAlertNetworkManager(apiUrl), 
        id, 
        aleaAlertSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("alea-alert: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["alea-alert"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api());
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    if (mode === "init")
        return commonInit_api(
            new AleaAlertNetworkManager(apiUrl), 
            aleaAlertSlice,
        )
    else 
        return commonGetAll_api(
            new AleaAlertNetworkManager(apiUrl), 
            aleaAlertSlice,
        );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(aleaAlertSlice, searchTerm, searchMatch);
    
};

const searchMatch = (
    alert: IATAleaAlertDto, 
    searchTerm: string = "",
): boolean => {

    if (!searchTerm) return true;

    let formattedString: string = 
        DateFormat.defaultDateString(new Date(alert.publishDate));

    if (
        alert.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        alert.text?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        formattedString?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )   return true;

    return false;
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const aleaAlertReducer = aleaAlertSlice.reducer;