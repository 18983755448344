// libraries
import React, { useEffect, useState } from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import { getCoords as getEventCoords } from "~/store/algo-api/slices/crashes";
import { getCoords as getGroupedCameraCoords } from "~/store/algo-api/slices/camera-group";
import { getCoords as getSignCoords } from "~/store/algo-api/slices/message-signs";
import { getCoords as getFacilityCoords } from "~/store/algo-api/slices/state-facilities";
import { getCoords as getASAPCoords } from "~/store/algo-api/slices/service-assistance-patrols";
import { getCoords as getOther511Coords } from "~/store/algo-api/slices/traveler-information-systems";
import { getCoords as getFerryCoords } from "~/store/algo-api/slices/ferries";
import { createDefaultMarker, EHereMapMarkerType, IHereMarkerInit } from "@algo/here-maps";
// hooks & context
import { usePrevious } from "@algo/hooks";
// types & models
import { EAlgoLayerType, ICoord, IMarkerTapObject } from "~/interfaces";
import { mapLayerToPin, mapStateToPin } from "~/resources/icons/algo-traffic-icons/map-pin";
import { 
    EATFacilityType, IATLocationDto, IATMessageSign, 
    IATMessageSignPageDto, IATTravelerInformationSystemDto 
} from "@algo/network-manager/models/v3";
import { useSelector } from "react-redux";

// given a map object type, returns the appropriate map marker size object
export const mapMarkerTypeToSize = (
    type: EAlgoLayerType
): {w: number, h: number} => {

    switch(type){
        case EAlgoLayerType.other511:
            return {w: 42, h: 42};
        case EAlgoLayerType["camera-group"]:
        case EAlgoLayerType.ferry:
        case EAlgoLayerType["message-sign"]:
        case EAlgoLayerType["state-facility"]:
        case EAlgoLayerType["weather-alert"]:
            return {w: 23, h: 23};
        default:
            return {w: 28, h: 28};
    }
}

// given a map object type, returns the appropriate function for parsing its coords
export const mapMarkerTypeToCoordFunction = (
    type: EAlgoLayerType
): (item: any) => ICoord | null => {

    switch(type){
        case EAlgoLayerType.other511:
            return getOther511Coords;
        case EAlgoLayerType["camera-group"]:
            return getGroupedCameraCoords;
        case EAlgoLayerType.ferry:
            return getFerryCoords;
        case EAlgoLayerType["message-sign"]:
            return getSignCoords;
        case EAlgoLayerType["state-facility"]:
            return getFacilityCoords;
        case EAlgoLayerType["service-assistance-patrol"]:
            return getASAPCoords;
        default:
            return getEventCoords;
    }
}

// given a list of data objects, builds a list of marker objects for the map
export const buildMarkerInitsByType = (
    dataList: any[], 
    objectType: EAlgoLayerType ,
    getCoords: (object: any) => { lat: number, lng: number} | null,
): IHereMarkerInit[] => {

    if (!dataList) return [];

    let markerInits: IHereMarkerInit[] = [];

    if (!nuonoe(dataList)) return markerInits;

    let icon: any = null;

    for (let i = 0; i < dataList.length; i++){

        let nextObject: any = dataList[i];

        let type: EHereMapMarkerType = EHereMapMarkerType.Default;

        let pinResource: any;
        let crossOrigin: boolean = false;

        let resourceVersion: number = 0;

        switch(objectType){
            case EAlgoLayerType["state-facility"]:
                resourceVersion = ( nextObject.type === EATFacilityType.RestArea ) ? 0 : 2;
                if (nextObject.open) resourceVersion++;
                pinResource = mapLayerToPin(EAlgoLayerType["state-facility"], resourceVersion);
                icon = {
                    iconSrc: pinResource,
                    size: mapMarkerTypeToSize(objectType),
                    crossOrigin
                };

                if(nextObject.locations && nextObject.locations.length > 0){
                    nextObject.locations.forEach((location: IATLocationDto) => {
                        let nextCoords: H.geo.Point = getCoords(location) as H.geo.Point;

                        markerInits.push({
                            type,
                            position: nextCoords,
                            options: {icon},
                            data: {
                                type: objectType,
                                data: nextObject
                            }
                        });
                    });
                }
                break;
            case EAlgoLayerType["message-sign"]:
                resourceVersion = 0;
                let pages: IATMessageSignPageDto[] = (nextObject as IATMessageSign).pages;
                if (pages && pages.length > 0) resourceVersion++;
                pinResource = mapLayerToPin(EAlgoLayerType["message-sign"], resourceVersion);
                break;
            case EAlgoLayerType["other511"]:
                let other511Object: IATTravelerInformationSystemDto = nextObject;
                // let markerIcons: any = other511Object.markerIcons;
                // pinResource = (markerIcons) ? markerIcons["png"] : mapLayerToPin(EAlgoLayerType.other511);
                // crossOrigin = true;
                pinResource = mapStateToPin(other511Object.state || "unknown");
                break;
            default:
                pinResource = mapLayerToPin(objectType);
        }
        
        if(objectType !== EAlgoLayerType["state-facility"])
        {
            let nextCoords: H.geo.Point = getCoords(nextObject) as H.geo.Point;
            
            icon = {
                iconSrc: pinResource,
                size: mapMarkerTypeToSize(objectType),
                crossOrigin
            };

            markerInits.push({
                type,
                position: nextCoords,
                options: {icon},
                data: {
                    type: objectType,
                    data: nextObject
                }
            });
        }
    }

    return markerInits;
};

// a hook for building a group of Here Map markers for a given type of map item
// expects a list of data, the EAlgoLayerType of the data, and the checksum(api) value for the data
export const useMarkerGroup = (
    map: H.Map | undefined, 
    type: EAlgoLayerType,
    markerTapCallback?: (markerData: IMarkerTapObject) => void
): H.map.Group | null => {

    // get the respective data and checksum
    const dataStore: any = useSelector( (state: any) => state[type]);
    const data: any = dataStore.data;
    const checksum: number = dataStore.lastChecksum;

    // track previous checksum
    const prevChecksum = usePrevious(checksum);

    // keep a list of created markers
    const [ markerGroup, setMarkerGroup ] = useState<H.map.Group | null>(null);

    useEffect(
        () => {

            // only execute logic if data exists and 
            // either checksum has changed or no markerGroup exists
            if (
                map &&
                data && 
                data.length > 0 && 
                (
                    (prevChecksum !== checksum) || 
                    !markerGroup
                )
            ){
                // determine which function to use to extract object coords
                let getCoords: (item: any) => ICoord | null = 
                    mapMarkerTypeToCoordFunction(type);

                // build the list of marker inits
                let markerInits: IHereMarkerInit[] = 
                    buildMarkerInitsByType(data, type, getCoords);

                // create a new marker group to hold the new marker objects if necessary
                let newMarkerGroup: H.map.Group = markerGroup || new window.H.map.Group();

                // remove all the current marker group if anything exists
                newMarkerGroup.forEach( (marker) => {marker.dispose();})
                newMarkerGroup.removeAll();

                // create marker object for each init and append them to the new group
                markerInits.forEach( (markerInit: IHereMarkerInit) => {
                    let marker: H.map.Marker | null = createDefaultMarker(markerInit);
                    marker?.setData(markerInit.data);

                    marker?.addEventListener('tap', (evt: any) => { 
                        markerTapCallback && markerTapCallback(evt.target.getData())
                    });

                    if (marker) {
                        newMarkerGroup.addObject(marker);
                    }
                });

                // update the group state
                setMarkerGroup(newMarkerGroup);
            }

            // cleanup logic
            return () => {
                //
            }

        }, [map, data, type, checksum, markerGroup]
    );

    // return the current marker group state
    return markerGroup;
};