// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const RegionalEvent: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"} 
            viewBox={viewBox || "0 0 300 300"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" fill="#9C5A34"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="white" strokeWidth="12"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="black" strokeWidth="12"/>
            <path d="M150 187.139L197.817 216L185.126 161.61L227.371 125.014L171.742 120.296L150 69L128.258 120.296L72.6289 125.014L114.874 161.61L102.183 216L150 187.139Z" fill="white"/>
        </svg>

    );
};

export default RegionalEvent;