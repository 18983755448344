// libraries
import * as React from "react";
// styles
import * as PageStyles from "../../views/page-template/Styled";
// types & models
// constants
import PageTemplate from "../../views/page-template/PageTemplate";
import SponsorsSection from "./sponsors-section/SponsorsSection";
import DisclosuresSection from "./disclosures-section/DisclosuresSection";
import DevelopedBySection from "./developed-by-section/DevelopedBySection";
import CopywriteSection from "./copywrite-section/CopywriteSection";
import VersionSection from "./version-section/VersionSection";

const aboutText: string = `The ALGO Traffic app is part of the Alabama Department` +
` of Transportation Intelligent Transportation Systems providing the state of Alabama` + 
` with real time traffic data.`;

export type IProps = {
    //
};

export const AboutPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    return (
        <PageTemplate title={"About"}>

            <PageStyles.PageText style={{marginBottom: "20px"}}>
                {aboutText}
            </PageStyles.PageText>

            <SponsorsSection />

            <DisclosuresSection />

            <DevelopedBySection />

            <CopywriteSection />

            <VersionSection />

        </PageTemplate>
    );
};

export default AboutPage;