// libraries
import { IATCameraDto } from "@algo/network-manager/models/v3";
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
    camera: IATCameraDto;
    showRouteOverlay?: boolean;
    showCityOverlay?: boolean;
};

export const CameraInfoOverlay: React.FC<IProps> = (props) => {

    const { camera, showRouteOverlay, showCityOverlay } = props;
    const { 
        displayRouteDesignator = "Primary Route", 
        displayCrossStreet = "Cross Street", 
        city, county 
    } = camera.location;
    const cityTag: string = city ? city : county ? county : "City";

    return (
        <SC.OverlayWrapper>
            {   showRouteOverlay &&
                <SC.TopRow>

                    <SC.RoutesOverlay>

                        <SC.Primary>{displayRouteDesignator}</SC.Primary>

                        <SC.Cross>{displayCrossStreet}</SC.Cross>

                    </SC.RoutesOverlay>

                </SC.TopRow>
            }
            {   showCityOverlay &&
                <SC.BottomRow>

                    <SC.TagOverlay>

                        <SC.Tag>{cityTag}</SC.Tag>

                    </SC.TagOverlay>

                </SC.BottomRow>
            }
        </SC.OverlayWrapper>
    );
};

export default CameraInfoOverlay;