import { colors as bsColors } from "./colors-bs";

export const ColorsDark = {
    ...bsColors,
    "disabled": {
        "hex": "E9ECEF",
        "hex-string": "#E9ECEF",
        "rgb": {
            "red": "233",
            "green": "236",
            "blue": "239"
        },
        "rgba": "rgba(233, 236, 239, 1)",
        "alpha": 1.0
    },
    "text-primary": {
        "hex": "FFFFFF0C",
        "hex-string": "#FFFFFF0C",
        "rgb": {
            "red": "255",
            "green": "255",
            "blue": "255"
        },
        "rgba": "rgba(255,255,255, 0.75)",
        "alpha": 0.75
    },
    "text-secondary": {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0,1)",
        "alpha": 1.0
    },
    "text-disabled": {
        "hex": "A9A9AC",
        "hex-string": "#A9A9AC",
        "rgb": {
            "red": "169",
            "green": "169",
            "blue": "172"
        },
        "rgba": "rgba(169, 169, 172, 1)",
        "alpha": 1.0
    },
    "text-input": {
        "hex": "495057",
        "hex-string": "#495057",
        "rgb": {
            "red": "73",
            "green": "80",
            "blue": "87"
        },
        "rgba": "rgba(73, 80, 87, 1)",
        "alpha": 1.0
    },
    "input-background": {
        "hex": "#262626",
        "hex-string": "##262626",
        "rgb": {
            "red": "38",
            "green": "38",
            "blue": "38"
        },
        "rgba": "rgba(38, 38, 38, 1)",
        "alpha": 1.0
    },
    "bg-page": {
        "hex": "232326",
        "hex-string": "#232326",
        "rgb": {
            "red": 35,
            "green": 35,
            "blue": 38
        },
        "rgba": "rgba(35,35,38,1)",
        "alpha": 1.0
    },
    "bg-primary": {
        "hex": "333333",
        "hex-string": "#333333",
        "rgb": {
            "red": 51,
            "green": 51,
            "blue": 51
        },
        "rgba": "rgba(51,51,51,1)",
        "alpha": 1.0
    },
    "bg-secondary": {
        "hex": "66666B",
        "hex-string": "#66666B",
        "rgb": {
            "red": 102,
            "green": 102,
            "blue": 107
        },
        "rgba": "rgba(102,102,107,1)",
        "alpha": 1.0
    },
    "bg-highlight": {
        "hex": "767679",
        "hex-string": "#767679",
        "rgb": {
            "red": "118",
            "green": "118",
            "blue": "121"
        },
        "rgba": "rgba(118, 118, 121, 1)",
        "alpha": 1.0
    },
    "bg-grey": {
        "hex": "333333",
        "hex-string": "#333333",
        "rgb": {
            "red": 51,
            "green": 51,
            "blue": 51
        },
        "rgba": "rgba(51,51,51,1)",
        "alpha": 1.0
    },
    "bg-disabled": {
        "hex": "767679",
        "hex-string": "#767679",
        "rgb": {
            "red": "118",
            "green": "118",
            "blue": "121"
        },
        "rgba": "rgba(118, 118, 121, 1)",
        "alpha": 1.0
    },
    "primary": {
        "hex": "01B561",
        "hex-string": "#01B561",
        "rgb": {
            "red": 1,
            "green": 181,
            "blue": 97
        },
        "rgba": "rgba(1,181,97, 1)",
        "alpha": 1.0
    },
    "primary-25": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.1)",
        "alpha": 0.1
    },
    "primary-50": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.5)",
        "alpha": 0.5
    },
    "primary-75": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.75)",
        "alpha": 0.75
    },
    "secondary": {
        "hex": "",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0,1)",
        "alpha": 1.0
    },
    "algo-green": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 1)",
        "alpha": 1.0
    },
    "bg-menu": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56,1)",
        "alpha": 1.0
    },
    "bg-menu-link-highlight": {
        "hex": "",
        "hex-string": "",
        "rgb": {
            "red": 61,
            "green": 61,
            "blue": 68
        },
        "rgba": "rgba(61, 61, 68, 0.1)"
    },
    "border-primary": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255, 255, 255, .5)",
        "alpha": 0.5
    },
    "border-secondary": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255, 255, 255, 0.5)",
        "alpha": 0.5
    },
    "border-disabled": {
        "hex": "373737",
        "hex-string": "#373737",
        "rgb": {
            "red": "55",
            "green": "55",
            "blue": "55"
        },
        "rgba": "rgba(55, 55, 55, 1)",
        "alpha": 1.0
    },
    "border-primary-old": {
        "hex": "1e824c",
        "hex-string": "#1e824c",
        "rgb": {
            "red": "30",
            "green": "130",
            "blue": "76"
        },
        "rgba": "rgba(74, 213, 138, 1)",
        "alpha": 1.0
    },
    "drop-shadow": {
        "hex": "333333",
        "hex-string": "#333333",
        "rgb": {
            "red": 51,
            "green": 51,
            "blue": 51
        },
        "rgba": "rgba(51,51,51,0)",
        "alpha": 0
    },
    "scrollbar-bg": {
        "hex": "232326",
        "hex-string": "#232326",
        "rgb": {
            "red": 35,
            "green": 35,
            "blue": 38
        },
        "rgba": "rgba(35,35,38,1)",
        "alpha": 1.0
    },
    "scrollbar-thumb": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255, 255, 255, 0.75)",
        "alpha": 0.5
    },
    "scrollbar-thumb-hover": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255, 255, 255, 1)",
        "alpha": 0.5
    },
    "hover": {
        "hex": "1C1C1E",
        "hex-string": "#1C1C1E",
        "rgb": {
            "red": 28,
            "green": 28,
            "blue": 30
        },
        "rgba": "rgba(28, 28, 30, 1)",
        "alpha": 1.0
    },
    "divider": {
        "hex": "2C2C2E",
        "hex-string": "#2C2C2E",
        "rgb": {
            "red": 44,
            "green": 44,
            "blue": 46
        },
        "rgba": "rgba(44 ,44 ,46, 1)",
        "alpha": 1.0
    },
    toolbox: {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0,1)",
        "alpha": 1.0
    }
};

export default ColorsDark;