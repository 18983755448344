// libraries
import { commonFilterBySearch, commonGetById_api, createAlgoSlice } from "./common";
// types & models
import { IProcessedResponse, WeatherAlertNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
// test data
import T_DATA from "~/store/algo-api/test-data/weather-alert-layer/all-weather-alerts-layer-11-29-22.json";
import { buildLastResponse } from "~/store/library";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherLayer}`;

// create list slice
export const weatherAlertSlice = 
    createAlgoSlice(EAlgoApiObjectType["weather-alert"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit
} = weatherAlertSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new WeatherAlertNetworkManager(apiUrl), 
        id, 
        weatherAlertSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("weather-alert: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["weather-alert"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: WeatherAlertNetworkManager = new WeatherAlertNetworkManager(apiUrl);

        manager.getLayer(buildLastResponse(getState()), {includeGeometry: true}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        dispatch(successFunction( { ...response.getReduxObject(), data: response.data.features} ))
                    }
                }
            ).catch(
                (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
            )
    }

};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(weatherAlertSlice, searchTerm, searchMatch);
    
};

const searchMatch = (feature: any, searchTerm: string = ""): boolean => {

    let name: string = feature.properties.name;
    
    if (!searchTerm) return true;

    if (
        name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) return true;

    return false;
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const weatherAlertReducer = weatherAlertSlice.reducer;