// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const IncidentGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "32"} height={height || "157"} 
            viewBox={ viewBox || "0 0 32 157" } fill="none" 
        >
            <path 
                d="M31.2153 0.569946V58.0489L23.6583 112.78H8.5443L0.529297 58.0489V0.569946H31.2153ZM31.2153 141.634C31.2153 145.756 29.6886 149.344 26.6353 152.397C23.582 155.298 19.918 156.748 15.6433 156.748C11.5213 156.748 7.93363 155.221 4.8803 152.168C1.97963 149.115 0.529297 145.603 0.529297 141.634C0.529297 137.359 1.97963 133.695 4.8803 130.642C7.93363 127.589 11.5213 126.062 15.6433 126.062C19.918 126.062 23.582 127.589 26.6353 130.642C29.6886 133.695 31.2153 137.359 31.2153 141.634Z" 
                fill={color || "black"}
            />
        </svg>
    );
};

export default IncidentGlyph;
