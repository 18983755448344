// libraries
import { useEffect, useState } from "react";

export const useWatchCountdown = (
    streamTimeout: number, 
    timeoutCallback: () => void, 
    playing: boolean
) => {

    const [ timerProgress, setTimerProgress ] = useState<number>(0);

    let timeoutId: NodeJS.Timer | null = null;

    useEffect(
        () => {

            if (playing && streamTimeout != -1){
                if (timerProgress + 1 > streamTimeout){
                    timeoutId && clearTimeout(timeoutId);
                    timeoutId = null;
                    timeoutCallback();
                }
    
                else {
                    timeoutId = setTimeout(
                        () => {
                            setTimerProgress(timerProgress => timerProgress + 1);
                        }, 1000
                    );
                }
            }

            return () => {
                timeoutId && clearTimeout(timeoutId);
                timeoutId = null;
            }

        }, [timerProgress, timeoutId, playing]
    );

    return timerProgress;

};