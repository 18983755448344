// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const WeatherAlertGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"}
            viewBox={viewBox || "0 0 300 300"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M84.375 133.637C84.375 132.758 84.375 131.879 84.375 131C84.375 105.219 105.176 84.125 131.25 84.125C148.535 84.125 163.77 93.793 171.68 107.855C176.074 104.633 181.641 102.875 187.5 102.875C203.027 102.875 215.625 115.473 215.625 131C215.625 134.809 214.746 138.031 213.574 141.254C230.859 144.77 243.75 160.004 243.75 177.875C243.75 198.676 226.758 215.375 206.25 215.375H98.4375C75 215.375 56.25 196.625 56.25 173.188C56.25 155.023 67.9688 139.203 84.375 133.637Z"
                fill={color}
            />
        </svg>
    );
};

export default WeatherAlertGlyph;