// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledNavBarSection = styled.div`
    width: 100%;
`;

export const SectionLinks = styled.div<{panelOpen?: boolean}>`
    width: 100%;
    ${flexSmart("space-evenly center")}
    
    padding-top: 10px;
    box-sizing: border-box;

    ${props => props.panelOpen 
        ? `
        `
        : `
            padding-top: 0;
        `
    }
`;

export const NavLink = styled.a<{index?: number, isSelected?: boolean, panelOpen?: boolean}>`

    ${flexSmart("center center")}
    flex-direction: column;

    padding: 10px 10px 10px 10px;
    box-sizing: border-box;

    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;

    ${props => props.isSelected 
        ? `
            color: ${props.theme.colors["primary"].rgba};
        ` 
        : `color: ${props.theme.colors["text-primary"].rgba};`
    }

    :hover {
        cursor: pointer;
    }

    & > :not(:only-child){
        margin-right: 8px;
    }
`;

export const LinkIcon = styled.div<{isSelected?: boolean}>`
    font-size: 20px;

    ${props => props.isSelected 
        ? `
            color: ${props.theme.colors["primary"].rgba};
        ` 
        : `color: ${props.theme.colors["text-primary"].rgba};`
    }
`;

export const LinkName = styled.div<{panelOpen?: boolean}>`
    
`;