// libraries
import { useState } from "react";
import {EThemeOptions, getTheme} from "~/theme";

/* 
    The theme context definition is provided externally,
    but this function handles creating and returning the local
    state values to plug into the initial theme context.
*/
export const useThemeContext = () => {
    // toggle value for theme mode light/dark etc.
    const [themeMode, setThemeMode] = 
        useState<EThemeOptions>(EThemeOptions.Light);

    // holds the actual JSObject containing theme values
    const [themeObject, setThemeObject] =
        useState<any>(getTheme(themeMode));

    return [ themeMode, setThemeMode, themeObject, setThemeObject ];
}