// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { CARD_PADDING, BORDER_RADIUS } from "~/constants";
import { EThemeOptions } from "~/theme";

export const Title = styled.div<{isMediumMax?: boolean, isSmallMax?: boolean, weather?: boolean, modal?: boolean}>`
    font-size: ${props => props.modal ? ' 24px' : ' 16px'};
    margin-bottom: ${props => props.modal ? ' 20px' : ' 0px'};
    font-weight: 600;
    color: ${props => props.weather ? 'rgba(235,235,245, 1)' : props.theme.colors["text-primary"].rgba};
    box-sizing: border-box;
    line-height: ${props => props.isMediumMax ? '15px' : '19px'};
    padding-bottom: ${props => props.isMediumMax ? '15px' : '10px'};
`;

export const LegendItems = styled.div<{isMediumMax?: boolean, weather?: boolean}>`
    font-size: ${props => props.isMediumMax ? '14px' : 'auto'};

    color:  ${props => props.weather ? 'rgba(235,235,245, 1)' : props.theme.colors["text-primary"].rgba};

    & > :not(:last-child) {
        margin-bottom: 10px;
        box-sizing: border-box;
    }
`;

export const TrafficLegendItems = styled.div<{isMediumMax?: boolean}>`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const WeatherRadarLegendItems = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const WeatherRadarProgressItems = styled.div`
    align-items: center;
    width: 100%;
`;

export const WeatherRadarTime = styled.div`
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 5px;
    color: rgba(235,235,245, 1);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
`;

export const Colors = styled.div<{isMediumMax?: boolean}>`
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 3px; 
    overflow: hidden;
    flex: 1;
`;

export const ColorBox = styled.div<{fillColor?: string}>`
    flex: 1;
    height: 15px;
    background-color: ${props => props.fillColor};
    box-sizing: border-box;
`;

export const Icon = styled.div`
    ${flexSmart("start center")}
    gap: 10px;
`;

export const LegendText = styled.div<{left?: boolean}>`
    color: ${props => props.theme.colors["text-primary"].rgba};
    margin-left: ${props => props.left ? '' : '8px'};
    margin-right: ${props => props.left ? '8px' : ''};
    flex: 0 1 auto;
    font-size: 16px;
    font-weight: 500;
`;

export const LegendWrapper = styled.div<{isMediumMax?: boolean}>`
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Legend = styled.div<{precipitation?: boolean}>`
    margin-top: ${props => props.precipitation ? '30px' : '0px'};
`;

export const Loading = styled.div`
    width: 100%;
    height: 15px;
    background-color: ${props => props.theme.mode === EThemeOptions.Dark ? `#696969` : `#E0E0E0`};
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    
    @keyframes shimmer {
    100% {-webkit-mask-position:left}
    }
`;

export const StyledLegend = styled.div<{isMediumMax?: boolean, radar?: boolean, weather?: boolean}>`
    width: 100%;
    min-width: ${props => props.isMediumMax ? '100px' : '225px'};
    padding: ${props => props.radar ? '10' : CARD_PADDING}px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: ${BORDER_RADIUS}px;
    background-color: ${props => (props.weather || props.radar) ? 'rgba(30,30,30,.75)' : props.theme.colors["bg-primary"].rgba};
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
`;