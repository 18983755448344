// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledSignOut = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const LogoWrap = styled.div`
    padding: 50px 0 30px 0;
    box-sizing: border-box;
`;

export const NoticeText = styled.div`
    font-size: 14px;
    line-height: 160%;
    color: #76838f;
`;

export const ReturnLinkText = styled.div`
    margin-top: 10px;

    color: #007556;
    @media (prefers-color-scheme: dark) {
        color: #01B561;
    }

    font-weight: 300;
    font-size: 16px;
    text-decoration: underline;

    :hover{
        cursor: pointer;
    }
`;