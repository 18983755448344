// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { mapPageTypeToIcon } from "~/resources";
// hooks and contexts
import { EThemeOptions, ThemeContext } from "~/theme";

export type IProps = {
    loading?: boolean;
    pageType: string;
};

export const NoResultsTemplate: React.FC<IProps> = (props) => {

    const { loading, pageType } = props;
    
    const theme: any = React.useContext(ThemeContext);
    const icon = mapPageTypeToIcon(pageType, theme.mode === EThemeOptions.Dark);

    return (
        <SC.NoResultsPage>
            <SC.Icon>{icon}</SC.Icon>
            { loading && <SC.NoResultsText>Loading...</SC.NoResultsText> }
            { !loading && <SC.NoResultsText>No {pageType}</SC.NoResultsText> }
        </SC.NoResultsPage>
    );
};

export default NoResultsTemplate;