// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const LaneGraphicColumn = styled.div`
        height: 315px;
        width: 100%;

        ${flexSmart("flex-start stretch")}
        flex-direction: column;

        padding-left: 10px;
        box-sizing: border-box;

        overflow: auto;
    `;

        export const DescriptionText = styled.div`
            width: 100%;
            ${flexSmart("flex-start center")}
            padding-bottom: 5px;
            color: ${props => props.theme.colors["text-primary"].rgba};
        `;

        export const LastUpdatedText = styled.div`
            text-align: right;

            font-weight: 400;
            font-size: 12px;
            line-height: 133%;
            color: ${props => props.theme.colors["text-primary"].rgba};
        `;

        export const LanesRow = styled.div`
            height: 100%;

            ${flexSmart("center center")}
            flex-direction: column;
            padding-bottom: 5px;
            color: ${props => props.theme.colors["text-primary"].rgba};
        `;

        export const DirectionHeader = styled.div`
            font-weight: 600;
            padding-bottom: 5px;
            color: ${props => props.theme.colors["text-primary"].rgba};
        `;