// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// constants
import SiteNavContext from "~/navigation/SiteNavContext";
import AutoScroller from "../auto-scroller/AutoScroller";
import { RemoveScrollbar } from "@caps-mobile/styled-lib";
import { useBreakpoint } from "~/library/useBreakpoint";

export type IProps = {
    color?: string;
    contentString?: string;
    content?: React.ReactNode;
};

export const Ticker: React.FC<IProps> = (props) => {

    const { color, contentString = "", content} = props;

    const navContext: any = React.useContext(SiteNavContext);

    const { sm: isSmallMax } = useBreakpoint();

    return (
        <SC.StyledTicker color={color} panelOpen={navContext.panelOpen} isSmallMax={isSmallMax}>
            <AutoScroller
                styleOverwrite={`${RemoveScrollbar} background-color: transparent;`}
                scrollSpeed={15}
                initialDelay={2 * 1000} finalDelay={2 * 1000}
            >
                {contentString ? contentString : null}
                {content ? content : null}
            </AutoScroller>
        </SC.StyledTicker>
    );

};

export default Ticker;