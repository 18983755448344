// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { IATServiceAssistancePatrolDto, IATServiceAssistancePatrolRoute } from "@algo/network-manager/models/v3";
import { dePascal } from "~/library/string-tools";

export type IProps = {
    object: IATServiceAssistancePatrolDto;
};

export const ASAPDetails: React.FC<IProps> = (props) => {

    const {
        object
    } = props;

    return (
        <SC.StyledASAPDetails>

            <SC.PropertyRow>
                <SC.PropertyValue>{object.description}</SC.PropertyValue>
            </SC.PropertyRow>

            { object.routes && object.routes.length > 0 &&
                <SC.PropertyRow>
                    <SC.PropertyName>Route Schedule</SC.PropertyName>
                </SC.PropertyRow>
            }

            { object.routes && object.routes.length > 0 &&
                <SC.List>
                {   object.routes && object.routes.map(
                    (route: IATServiceAssistancePatrolRoute, index: number) => {
                        return (
                            <SC.ListRow key={`${index}`}>
                                <SC.ListValue key={`${index}-route`}>
                                    <SC.Bold>{`${route.routeDesignator} (MP ${Math.trunc(route.startLinearReference)} 
                                        - ${Math.trunc(route.endLinearReference)})`}</SC.Bold>
                                </SC.ListValue>
                                <SC.ListValue key={`${index}-schedule`}>
                                    {route.scheduleInformation}
                                </SC.ListValue>
                            </SC.ListRow>
                        )
                    }
                )}
                </SC.List>
            }

        </SC.StyledASAPDetails>
    )
};

export default ASAPDetails;