// libraries
import { mapIconNameToComp } from "~/resources";
import React from "react";
// hooks & context
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllMapLayers, updateMapLayersObject } from "~/store/map-layers/map-layers";
import { EThemeOptions, ThemeContext } from "~/theme";
// constants
import { layerColors } from "~/constants";
import { IToggleOption } from "~/components/views/icon-toggle-list/IconToggleList";

const mapLayerKeyToName = (key: string): string => {
    switch(key){
        case "all":
            return "All Layers";
        case "camera-group":
            return "Camera";
        case "service-assistance-patrol":
            return "ASAP";
        case "other511":
        case "traveler-information-system":
        case "traveler-information-systems":
            return "Other 511 Systems";
        case "weather-radar":
            return "Radar";
        default:
            return key;
    }
}

export const useLayerOptions = () => {

    const dispatch = useDispatch();
    const mapLayersStore: any = useSelector( (state: any) => state["map-layers"]);

    const [ layerOptions, setLayerOptions ] = useState<IToggleOption[]>([]);

    const theme: any = React.useContext(ThemeContext);

    useEffect(
        () => {

            setLayerOptions (
                (mapLayersStore.mapLayers)
                    ? Object.keys(mapLayersStore.mapLayers).map(
                        (key: string) => {
                            let isActive: boolean = mapLayersStore.mapLayers[key];
                            return {
                                name: key,
                                displayName: mapLayerKeyToName(key),
                                icon: mapIconNameToComp(key, isActive, theme.mode === EThemeOptions.Dark) || null,
                                isActive: isActive,
                                activeColor: layerColors[key],
                                toggleCallback: (targetName: string) => {
                                    if (targetName === "all") 
                                        dispatch(selectAllMapLayers() as any)
                                    else{
                                        let allState: boolean = mapLayersStore.mapLayers["all"];
                                        let targetState: boolean = mapLayersStore.mapLayers[targetName];

                                        // if all is currently selected and toggle target is currently true
                                        // update all to false along with this target toggle 
                                        if (allState && targetState) allState = false;
                                        dispatch(updateMapLayersObject({
                                            ...mapLayersStore.mapLayers, 
                                            [targetName]: !mapLayersStore.mapLayers[targetName],
                                            ["all"]: allState 
                                        }) as any);
                                    }
                                }
                            }
                        }
                    )
                    : []
            );

        }, [mapLayersStore.mapLayers, theme.mode]
    )

    return layerOptions;
};