// types & models
import { IProcessedResponse, WeatherRadarNetworkManager } from "@algo/network-manager/managers/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting, weather_radar_product } from "~/constants";
import { createAlgoSlice } from "./common";
import { buildLastResponse } from "~/store/library";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherRadar}`;

export type IWeatherRadarInit = {
    [key: string]: { data: any, loading: boolean };
};

export const WeatherRadarInit: IWeatherRadarInit = {};

// create list slice
export const weatherRadarSlice = 
    createAlgoSlice("weather-radar");

// get handles for the slice's actions
const { 
    begin, success, failure, 
    beginPrecipitationLegend, successPrecipitationLegend, failurePrecipitationLegend,
    successPrecipitationLegendHead, failurePrecipitationLegendHead
} = weatherRadarSlice.actions;

// handles dispatching a data get either api or test source based on args
export const getKey = (test: boolean = isTesting, testMode?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getKey_test(testMode));
        else 
            dispatch(getKey_api());
    }
}

// retrieves data from api for this data type
export const getKey_api = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let manager = new WeatherRadarNetworkManager(apiUrl + "/Keys");
    
        manager.getKey()
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message} ))
                    else {
                        dispatch(success( { data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getKey_test = (mode?: string) => {
        
    return (
        dispatch: any,
        getState: any
    ) => {
    
        alert("weather-radar: getKey_test is not yet implemented.");

    }
};

// handles dispatching a data get from either api or test source based on args
export const getLegend = (headOnly?: boolean, test: boolean = isTesting, testMode?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        if (test){
            if (headOnly)
                dispatch(getLegend_head_test());
            else
                dispatch(getLegend_test());
        }
        else{
            if(headOnly)
                dispatch(getLegend_head_api());
            else
                dispatch(getLegend_api());
        }
    }
}

// retrieves data from api for this data type
export const getLegend_api = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(beginPrecipitationLegend());

        let manager = new WeatherRadarNetworkManager(apiUrl + "/Legends?radarProduct=" + weather_radar_product);
    
        manager.getLegend(buildLastResponse(getState()), false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failurePrecipitationLegend({ errorMessage: response.error.message} ))
                    else {
                        dispatch(successPrecipitationLegend( { data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failurePrecipitationLegend({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getLegend_test = (mode?: string) => {
        
    return (
        dispatch: any,
        getState: any
    ) => {
    
        alert("weather-radar: getLegend_test is not yet implemented.");

    }
};

// retrieves data from api for this data type
export const getLegend_head_api = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let manager = new WeatherRadarNetworkManager(apiUrl + "/Legends");
    
        manager.getLegend(buildLastResponse(getState()), true)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failurePrecipitationLegendHead({ errorMessage: response.error.message} ))
                    else {
                        dispatch(successPrecipitationLegendHead( { data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failurePrecipitationLegendHead({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getLegend_head_test = (mode?: string) => {
        
    return (
        dispatch: any,
        getState: any
    ) => {
    
        alert("weather-radar: getLegend_head_test is not yet implemented.");

    }
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const weatherRadarReducer = weatherRadarSlice.reducer;