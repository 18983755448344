// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, SS_HEIGHT, SS_WIDTH } from "~/constants";

export const SnapshotWrapper = styled.div<{isClickable?: boolean}>`
    height: ${SS_HEIGHT}px;
    width: ${SS_WIDTH}px;

    position: relative;

    ${flexSmart("space-between center")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;

    > img {
        border-radius: ${BORDER_RADIUS}px;
    }

    : hover{
        ${props => props.isClickable ? "cursor: pointer;" : ""}
    }
`;

export const FloatingPlayBtn = styled.div`
    position: absolute;
    left: calc(50% - 26px);
    top: calc(50% - 26px);
    z-index: 3;
`;