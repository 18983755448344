// libraries
import * as React from "react";

export type IVideoboardModalContext = {
    showModal: boolean;
    modalContent: React.ReactNode | null;
    setShowModal: (newVal: boolean) => void;
    setModalContent: (newVal: React.ReactNode) => void;
};

// ui state
export const VideoboardModalContext = React.createContext<IVideoboardModalContext>({
    showModal: false,
    modalContent: null,
    setShowModal: (newVal: boolean) => {},
    setModalContent: (newVal: React.ReactNode) => {}
});

// hook to initialize the contexts
export const useVideoboardModalContext = () => {

    // tracks when modal is open/closed
    const [showModal, setShowModal] = React.useState<boolean>(false);

    // tracks which nav route currently selected
    const [modalContent, setModalContent] = React.useState<React.ReactNode | null>(null);

    const VideoboardModalContextInit = {
        
        showModal, 
        setShowModal: (newVal: boolean) => setShowModal(newVal),
        
        modalContent,
        setModalContent: (newVal: React.ReactNode) => setModalContent(newVal),
    };

    return VideoboardModalContextInit;
}

export default VideoboardModalContext;