// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// constants
import DEFAULT_IMG from "~/resources/ui/graphics/default-image.png";

export type IProps = {
    src?: string;
    fallbackSrc?: string;
    width?: string;
    height?: string;
};

export const ImgWithDefault: React.FC<IProps> = (props) => {

    const { 
        src = DEFAULT_IMG, fallbackSrc = DEFAULT_IMG,
        width = "auto", height = "auto" 
    } = props;

    const [ errorCount, setErrorCount ] = React.useState<number>(0);

    const onImgError = (errObj: any) => {
        // if you have reached error count of 2 or more, something has gone wrong, use default img
        if (errorCount >= 2)
            errObj.currentTarget.src = DEFAULT_IMG;

        // if this was the first error, src failed, so use fallbackSrc
        if (errorCount === 0){
            errObj.currentTarget.src = fallbackSrc;
        }
        // if this was the second error, fallback failed as well, so use default img
        else if (errObj.currentTarget.src === fallbackSrc){
            errObj.currentTarget.src = DEFAULT_IMG;
        }

        setErrorCount(errorCount => errorCount + 1);
    }

    const [isLoading, setIsLoading] = React.useState(true);

    const handleLoad = () => {
        console.log("loaded");
        setIsLoading(false);
    };

    return (
        <SC.ImgLoadingWrapper height={height} width={width}>
            {isLoading && 
                <SC.LoadingImg height={height} />
            }
            <SC.StyledImgWithDefault
                src={src}
                width={width}
                height={height}
                onLoad={handleLoad}
                onError={onImgError}
            />
        </SC.ImgLoadingWrapper>
    );
};

export default ImgWithDefault;