// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// hooks & context
import { ThemeContext } from "~/theme";
import DataSearchOverlayContext from "~/contexts/DataSearchOverlayContext";
import SiteNavContext from "~/navigation/SiteNavContext";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faSearch } from "@fortawesome/pro-regular-svg-icons"; 
import { useBreakpoint } from "~/library/useBreakpoint";
// constants
import { moreNavBarMap } from "~/navigation/navigation-map";


export type IProps = {
    styleOverwrite?: string;
};

export const SearchBarButton: React.FC<IProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const overlayContext: any = React.useContext(DataSearchOverlayContext);
    const { sm: isSmallMax } = useBreakpoint();
    const searchText: string = "Search";
    const navContext: any = React.useContext(SiteNavContext);
    const legend: any = moreNavBarMap.navSections[0].links?.find((element) => element.name === "Legend");

    return (
        <SC.StyledSearchBarButton
            styleOverwrite={props.styleOverwrite}
            isSmallMax={isSmallMax}
            onClick={
                (evt) => { 
                    overlayContext.setShowOverlay(true);
                    document.getElementById('search')?.focus();
                }
            }
        >

            <SC.LeftSide>

                <SC.IconClickWrap>

                    <FontAwesomeIcon
                        className="fa-fw"
                        icon={faSearch}
                        size={"1x"}
                        color={theme.colors["primary"].rgba}
                    />

                </SC.IconClickWrap>

                <SC.SearchText>
                    {searchText}
                </SC.SearchText>

            </SC.LeftSide>

            <SC.RightSide>

                <SC.IconClickWrap
                    onClick={
                        (evt) => {
                            evt.stopPropagation();
                            navContext.setSelectedSiteLink([legend.id, legend.id]);
                            navContext.setSelectedRoute(legend.route);
                        }
                    }
                >

                    { isSmallMax &&
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={faQuestionCircle}
                            size={"1x"}
                            color={theme.colors["primary"].rgba}
                        />
                    }

                </SC.IconClickWrap>

            </SC.RightSide>

        </SC.StyledSearchBarButton>
    );
};

export default SearchBarButton;