// libraries
import * as React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type IProps = {
    closeCallback: () => void;
    lockOverlays: boolean;
    fullScreen: boolean;
    setLockOverlays: React.Dispatch<React.SetStateAction<boolean>>
    setFullScreen: () => void;
    fullScreenAvailable?: boolean;
};

export const VBUpperUI: React.FC<IProps> = (props) => {

    const { closeCallback, lockOverlays, fullScreen, setLockOverlays, setFullScreen, fullScreenAvailable } = props;

    return (
        <SC.UpperUIOverlay fullScreenAvailable={fullScreenAvailable}>

            <SC.UpperUIButton onClick={() => { const element = document.getElementById('videoElement'); if(element) {element.innerHTML="";} closeCallback(); }} >
                <FontAwesomeIcon icon={solid("times")} />
            </SC.UpperUIButton>

            <SC.UpperUIButton onClick={() => setLockOverlays(lockOverlays => !lockOverlays)}>
                {   (lockOverlays)
                    ? <FontAwesomeIcon icon={solid("window-maximize")} />
                    : <FontAwesomeIcon icon={regular("square")} />
                }
            </SC.UpperUIButton>

            { fullScreenAvailable && 
                <SC.UpperUIButton onClick={setFullScreen}>
                    {   (fullScreen)
                        ? <FontAwesomeIcon icon={solid("down-left-and-up-right-to-center")} />
                        : <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} />
                    }
                </SC.UpperUIButton>
            }

        </SC.UpperUIOverlay>
    );
};

export default React.memo(VBUpperUI);