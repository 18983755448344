// libraries
import * as React from "react";

export type IWeatherRadarContext = {
    start: string;
    setStart: (newVal: string) => void;
    end: string;
    setEnd: (newVal: string) => void;
    current: string;
    setCurrent: (newVal: string) => void;
};

// route cameras overlay ui state
export const WeatherRadarContext = React.createContext({
    start: '',
    setStart: (newVal: string) => {},
    end: '',
    setEnd: (newVal: string) => {},
    current: '',
    setCurrent: (newVal: string) => {}
});

export const useWeatherRadarContext = () => {

    const [start, setStart] = React.useState<string>('');
    const [end, setEnd] = React.useState<string>('');
    const [current, setCurrent] = React.useState<string>('');
   
    const WeatherRadarContextInit = {
        
        start, 
        setStart: (newVal: string) => setStart(newVal),
        end, 
        setEnd: (newVal: string) => setEnd(newVal),
        current, 
        setCurrent: (newVal: string) => setCurrent(newVal),
        
    };

    return WeatherRadarContextInit;
}

export default WeatherRadarContext;