// libraries
import * as React from "react";
import { getEnumStrings, nuonoe } from "@caps-mobile/common-lib";
// styles
import * as SC from "./Styled";
// types & models
import { 
    EATAmenityType, EATParkingType, EATPhoneType, 
    IATFacilityAmenityDto, EATFacilityType,
    IATFacilityDto, IATFacilityParkingDto, IATFacilityPhoneDto 
} from "@algo/network-manager/models/v3";
// constants
const amenityTypeStrings: string[] = getEnumStrings(EATAmenityType);
const parkingTypeStrings: string[] = getEnumStrings(EATParkingType);

export type IProps = {
    object: IATFacilityDto;
};

export const StateFacilityDetails: React.FC<IProps> = (props) => {

    const {
        object: facility
    } = props;

    const closedReason = facility.events && facility.events[0]?.description 
        ? facility.events[0]?.description  
        : facility.type === EATFacilityType.WelcomeCenter ? 'The welcome center is closed at this time'
        : facility.type === EATFacilityType.RestArea ? 'The rest area is closed at this time' 
        : '';

    return (
        <SC.StyledStateFacility>
        
            {!facility.open && 
                <SC.StyledStateFacilityClosed>
                    <SC.ClosedPropertyRow>
                        <SC.PropertyName>{`CLOSED: `}</SC.PropertyName>
                        &nbsp;&nbsp;
                        <SC.PropertyValue>{`${closedReason}`}</SC.PropertyValue>
                    </SC.ClosedPropertyRow>
                </SC.StyledStateFacilityClosed>
            }

            {facility.open &&
            <SC.StyledStateFacilityDetails>
                <SC.PropertyRow>
                    <SC.PropertyName>{`Daytime Phone: `}</SC.PropertyName>
                    &nbsp;&nbsp;
                    <SC.PropertyValue>{getDaytimePhone(facility)}</SC.PropertyValue>
                </SC.PropertyRow>

                { amenityTypeStrings.map(
                    (type: string, index: number) => {
                        if (type === "Other") return null 
                        return (
                            <SC.PropertyRow key={type + index}>
                                <SC.PropertyName>{`${mutateAmenityForDisplay(type)}: `}</SC.PropertyName>
                                &nbsp;&nbsp;
                                <SC.PropertyValue>
                                    {checkAmenitiesFor(facility, type as EATAmenityType)}
                                </SC.PropertyValue>
                            </SC.PropertyRow>
                        )
                    }
                )}

                { parkingTypeStrings.map(
                    (type: string, index: number) => {
                        let parking = checkParkingFor(facility, type as EATParkingType);
                        if (parking <= 0) return null;
                        return (
                            <SC.PropertyRow key={type + index}>
                                <SC.PropertyName>{`${mutateParkingForDisplay(type)}: `}</SC.PropertyName>
                                &nbsp;&nbsp;
                                <SC.PropertyValue>
                                    {parking}
                                </SC.PropertyValue>
                            </SC.PropertyRow>
                        )
                    }
                )}

            </SC.StyledStateFacilityDetails>
        }

        </SC.StyledStateFacility>
    )
};

export default StateFacilityDetails;


// extracts the daytime phone value from the facility phones list
export const getDaytimePhone = (facility: IATFacilityDto): string => {

    let phones: IATFacilityPhoneDto[] = facility.phones || [];
    let number: string = "??";

    // if there are no phones in the facility phones list, return "??"
    if (!nuonoe(phones)) return "??";
    // otherwise, travel the list until day-type phone is found and return its value as string
    else {
        for (let i = 0; i < phones.length; i++){
            let phone: IATFacilityPhoneDto = phones[i];
            
            if (phone.number) {
                // go ahead and save this number even if it isn't day-type
                // if no day-type is found, we will assume the only valid number found is day-type
                number = phone.number;

                // if there was a number AND the type is day, we can return early
                if (phone.type === EATPhoneType.Day) return number;
            }
        
        }

        // if day-type was never found, just retun wtvr is currently in number,
        // either the last valid number we found, or the "??" default
        return number;
    }
};

export const checkAmenitiesFor = (facility: IATFacilityDto, type: EATAmenityType): string => {

    let amenities: IATFacilityAmenityDto[] = facility.amenities || [];

    // if the facility has no amenities list, all types are false
    if (!nuonoe(amenities)) return "No";
    // if there are amenities, then travel the list and return true if an amenity is found
    // with any truthy value
    else {
        for (let i = 0; i < amenities.length; i++){
            if (amenities[i].type === type && amenities[i].description){
                return amenities[i].description || "No";
            }
        }
    }

    return "No";
};

export const checkParkingFor = (facility: IATFacilityDto, type: EATParkingType): number => {

    let parkingSpaces: IATFacilityParkingDto[] = facility.parkingSpaces || [];

    // if the facility has no amenities list, all types are false
    if (!nuonoe(parkingSpaces)) return 0;
    // if there are amenities, then travel the list and return true if an amenity is found
    // with any truthy value
    else {
        for (let i = 0; i < parkingSpaces.length; i++){
            if (parkingSpaces[i].type === type){
                return parkingSpaces[i].total || 0;
            }
        }
    }

    return 0;
};

export const mutateAmenityForDisplay = (type: string) => {

    let strings: string[] = type.split(/(?=[A-Z])/);
    let newString: string = "";
    strings.forEach(
        (str: string, index: number) => {
            if (index !== 0) newString += " ";
            newString += str;
        }
    );
    return newString;
};

export const mutateParkingForDisplay = (type: string) => {

    return `${type} Parking Spots`;
};