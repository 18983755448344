// libraries
import { 
    commonFilterBySearch, commonGetAll_api, commonInit_api,
    commonGetById_api, createAlgoSlice 
} from "./common";
// types & models
import { MessageSignsNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "~/store/algo-api/test-data/message-signs/all-message-signs-10-3-22.json";
import { ATLocation, IATLocationDto, IATMessageSignDto } from "@algo/network-manager/models/v3";
import { nuon } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).messageSigns}`;

// create list slice
export const messageSignSlice = 
    createAlgoSlice(EAlgoApiObjectType["message-sign"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit
} = messageSignSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new MessageSignsNetworkManager(apiUrl), 
        id, 
        messageSignSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("message-sign: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["message-sign"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {
    
    if (mode === "init")
        return commonInit_api(
            new MessageSignsNetworkManager(apiUrl), 
            messageSignSlice,
        )
    else 
        return commonGetAll_api(
            new MessageSignsNetworkManager(apiUrl), 
            messageSignSlice,
        );

};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(messageSignSlice, searchTerm, searchMatch);
    
};

const searchMatch = (sign: IATMessageSignDto, searchTerm: string = ""): boolean => {

    if (!searchTerm) return true;

    let location: IATLocationDto = sign.location || new ATLocation();

    if ( 
        location.displayRouteDesignator?.toLowerCase().includes(searchTerm.toLowerCase())
    ) return true;

    return false;
};

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: IATMessageSignDto
): ICoord | null => {

    let location: IATLocationDto = item.location || new ATLocation();
    let lat: number = location.latitude;
    let lng: number = location.longitude;

    if (nuon(lat) && nuon(lng)) 
        return {lat, lng}

    else 
        return null;

};

// exports the slice's reducer ( used in store file to build up master reducer )
export const messageSignReducer = messageSignSlice.reducer;