// libraries
import { createSlice } from "@reduxjs/toolkit";
import { minuteToMilli } from "@caps-mobile/date-time";
import { getEnumStrings } from "@caps-mobile/common-lib";
// types & models
import { ERefreshInterval, EMapTarget } from "~/interfaces";
// constants
const INTERVALS: number[] = 
    getEnumStrings(ERefreshInterval).map((str: string) => parseInt(str));
const TARGETS: string[] = getEnumStrings(EMapTarget);

export type ISettings = {
    autoRefresh: boolean;
    refreshInterval: ERefreshInterval;
    defaultMapTarget: EMapTarget;

    refreshTimeoutId: NodeJS.Timeout | undefined;
};

export type ISettingsActionPayload = {
    autoRefresh?: boolean;
    refreshInterval?: ERefreshInterval;
    defaultMapTarget?: EMapTarget;
}

export const SETTINGS_INIT: ISettings = {
    autoRefresh: true,
    refreshInterval: minuteToMilli(5),
    defaultMapTarget: EMapTarget.alabama,

    refreshTimeoutId: undefined,
};

export const settingsSlice = createSlice(
    {
        name: "settings",
        initialState: SETTINGS_INIT,
        reducers: {
            updateAutoRefresh: (state: any, action: any) => {
                state.autoRefresh = action.payload.autoRefresh;
            },
            updateRefreshInterval: (state: any, action: any) => {
                state.refreshInterval = action.payload.refreshInterval;
            },
            updateDefaultMapTarget: (state: any, action: any) => {
                state.defaultMapTarget = action.payload.defaultMapTarget;
            },
            updateRefreshTimeoutId: (state: any, action: any) => {
                state.refreshTimeoutId = action.payload.refreshTimeoutId;
            }
        }
    }
);

const { 
    updateAutoRefresh, updateRefreshInterval, updateDefaultMapTarget,
    updateRefreshTimeoutId
} = settingsSlice.actions;


export const initializeSettings = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        // get the settings values from the local storage if they exist
        let autoRefreshValue: string | null = localStorage.getItem("autoRefresh");

        // check that the local storage value is a valid option, otherwise use default
        let refreshIntervalValue: string | null = localStorage.getItem("refreshInterval");
        if (refreshIntervalValue && !INTERVALS.includes(parseInt(refreshIntervalValue)))
            refreshIntervalValue = minuteToMilli(5).toString();

        // check that the local storage value is a valid option, otherwise use default
        let defaultMapTargetValue: string | null = localStorage.getItem("defaultMapTarget");
        if (!defaultMapTargetValue || !TARGETS.includes(defaultMapTargetValue))
            defaultMapTargetValue = EMapTarget.alabama;

        // update store
        if (autoRefreshValue){
            dispatch(updateAutoRefresh({autoRefresh: autoRefreshValue.toLowerCase() === "true" ? true : false}))
        }
        if (refreshIntervalValue){
            dispatch(updateRefreshInterval({refreshInterval: parseInt(refreshIntervalValue)}))
        }
        if (defaultMapTargetValue){
            dispatch(updateDefaultMapTarget({defaultMapTarget: defaultMapTargetValue}))
        }
    }
    
};

export const updateSettings = (newState: ISettingsActionPayload) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {
        
        // update the store and the localStorage values for any settings values given
        if (newState.autoRefresh !== undefined) { 
            dispatch(updateAutoRefresh({autoRefresh: newState.autoRefresh}));
            localStorage.setItem("autoRefresh", newState.autoRefresh.toString());
        }
        if (newState.refreshInterval !== undefined) { 
            dispatch(updateRefreshInterval({refreshInterval: newState.refreshInterval}));
            localStorage.setItem("refreshInterval", newState.refreshInterval.toString());
        }
        if (newState.defaultMapTarget !== undefined) {
            dispatch(updateDefaultMapTarget({defaultMapTarget: newState.defaultMapTarget}));
            localStorage.setItem("defaultMapTarget", newState.defaultMapTarget);
        }
    }
};

export const resetRefreshTimeoutId = (newTimeoutId?: NodeJS.Timeout | undefined) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let currentId: NodeJS.Timeout | undefined = getState().settings.refreshTimeoutId;
        if (currentId) clearTimeout(currentId);
        dispatch(updateRefreshTimeoutId({ refreshTimeoutId: newTimeoutId }));

    }
}

export const settingsReducer = settingsSlice.reducer;