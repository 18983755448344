// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const RoadWork: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"} 
            viewBox={viewBox || "0 0 300 300"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" fill="#F4911D"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="white" strokeWidth="12"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="black" strokeWidth="12"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M138.528 52C147.431 52 154.696 59.2643 154.696 68.172C154.696 77.0797 147.431 84.3478 138.528 84.3478C129.618 84.3478 122.348 77.0797 122.348 68.172C122.348 59.2643 129.618 52 138.528 52ZM110.319 215.711H98.7097L101.162 137.934L103.841 131.256L90 117.793C101.427 106.3 112.846 94.8044 124.266 83.3043C130.052 88.4174 136.208 89.5392 142.578 88.4193L150.841 164.42C162.484 174.206 174.127 183.998 185.779 193.793C177.297 178.267 181.139 161.411 195.936 165.865L234 177.326L195.263 215.826H154.293L169.48 186.338L130.856 154.069L137.665 173.433L136.434 215.711H127.171L125.612 179.996L115.902 162.522L110.319 215.711ZM134.815 126.087L140.087 155.304L129.652 145.715L134.815 126.087ZM103.565 116.263L112.957 107.304L107.922 119.826L103.565 116.263Z" fill="black"/>
        </svg>
    );
};

export default RoadWork;