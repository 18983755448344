// libraries
import * as React from "react";
import { isTrafficEvent } from "~/store/algo-api/slices/traffic-events";
import { getFirstCameraFromGroup } from "~/store/algo-api/slices/camera-group";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks & context
import { useSelector } from "react-redux";
import { useNearbyCameras } from "./hooks";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
import { ATCamera, IATCameraDto, IATVideoBoardDto, EATStreamAccessLevel } from "@algo/network-manager/models/v3";
// components
import VideoCard from "../../video-card/VideoCard";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
import VideoboardModal from "../../modal/videoboard-modal/VideoboardModal";
import { VideoboardManager } from "../../videoboard/VideoboardManager";
import Snapshot from "../../snapshot/Snapshot";
import { SS_WIDTH } from "~/constants";

export type IProps = {
    object: any;
    type: EAlgoApiObjectType;
};

export const CamerasSection: React.FC<IProps> = (props) => {

    const { type, object } = props;

    const { xl: isXLargeMax } = useBreakpoint();

    const [ showVideo, setShowVideo ] = React.useState<{ [key: number]: boolean }>({});

    const videoboardModalContext: any = React.useContext(VideoboardModalContext);

    const isTrafficEventType: boolean = isTrafficEvent(type);

    const typeString: string = (isTrafficEventType) 
        ? "traffic-event" 
        : type;

    const objectId: number = ( typeString === "camera-group")
        ? getFirstCameraFromGroup(object).id  
        : object.id;

    const cameraObject = typeString === "camera-group" ? object.pages[0]?.items[0]?.camera : {};

    const dataStore = useSelector( (state: any) => state["nearby-cameras"] );
    const isLoading: boolean = dataStore.loading;
    const dataStoreTypeObject = dataStore[typeString];
    const camerasList: IATCameraDto[] = 
        // if has nearby cameras and camera group 
        dataStoreTypeObject && typeString === "camera-group" && dataStoreTypeObject[objectId]
        ? [cameraObject , ...dataStoreTypeObject[objectId]] 
        // if has nearby cameras and not camera or camera group
        : dataStoreTypeObject
        ? ( dataStoreTypeObject[objectId] || [] )
        // if no nearby cameras and is camera
        : typeString === "camera"
        ? [object] 
        // if no nearby cameras and not camera
        : [];

    const noHLSPlaybacks = camerasList.find(x => x.hlsUrl) ? false : true;
        
    const VM: VideoboardManager = new VideoboardManager(camerasList.filter(x => x.hlsUrl));
    //VM.videoboard.name = "Nearby Cameras";

    // data fetch hook
    useNearbyCameras(object, typeString);

    const playAllClickHandler = () => {
        videoboardModalContext.setModalContent(
            <VideoboardModal 
                videoboard={VM.videoboard}
                doneCallback={() => videoboardModalContext.setShowModal(false)} />
        );
        videoboardModalContext.setShowModal(true);
    };

    const playOneClickHandler = (camera: IATCameraDto) => {
        const SingleVM: VideoboardManager = new VideoboardManager([camera]);
        const hasHLSPlaybackURL = camera.hlsUrl ? true : false;

        if(hasHLSPlaybackURL)
        {
            videoboardModalContext.setModalContent(
                <VideoboardModal 
                    videoboard={SingleVM.videoboard}
                    doneCallback={() => videoboardModalContext.setShowModal(false)} />
            );
            videoboardModalContext.setShowModal(true);
        }
    }

    return (
        <SC.StyledCamerasSection>

           <SC.CamerasRow>

                <SC.SnapshotHeaderRow>

                    <SC.NearbyCamerasText>{`Nearby Cameras`}</SC.NearbyCamerasText>
                    
                    <SC.PlayAllText noCameras={(!camerasList || camerasList.length === 0)} onClick={(evt: any) => {evt.stopPropagation(); if(camerasList && camerasList.length > 0 && !noHLSPlaybacks) playAllClickHandler();}}>
                        {`Play All`}
                    </SC.PlayAllText>

                </SC.SnapshotHeaderRow>

                {isLoading &&
                    <SC.LoadingCamerasWrap>
                        <SC.LoadingCameras key={1}></SC.LoadingCameras>
                        <SC.LoadingCameras key={2}></SC.LoadingCameras>
                        <SC.LoadingCameras key={3}></SC.LoadingCameras>
                        {!isXLargeMax && <SC.LoadingCameras key={4}></SC.LoadingCameras>}
                    </SC.LoadingCamerasWrap>
                }

                {(camerasList && camerasList.length > 0) && !isLoading &&
                    <SC.SnapshotRow>
                        {camerasList && camerasList.map( (camera: IATCameraDto) => {
                            return (
                                <SC.SnapshotWrap key={camera.id} width={`${SS_WIDTH}px`} 
                                    onClick={ (evt: any) => {evt.stopPropagation(); playOneClickHandler(camera)}}
                                >
                                    <Snapshot camera={new ATCamera(camera)} 
                                        showPlayButton={true} height={"198px"} />

                                </SC.SnapshotWrap>
                            );
                        })}
                    </SC.SnapshotRow>
                }
                
                {(camerasList && camerasList.length === 0) && !isLoading &&
                    <SC.NoCameras>No nearby cameras</SC.NoCameras>
                }

                <SC.cameraDisclaimer>
                    ALGO Traffic cameras are a public safety tool for use in real time and not intended to be recorded or 
                    otherwise used for any commercial purpose. All unauthorized photography, recording, storing, or 
                    transmitting of visual material, data, or information gathered from ALGO traffic cameras without the 
                    permission of ALDOT is expressly prohibited.
                </SC.cameraDisclaimer>

            </SC.CamerasRow>

        </SC.StyledCamerasSection>
        
    );
};

export default CamerasSection;