// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
import * as SC from "./Styled";
// components
import WeatherLegend from "./legends/WeatherLegend";
import WeatherRadarLegend from "./legends/WeatherRadarLegend";
import WeatherRadarContext from "~/contexts/WeatherRadarContext";

export type IProps = {
    radar?: boolean;
    alerts?: boolean;
    isSmallMax?: boolean; 
};

export const LegendSection: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();
    const weatherRadarContext: any = React.useContext(WeatherRadarContext);

    return (
        /* Overlay Views */
        <OverlaySection 
            bottom={isMediumMax ? 20 : 42} left={isMediumMax ? 20 : 42} 
            styleOverwrite={`width: auto;`}
            childWrapperStyleOverwrite={`align-items: flex-start;`}
        >
            {props.radar && weatherRadarContext.start && weatherRadarContext.end && weatherRadarContext.current &&
                <SC.StyledLegend isMediumMax={isMediumMax} radar={true}>
                    <WeatherRadarLegend isSmallMax={props.isSmallMax} />
                </SC.StyledLegend>
            }
            {props.alerts && !props.isSmallMax &&
                <SC.StyledLegend isMediumMax={isMediumMax} weather={!isSmallMax}>
                    <WeatherLegend />
                </SC.StyledLegend>
            }

        </OverlaySection>
    );

};

export default LegendSection;