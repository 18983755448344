// libraries
import { commonFilterBySearch, commonGetAll_api, createAlgoSlice } from "./common";
// types & models
import { FerriesNetworkManager, IFerriesNetworkManager, IProcessedResponse, IStoreProcessedResponse } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord, IFeature, IFeatureCollection, IGeometry } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS, API_V4 } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
//import T_DATA from "~/store/algo-api/test-data/ferries/all-ferries-10-25-22.json";
import T_DATA_GEOJSON from "~/store/algo-api/test-data/ferries/all-ferries-geojson-10-25-22.json";
import { IATFerryDto, IATFerryLandingDto } from "@algo/network-manager/models/v3";
import { buildLastResponse } from "~/store/library";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).ferries}`;


export type IFerryLandingWithCoord = {
    coord: ICoord;
} & IATFerryLandingDto;

// create list slice
export const ferrySlice = 
    createAlgoSlice(EAlgoApiObjectType.ferry);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit,
    beginGeojson, successGeojson, failureGeojson
} = ferrySlice.actions;

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["ferry"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = beginGeojson;
        let successFunction = successGeojson;
        let failureFunction = failureGeojson;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: IFerriesNetworkManager = 
            new FerriesNetworkManager(apiUrl);

        manager.getAll(buildLastResponse(getState()), {includeGeometry: true}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        let reduxObject: IStoreProcessedResponse = response.getReduxObject();

                        let features: IFeature[] = response.data.features;
                        let data: IFerryLandingWithCoord[] = [];
                        let geometries: IGeometry[] = [];

                        features.forEach( (feature: any) => {
                            geometries.push(feature.geometry); 
                            data.push({...feature.properties, 
                                coord: {
                                    lat: feature.geometry.coordinates[1], 
                                    lng: feature.geometry.coordinates[0]
                                }
                            }); 
                        });
                        
                        dispatch(successFunction({...reduxObject, data, geometries}))
                    }
                }
            ).catch(
                (error: Error) => {
                    dispatch(failureFunction({ errorMessage: error.message }))
                }
            )

    }
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = beginGeojson;
        let successFunction = successGeojson;
        let failureFunction = failureGeojson;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let features: IFeature[] = T_DATA_GEOJSON.features;
        let data: IFerryLandingWithCoord[] = [];
        let geometries: IGeometry[] = [];

        features.forEach( (feature: IFeature) => { 
            geometries.push(feature.geometry); 
            data.push(
                {...feature.properties.data, 
                    coord: {
                        lat: feature.geometry.coordinates[1], 
                        lng: feature.geometry.coordinates[0]
                    }
                }
            ); 
        });
        
        setTimeout(
            () => {
                dispatch(successFunction({data, geometries, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(ferrySlice, searchTerm, searchMatch);
    
};

const searchMatch = (ferry: IATFerryDto, searchTerm: string = ""): boolean => {

    if (!searchTerm) return true;

    if (ferry.name?.toLowerCase().includes(searchTerm.toLowerCase())) return true;

    return false;
}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: IFerryLandingWithCoord
): {lat: number, lng: number} | null => {

    let lat: number = item.coord.lat;
    let lng: number = item.coord.lng;

    if (lat && lng) 
        return {lat, lng}

    else 
        return null;

};

// exports the slice's reducer ( used in store file to build up master reducer )
export const ferryReducer = ferrySlice.reducer;