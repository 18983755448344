// libraries
import { useContext, useEffect } from "react";
// context
import SiteNavContext from "~/navigation/SiteNavContext";

// triggers navigation to the given route when the session_ended component renders
export const useNavigateAfterSignout = (routeName: string = "/") => {

    const navContext = useContext(SiteNavContext);

    useEffect(
        () => {
            navContext.setSelectedRoute(routeName);
        }, []
    );
}