// libraries
import * as React from "react";
// types & models
import { D_ATSignStyle, EATDirection, EATLaneState, EATSeverity, IATLaneDirection, IATTrafficEventDto } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import RoadClosed from "~/resources/icons/algo-traffic-icons/lanes/RoadClosed";
import Lanes from "./lanes/Lanes";

export type IProps = {
    object: any;
};

export const TrafficEventDetails: React.FC<IProps> = (props) => {

    const { object } = props;

    const minutesSinceLastUpdatedAt: number = Math.abs(Date.now() - new Date(object.lastUpdatedAt).getTime()) / 60000;

    const isDirectionClosed = (direction: IATLaneDirection) => {
        return direction.lanes.every(lane => lane.state === EATLaneState.Closed) && object.severity === EATSeverity.Closed;
    }

    const {backgroundColor, glyphColor} = object.signStyle || D_ATSignStyle;

    return (
        <SC.LaneGraphicColumn>
                    
            <SC.DescriptionText>
                {(object as IATTrafficEventDto).description}
            </SC.DescriptionText>

            {   object.laneDirections.map(
                    (direction: IATLaneDirection) => {
                        return (
                            <SC.LanesRow key={`${object.id}${direction.direction}`}>

                                <SC.DirectionHeader>
                                    {decorateDirectionString(direction.direction)}
                                </SC.DirectionHeader>

                                {/* check if event is severity: closed if so, just a sign*/}
                                {
                                    isDirectionClosed(direction) &&
                                        <RoadClosed 
                                            bgColor={`#${backgroundColor.hex}`} 
                                            textColor={`#${glyphColor.hex}`} 
                                            borderColor={`#${glyphColor.hex}`} 
                                        />
                                }

                                {/* if road open: render the lanes */}
                                { !isDirectionClosed(direction) &&
                                    <Lanes direction={direction} />
                                }

                            </SC.LanesRow>
                        )
                    }
                )
            }

            <SC.LastUpdatedText>
                {`Last updated ${displayMinutesInHours(minutesSinceLastUpdatedAt)} ago.`}
            </SC.LastUpdatedText>

        </SC.LaneGraphicColumn>
    );
};

export default TrafficEventDetails;

export const decorateDirectionString = (direction: EATDirection) => {

    switch(direction){
        case EATDirection.East:
        case EATDirection.West:
        case EATDirection.South:
        case EATDirection.North:
            return `${direction}bound`;
        case EATDirection.NorthSouth:
            return `Northbound / Southbound`;
        default:
            return direction;
    }
};

// takes a given minutes number and returns a string of format:
// x hour(s), x minute(s)
export const displayMinutesInHours = (minutes: number) => {

    let d = Math.floor(minutes / 1440);
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    let min = Math.floor(m);

    let retStr: string = ``;

    if (d > 0) {
        retStr = d === 1 ? "1 day" : `${d} days`;
    }
    else {
        if (h >= 1){
            retStr += `${h} hour${h > 1 ? "s" : ""}`;
            if (m > 0) retStr += ", ";
        }

        if (m > 0 && min > 0) {
            retStr += `${min} minute${m > 1 ? "s" : ""}`;
        }
        else {
            retStr = "less than a minute";
        }
    }

    return retStr;
};