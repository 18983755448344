// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
// constants
import { lane_closed, lane_median, lane_open } from "~/constants";

export type IProps = {
    modal?: boolean;
};

export const TrafficSpeedLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();


    return (
        <SC.Legend>

            <SC.Title isMediumMax={isMediumMax} modal={props.modal}>
                {"Traffic Speeds"}
            </SC.Title>

            <SC.TrafficLegendItems isMediumMax={isMediumMax}>
                <SC.LegendText left={true}>Fast</SC.LegendText>
                <SC.Colors>
                    <SC.ColorBox fillColor={lane_open} />
                    <SC.ColorBox fillColor={lane_median} />
                    <SC.ColorBox fillColor={lane_closed} />
                    <SC.ColorBox fillColor="black" />
                </SC.Colors>
                <SC.LegendText left={false}>Blocked</SC.LegendText>
            </SC.TrafficLegendItems>

        </SC.Legend>
    );
};

export default TrafficSpeedLegend;