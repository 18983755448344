// libraries
import * as React from "react";
import DateFormatter from "~/library/DateFormatter";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// types & models
import { ATCongestionLevel, EAlgoApiObjectType } from "~/interfaces";
import { EDetailType, IDetailMap } from "../ApiObjectView";
import { 
    ATAldotMessage, ATAleaAlert, ATCamera, ATFacility, 
    ATMessageSign, ATServiceAssistancePatrol, EATEventType, EATSeverity, IATAldotMessage, 
    IATAleaAlertDto, IATCamera, IATFacilityDto, IATFerryLandingDto, IATLocation, 
    IATMessageSign, IATServiceAssistancePatrolDto, IATTrafficEvent, IATTravelTimeDto 
} from "@algo/network-manager/models/v3";
import { IATVideoBoardDto, IATVideoItemDto, IATVideoPageDto } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import EventProgress from "../../event-progress/EventProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// constants
import { signColors } from "~/constants";
import { ICWCollatedAlertDto } from "@algo/network-manager/models/v3/cw";
import { getTravelColor } from "~/theme";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks & contexts
import { ThemeContext } from "~/theme";

export type IProps = {
    object: any;
    type: EAlgoApiObjectType;
    showChevron?: boolean;
    extraStyling?: boolean;
};

export const ApiObjectDetails: React.FC<IProps> = (props) => {

    const { object, type, showChevron = true, extraStyling } = props;
    const detailMap = mapDetails(type, object);
    const { xl: isXLargeMax } = useBreakpoint();
    const theme: any = React.useContext(ThemeContext);

    return (
        <SC.StyledApiObjectDetails showChevron={showChevron}>
            {   detailMap &&
                detailMap.map(
                    (detail: IDetailMap, mapIndex: number) => {

                        let type: EDetailType = detail.type;
                        let color = getTravelColor(object, theme.mode);

                        switch(type){
                            case EDetailType.header:
                                return <SC.Header key={`header${mapIndex}`} extraStyling={extraStyling}>{detail.detail}</SC.Header>
                            case EDetailType.detail:
                                return <SC.Detail key={`detail${mapIndex}`} extraStyling={extraStyling}>{detail.detail}</SC.Detail>
                            case EDetailType.travelTime:
                                return (
                                    <SC.TravelTime key={`traveltime${mapIndex}`}>
                                        <SC.TravelTimeDetails>
                                            <SC.Detail key={`detail${mapIndex}`} extraStyling={extraStyling}>{detail.detail}</SC.Detail>
                                            <SC.Detail key={`traveldetail${mapIndex}`} extraStyling={extraStyling} travel={true} color={color}>{detail.travelDetail}</SC.Detail>
                                        </SC.TravelTimeDetails>
                                        {!isXLargeMax &&
                                            <SC.TravelIconSection>
                                                {getTravelIcon(object, color)}
                                            </SC.TravelIconSection>
                                        }
                                    </SC.TravelTime>
                                )
                            case EDetailType.trafficEvent:
                                return (
                                    <SC.TrafficEvent key={`trafficevent${mapIndex}`}>
                                        <SC.TrafficEventText>{detail.detail}</SC.TrafficEventText>
                                    </SC.TrafficEvent>
                                )
                            case EDetailType.footer:
                                return <SC.Footer key={`footer${mapIndex}`}>{detail.detail}</SC.Footer>
                            case EDetailType.progress:
                                return <EventProgress 
                                    key={`eventprogress${mapIndex}`} color={detail.detail.color || "rgba(196, 18, 46, 1)"} 
                                    start={detail.detail.start} end={detail.detail.end} 
                                />
                        }
                    }
                )
            }
        </SC.StyledApiObjectDetails>
    )
};

export default ApiObjectDetails;

const getTravelIcon = (
    dataObject: any,
    color: string
) => {

    let congestionLevel: ATCongestionLevel = dataObject.congestionLevel;

    switch(ATCongestionLevel[congestionLevel]){
        
        case ATCongestionLevel.Unaffected:
            return (
                <SC.TravelIcon>
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                </SC.TravelIcon>
            );
        case ATCongestionLevel.Minor:
            return (
                <SC.TravelIcon>
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                </SC.TravelIcon>
            );
        case ATCongestionLevel.Moderate:
            return (
                <SC.TravelIcon>
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                </SC.TravelIcon>
            );
        case ATCongestionLevel.Major:
            return (
                <SC.TravelIcon>
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                    <FontAwesomeIcon icon={solid("car-side")} color={color} size={"2x"} />
                </SC.TravelIcon>
            );
        default:
            return (<SC.TravelIcon></SC.TravelIcon>);

    }
}   

const mapDetails = (
    dataType: EAlgoApiObjectType, 
    dataObject: any
): IDetailMap[] => {

    let details: IDetailMap[] = [
        {
            type: EDetailType.header,
            detail: "No Details"
        }
    ];

    switch(EAlgoApiObjectType[dataType]){
        
        case EAlgoApiObjectType["alea-alert"]:
            let aleaAlert: IATAleaAlertDto = new ATAleaAlert(dataObject);

            return [
                {
                    type: EDetailType.detail,
                    detail: <span style={{width: "100%", whiteSpace: "pre-wrap"}}>{aleaAlert.text}</span>
                }
            ];
        case EAlgoApiObjectType["aldot-message"]:
            let aldotMessage: IATAldotMessage = new ATAldotMessage(dataObject);
            let aldotPubDate: Date = new Date(aldotMessage.start);
            let aldotFormatDate: string = "";
            let aldotFormatter: DateFormatter = new DateFormatter(aldotPubDate);
            aldotFormatDate += aldotFormatter.defaultDateString(false);

            return [
                {
                    type: EDetailType.header,
                    detail: aldotMessage.title
                },
                {
                    type: EDetailType.detail,
                    detail: <span style={{width: "100%", whiteSpace: "pre-wrap"}}>{aldotMessage.body}</span>
                },
                {
                    type: EDetailType.footer,
                    detail: `Published ${aldotFormatDate}`
                }
            ];
        case EAlgoApiObjectType["service-assistance-patrol"]:
            let serviceAssistancePatrol: IATServiceAssistancePatrolDto = new ATServiceAssistancePatrol(dataObject);

            return [
                {
                    type: EDetailType.header,
                    detail: serviceAssistancePatrol.title
                },
                {
                    type: EDetailType.detail,
                    detail: serviceAssistancePatrol.subTitle
                }
            ];
        case EAlgoApiObjectType["camera"]:
            let camera: IATCamera = dataObject;

            return [
                {
                    type: EDetailType.header,
                    detail: 
                        `${camera.location.displayRouteDesignator}` + 
                        `${camera.location.displayCrossStreet 
                            ? " @ " + camera.location.displayCrossStreet 
                            : ""
                        }`
                },
                {
                    type: EDetailType.detail,
                    detail: 
                        `MP ${camera.location.linearReference}` + 
                            `${camera.location.city 
                                ? " in  " + camera.location.city 
                                : camera.location.county
                                    ? " in " + camera.location.county + " County"
                                    : ""
                            }`
                }
            ];
        case EAlgoApiObjectType["camera-group"]:
            let group: IATVideoBoardDto = (dataObject as IATVideoBoardDto);
            let pages: IATVideoPageDto[] = group.pages || [];
            let itemCount: number = 0;

            pages.forEach( 
                (page: IATVideoPageDto) => {
                    let items: IATVideoItemDto[] = page.items || []; 
                    itemCount += items.length
                }
            );

            itemCount = itemCount - 1;
            let firstPage: IATVideoPageDto = pages[0];
            let firstItem: IATVideoItemDto | undefined = ( firstPage.items )
                ? firstPage.items[0] 
                : undefined;

            let firstCamera: IATCamera | undefined = firstItem ? firstItem.camera : new ATCamera();

            return [
                {
                    type: EDetailType.header,
                    detail: group.name
                },
                {
                    type: EDetailType.detail,
                    detail: itemCount === 1 ? `Plus ${itemCount} more nearby camera` : 
                            itemCount > 1 ? `Plus ${itemCount} more nearby cameras` : 
                            `MP ${firstCamera.location.linearReference}` + 
                            `${firstCamera.location.city 
                                ? " in  " + firstCamera.location.city 
                                : firstCamera.location.county
                                    ? " in " + firstCamera.location.county + " County"
                                    : ""
                            }`
                }
            ];

        case EAlgoApiObjectType.crash:
        case EAlgoApiObjectType.roadwork:
        case EAlgoApiObjectType.incident:
        case EAlgoApiObjectType["regional-event"]:
        case EAlgoApiObjectType["road-condition"]:

            let event: IATTrafficEvent = dataObject;
            let startLocation: IATLocation = event.startLocation;

            if(event.active){
                return [
                    {
                        type: EDetailType.header,
                        detail: event.title
                    },
                    {
                        type: EDetailType.detail,
                        detail: event.subTitle
                    },
                    {
                        type: EDetailType.progress,
                        detail: {
                            color: getObjectSignColor(dataObject),
                            start: event.start,
                            end: event.end
                        }
                    }
                ];
            }
            else{
                return [
                    {
                        type: EDetailType.header,
                        detail: event.title
                    },
                    {
                        type: EDetailType.detail,
                        detail: 
                            `${startLocation?.displayRouteDesignator}` + 
                            ` @ MP ${startLocation.linearReference}` + 
                            ` at ${startLocation.displayCrossStreet}` + 
                            ` in ${startLocation.county} County`
                    },
                    {
                        type: EDetailType.progress,
                        detail: {
                            color: getObjectSignColor(dataObject),
                            start: event.start,
                            end: event.end
                        }
                    },
                    {
                        type: EDetailType.trafficEvent,
                        detail: 'This event has ended or been replaced by another event'
                    }
                ];
            }

        case EAlgoApiObjectType.ferry:

            let ferry: IATFerryLandingDto = dataObject;

            return [
                {
                    type: EDetailType.header,
                    detail: "Ferry"
                },
                {
                    type: EDetailType.detail,
                    detail: ferry.name
                },
            ];

        case EAlgoApiObjectType["message-sign"]:

            let sign: IATMessageSign = new ATMessageSign(dataObject);
            let signLocation: IATLocation = sign.location;

            return [
                {
                    type: EDetailType.header,
                    detail: signLocation.displayRouteDesignator
                },
                {
                    type: EDetailType.detail,
                    detail: `Updated by ALDOT`
                }
            ];

        case EAlgoApiObjectType["state-facility"]:

            let facility: IATFacilityDto = new ATFacility(dataObject);
            let detail: string = "";
            if(facility.locations && facility.locations.length > 0) {
                let count: number = 0;
                facility.locations.forEach( facilityLocation => {
                    detail += `${facilityLocation.displayRouteDesignator}` +
                    ` at MP ` +
                    `${facilityLocation.linearReference}`

                    count++;

                    if(count !== facility?.locations?.length){
                        detail += ', ';
                    }
                })
            }

            return [
                {
                    type: EDetailType.header,
                    detail: facility.name
                },
                {
                    type: EDetailType.detail,
                    detail: detail
                }
            ];

        case EAlgoApiObjectType["location"]:

            let location: any = dataObject ? dataObject : new Object();

            return [
                {
                    type: EDetailType.header,
                    detail: location.label
                }
            ];

        case EAlgoApiObjectType.other511:

            return [
                {
                    type: EDetailType.header,
                    detail: dataObject.systemName
                },
                {
                    type: EDetailType.detail,
                    detail: `State of ${dataObject.state}`
                }
            ];

        case EAlgoApiObjectType["weather-alert"]:
            let alert: ICWCollatedAlertDto = ( dataObject.properties )
                ? dataObject.properties // if we are dealing with a feature object, use the properties property value
                : dataObject;   // otherwise this should be a stand-alone alert object

            return [
                {
                    type: EDetailType.header,
                    detail: alert.name
                }
            ];

        case EAlgoApiObjectType["travel-time"]:
            let travelTime: IATTravelTimeDto = dataObject;

            const now = new Date().getTime();
            const lastUpdated = travelTime.lastUpdated ? new Date(travelTime.lastUpdated).getTime() : new Date().getTime();
            const diffMs = (now - lastUpdated); // milliseconds
            const diffDays = Math.floor(diffMs / 86400000); // days
            const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            const lessThanMinute = diffDays <= 0 && diffHrs <= 0 && diffMins <= 0 && diffMs > 0;

            const time = 
                diffDays > 0 ? diffDays === 1 ? `${diffDays} day ago` : `${diffDays} days ago` :
                diffHrs > 0 ? diffHrs === 1 ? `${diffHrs} hour ago` : `${diffHrs} hours ago` :
                diffMins > 0 ? diffMins === 1 ? `${diffMins} minute ago` : `${diffMins} minutes ago` :
                lessThanMinute ? `less than a minute ago` :
                ``;

            return [
                {
                    type: EDetailType.header,
                    detail: travelTime.name
                },
                {
                    type: EDetailType.travelTime,
                    detail: `Arrive in ${displayMinutesInHours(travelTime.estimatedTimeMinutes)}`,
                    travelDetail: `Average speed is ${travelTime.averageSpeedMph}`
                },
                {
                    type: EDetailType.footer,
                    detail: `Last Updated ${time}`
                }
            ];

        default:
            return details;
    }
};

const mapEventTypeToColor = (type: EATEventType) => {
    return signColors[type];
};

const getObjectSignColor = (object: any) => {

    let event: IATTrafficEvent = object;

    if ( event.severity === EATSeverity.Closed ){
        return "#"+event.signStyle.backgroundColor.hex;
    }
    else {
        return mapEventTypeToColor(object.type);
    }
}

// takes a given minutes number and returns a string of format:
// x hour(s), x minute(s)
export const displayMinutesInHours = (minutes: number) => {

    let h = Math.floor(minutes / 60);
    let m = minutes % 60;

    let retStr: string = ``;

    if (h >= 1){
        retStr += `${h} hour${h > 1 ? "s" : ""}`;
        if (m > 0) retStr += ", ";
    }
    if (m > 0)
        retStr += `${m} minute${m > 1 ? "s" : ""}`;

    return retStr;
};