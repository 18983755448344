// libraries
import { 
    commonFilterBySearch, createAlgoSlice 
} from "./common";
// types & models
import { IProcessedResponse, IServiceAssistancePatrolsNetworkManager, IStoreProcessedResponse, ServiceAssistancePatrolsNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, IFeature } from "~/interfaces";
// constants
import { CUR_API_ENDPOINTS, API_V4 } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "../test-data/asaps/service-assistance-patrols.json";
import { buildLastResponse } from "~/store/library";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).serviceAssistancePatrols}`;

// create list slice
export const ServiceAssistancePatrolSlice = 
    createAlgoSlice(EAlgoApiObjectType["service-assistance-patrol"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit
} = ServiceAssistancePatrolSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getByRegion = (region: string, mode?: string, test: boolean = isTesting, ) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getByRegion_test(region, mode));
        else 
            dispatch(getByRegion_api(region, mode));
    }
}

export const getByRegion_api = (region: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: IServiceAssistancePatrolsNetworkManager = 
            new ServiceAssistancePatrolsNetworkManager(apiUrl);

        manager.getByRegion(buildLastResponse(getState()), {region: region, includeGeometry: true}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        let reduxObject: IStoreProcessedResponse = response.getReduxObject();

                        let features: IFeature[] = response.data.features;
                        let data: any[] = [];

                        features.forEach( (feature: IFeature) => {
                            data.push(
                                {...feature.properties.data, 
                                    coord: {
                                        lat: feature.geometry.coordinates[1], 
                                        lng: feature.geometry.coordinates[0]
                                    },
                                    id: feature.properties.data.pages[0]?.items[0]?.camera?.id
                                }
                            ); 
                        });
                        
                        dispatch(successFunction({...reduxObject, data}))
                    }
                }
            ).catch(
                (error: Error) => {
                    dispatch(failureFunction({ errorMessage: error.message }))
                }
            )

    }
    
};

// retrieves test data for this data type
export const getByRegion_test = (region: string, mode?: string) => {
    alert("ServiceAssistancePatrol: getByRegion_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (
    mode?: string,
    test: boolean = isTesting, 
): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.county].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: IServiceAssistancePatrolsNetworkManager = 
            new ServiceAssistancePatrolsNetworkManager(apiUrl);

        manager.getAll(buildLastResponse(getState()), {includeGeometry: true}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        let reduxObject: IStoreProcessedResponse = response.getReduxObject();
        
                        let features: IFeature[] = response.data.features;
                        let data: any[] = [];

                        features.forEach( (feature: IFeature) => {
                            data.push(
                                {...feature.properties, 
                                    coord: {
                                        lat: feature.geometry.coordinates[1], 
                                        lng: feature.geometry.coordinates[0]
                                    }
                                }
                            ); 
                        });
                        
                        dispatch(successFunction({...reduxObject, data}))
                    }
                }
            ).catch(
                (error: Error) => {
                    dispatch(failureFunction({ errorMessage: error.message }))
                }
            )

    }
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        setTimeout(
            () => {
                dispatch(success({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(ServiceAssistancePatrolSlice, searchTerm, searchMatch);
    
};

const searchMatch = (feature: any, searchTerm: string = ""): boolean => {

    let subTitle: string = feature.subTitle;
    
    if (!searchTerm) return true;

    if (
        subTitle?.toLowerCase().includes(searchTerm.toLowerCase())
    ) return true;

    return false;
}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: any
): {lat: number, lng: number} | null => {

    let lat: number = item.coord.lat;
    let lng: number = item.coord.lng;

    if (lat && lng) 
        return {lat, lng}

    else 
        return null;

};

function formatFloatString(input: string): number {
    // Convert string to number
    const num = parseFloat(input);
  
    // Check if the conversion was successful
    if (isNaN(num)) {
      throw new Error("Input is not a valid number");
    }
  
    // Format the number to 6 decimal places and convert back to number
    const formattedNum = parseFloat(num.toFixed(6));
  
    return formattedNum;
  }

// exports the slice's reducer ( used in store file to build up master reducer )
export const ServiceAssistancePatrolReducer = ServiceAssistancePatrolSlice.reducer;