// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// components
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
import SearchBar from "~/components/views/search-bar-button/SearchBarButton";

export type IProps = {
    activeTickerCount?: number;
};

export const SearchBarSection: React.FC<IProps> = (props) => {

    const { activeTickerCount = 0 } = props;

    const topOffset: number = 20 + (20 * activeTickerCount);

    const { sm: isSmallMax } = useBreakpoint();

    return (
        /* Overlay Views */
        <OverlaySection 
            top={topOffset} right={isSmallMax ? 0 : 42}
            styleOverwrite={`height: 39px; pointer-events: none; ${isSmallMax ? '' : '200px;'}`}
            childWrapperStyleOverwrite={`${isSmallMax ? '' : 'align-items: flex-end'};`}
        >
            <SearchBar />

        </OverlaySection>
    );
};

export default SearchBarSection;