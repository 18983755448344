// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// component
import Logo from "~/components/views/logo/Logo";
import { useNavigate } from "react-router-dom";

export type IProps = {
    //
};

export const NotFoundPage: React.FC<IProps> = (props) => {

    const navigate = useNavigate();

    return (
        <SC.StyledNotFound>

            <SC.ErrorCodeText>{"404"}</SC.ErrorCodeText>

            <SC.NotFoundText>
                {"Page Not Found"}
            </SC.NotFoundText>

            <SC.LogoWrap>
                <Logo logoWidth={128} logoHeight={128} textWidth={128} textHeight={128} />
            </SC.LogoWrap>

            <SC.NoticeText>
                {"We were unable to find the page you were looking for"}
            </SC.NoticeText>

            <SC.ReturnLinkText
                onClick={() => navigate("/")}
            >
                {"Return to ALGO Traffic"}
            </SC.ReturnLinkText>

        </SC.StyledNotFound>
    );
};

export default NotFoundPage;