// libraries
import styled from "styled-components";
import {flexSmart} from "@caps-mobile/styled-lib";

export const StyledMapPage = styled.div`
    height: 100%;
    width: 100%;

    position: relative;

    ${flexSmart("center end")}

    background-color: ${props => props.theme.colors["bg-secondary"].rgba};

`;

export const MapWrapper = styled.div<{activeTickerCount: number}>`
    height: calc(100% - ${props => props.activeTickerCount} * 21px);
    width: 100%;

    position: relative;
`;