// libraries
import styled from "styled-components";

export const StyledNavSection = styled.div`

`;

export const SectionLinks = styled.div<{panelOpen?: boolean}>`

    padding-top: 10px;
    box-sizing: border-box;

    ${props => props.panelOpen 
        ? `
        `
        : `
            padding-top: 0;
        `
    }
`;

export const NavLink = styled.a<{index?: number, isSelected?: boolean, panelOpen?: boolean}>`

    display: flex;
    
    align-items: center;

    ${props => props.panelOpen ? `justify-content: flex-start;` : `justify-content: center;`}

    padding: 10px 10px 10px 10px;
    box-sizing: border-box;

    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;

    color: ${props => props.theme.colors["text-primary"].rgba};

    ${props => props.isSelected 
        ? `
            background-color: ${props.theme.colors["primary"].rgba};
            color: ${props.theme.colors["text-secondary"].rgba};
        ` 
        : ``
    }

    :hover {
        cursor: pointer;
    }

    & > :not(:only-child){
        margin-right: 8px;
    }
`;

export const LinkIcon = styled.div<{isSelected?: boolean}>`
    color: ${props => props.theme.colors["primary"].rgba};

    font-size: 20px;

    ${props => props.isSelected 
        ? `
            color: ${props.theme.colors["text-secondary"].rgba};
        ` 
        : ``
    }
`;

export const LinkName = styled.div<{panelOpen?: boolean}>`
    
`;