// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const RoadworkGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "144"} height={height || "164"} 
            viewBox={ viewBox || "0 0 144 164" } fill="none" 
        >
            <path fill={color || "black"}
                d="M48.5277 0C57.4314 0 64.6957 7.26432 64.6957 16.172C64.6957 25.0797 57.4314 32.3478 48.5277 32.3478C39.6178 32.3478 32.3478 25.0797 32.3478 16.172C32.3478 7.26432 39.6178 0 48.5277 0ZM20.3186 163.711H8.7097L11.162 85.9344L13.8412 79.2565L0 65.7927C11.4266 54.3002 22.8461 42.8044 34.266 31.3043C40.0516 36.4174 46.208 37.5392 52.5778 36.4193L60.8411 112.42C72.4835 122.206 84.1274 131.998 95.7794 141.793C87.297 126.267 91.1389 109.411 105.936 113.865L144 125.326L105.263 163.826H64.2935L79.4802 134.338L40.856 102.069L47.665 121.433L46.4343 163.711H37.1715L35.6121 127.996L25.9023 110.522L20.3186 163.711ZM44.8155 74.087L50.087 103.304L39.6522 93.715L44.8155 74.087ZM13.5652 64.2631L22.9565 55.3043L17.9222 67.8261L13.5652 64.2631Z" />
        </svg>
    );
};

export default RoadworkGlyph;