// libraries
import { 
    commonGetAll_api, commonGetById_api, 
    commonFilterBySearch, createAlgoSlice 
} from "./common";
// types & models
import { CitiesNetworkManager, IProcessedResponse } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "../test-data/cities/all-cities-11-10-22.json";
import { buildLastResponse } from "~/store/library";
import { IATCityDto } from "@algo/network-manager/models/v3";
import { nuonoe } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).cities}`;

// create list slice
export const citySlice = 
    createAlgoSlice(EAlgoApiObjectType.city);

// get handles for the slice's actions
const { 
    begin, success, failure, filterDataBySearch
} = citySlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new CitiesNetworkManager(apiUrl), 
        id, 
        citySlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    alert("city: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (
    params?: any,
    test: boolean = isTesting, 
    testMode?: string
): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (test)
            dispatch(getAll_test(testMode));
        else 
            dispatch(getAll_api(params));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (params: any) => {

    return commonGetAll_api(
        new CitiesNetworkManager(apiUrl), 
        citySlice,
        params
    );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        setTimeout(
            () => {
                dispatch(success({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(citySlice, searchTerm, searchMatch);
    
};

const searchMatch = (
    city: IATCityDto, 
    searchTerm: string = "",
): boolean => {

    if (!searchTerm) return true;

    if (
        city.name?.toLowerCase().includes(searchTerm.toLowerCase())
    )   return true;

    return false;
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const cityReducer = citySlice.reducer;