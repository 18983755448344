import { IProcessedResponse, ProcessedResponse } from "@algo/network-manager/managers/v3";

export const buildLastResponse = ( state: any ): IProcessedResponse => {

    return new ProcessedResponse(
        {
            data: state.lastData,
            errorMessage: state.lastErrorMessage,
            status: state.lastStatus,
            xChecksum: state.lastChecksum,
            xCount: state.lastCount
        }
    )
};