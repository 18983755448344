// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BOX_SHADOW } from "~/constants";

export const StyledIconToggleList = styled.div<{isMediumMax?: boolean, isSmallMax?: boolean, isXXSmallMax?: boolean}>`
    width: ${props => props.isXXSmallMax ? '90vw' : props.isSmallMax ? '95vw' : props.isMediumMax ? '100%' : '585px'};
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

export const Header = styled.div`
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 30px;
    box-sizing: border-box;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Close = styled.div`
    box-sizing: border-box;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 700;
    font-size: 17px;
    line-height: 147%;

    :hover{
        cursor: pointer;
    }
`;

export const ToggleOptionList = styled.div`
    width: 100%;

    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(auto-fill, 90px);
    justify-content: space-around;
    grid-gap: 20px;

    margin-bottom: 20px;
`;

export const ToggleOption = styled.div`
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    :hover{
        cursor: pointer;
    }
`;

export const ToggleOptionIconWrapper = styled.div<{isActive?: boolean, bgColor?: string}>`
    
    height: 54px;
    width: 54px;

    ${flexSmart("center center")}

    border-radius: 50%;

    background-color: ${props => props.isActive ? props.bgColor : "transparent"};

    box-shadow: ${BOX_SHADOW}
`;

export const ToggleOptionLabel = styled.div`
    font-size: 16px;
    font-weight: 400;

    white-space: wrap;

    text-transform: capitalize;
    text-align: center;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;