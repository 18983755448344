// libraries
import { 
    createAlgoSlice, commonGetCameras_test, commonGetCameras_api, commonGetById_api 
} from "./common";
import { getEnumStrings, nuon } from "@caps-mobile/common-lib";
// types & models
import { ATLocation, EATEventType, IATLocationDto, IATTrafficEvent, IATTrafficEventDto } from "@algo/network-manager/models/v3";
import { EAlgoApiObjectType } from "~/interfaces";
import { IProcessedResponse, TrafficEventNetworkManager } from "@algo/network-manager/managers/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
import { createSlice } from "@reduxjs/toolkit";
import { buildLastResponse } from "~/store/library";
declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).trafficEvents}`;

// logic common to all traffic event types

export type ITrafficEventsInit = {
    [key: string]: { event: IATTrafficEventDto, loading: boolean };
};

export const TrafficEventsInit: ITrafficEventsInit = {
    
};

// create list slice
export const trafficEventSlice = createSlice(
    {
        name: "traffic-events",
        initialState: TrafficEventsInit,
        reducers: {
            begin: (state: any, action: any) => {
                state[action.payload.id] = { ...state[action.payload.id], loading: true };
            },
            success: (state: any, action: any) => {
                state[action.payload.id] = { 
                    ...state[action.payload.id],
                    event: action.payload.data,
                    loading: false 
                };
            },
            failure: (state: any, action: any) => {
                state[action.payload.id] = { 
                    ...state[action.payload.id], 
                    event: null,
                    loading: false 
                };
            }
        }
    }
);

export const trafficEventReducer = trafficEventSlice.reducer;

// get handles for the slice's actions
const { 
    begin, success, failure
} = trafficEventSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: string, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let manager = new TrafficEventNetworkManager(apiUrl);
    
        manager.getById(buildLastResponse({}), {id}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message} ))
                    else {
                        dispatch(success( { id: id, data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getById_test = (id: string, mode?: string) => {
    
    alert("traffic-event: getById_test is not yet implemented.")
};

export const getCameras = (id: number, mode?: string, test: boolean = isTesting) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()["traffic-event"].camerasLoading) return;

        if (test)
            dispatch(commonGetCameras_test(trafficEventSlice, {id}));
        else 
            dispatch(commonGetCameras_api(
                new TrafficEventNetworkManager(apiUrl),
                trafficEventSlice,
                { id }
            ));
    }
    
};

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of object for geo-filtering
export const getCoords = (
    item: IATTrafficEvent
): {lat: number, lng: number} | null => {

    let location: IATLocationDto = item.startLocation;
    let lat: number = location.latitude;
    let lng: number = location.longitude;

    if (nuon(lat) && nuon(lng)) 
        return {lat, lng}

    else 
        return null;
};

// used to compare given type with the type of given trafficEvent
export const typeMatch = (trafficEvent: IATTrafficEventDto, type: string): boolean => {

    // short-circuit
    if (!type) return true;

    if (trafficEvent.type === type.replace(/\s/g, ""))   return true;

    return false;
};

// used to compare given severity with the severity of given trafficEvent
export const severityMatch = (trafficEvent: IATTrafficEventDto, severity: string): boolean => {

    // short-circuit
    if (!severity) return true;

    if (trafficEvent.severity === severity.replace(/\s/g, ""))   return true;

    return false;
};

// used to compare given city name with city values in origin and destination of given trafficEvent
export const cityMatch = (trafficEvent: IATTrafficEventDto, cityName: string) => {

    // short-circuit
    if (!cityName) return true;

    let startLocation: IATLocationDto = trafficEvent.startLocation || new ATLocation();
    let endLocation: IATLocationDto = trafficEvent.endLocation || new ATLocation();

    if (
        cityName === startLocation.city ||
        cityName === endLocation.city
    )   return true;

    return false;
};

// used to compare given county name with county values in origin and destination of given trafficEvent
export const countyMatch = (trafficEvent: IATTrafficEventDto, countyName: string) => {

    // short-circuit
    if (!countyName) return true;

    let startLocation: IATLocationDto = trafficEvent.startLocation || new ATLocation();
    let endLocation: IATLocationDto = trafficEvent.endLocation || new ATLocation();

    if (
        countyName === startLocation.county ||
        countyName === endLocation.county
    )   return true;

    return false;
};

// used to compare given region with the region of given trafficEvent
export const regionMatch = (trafficEvent: IATTrafficEventDto, region: string): boolean => {

    // short-circuit
    if (!region) return true;

    if (trafficEvent.responsibleRegion === region.replace(/\s/g, ""))   return true;

    return false;
};

export const searchMatch = (
    trafficEvent: IATTrafficEventDto, 
    searchTerm: string = "",
): boolean => {

    if (!searchTerm) return true;

    let startLocation: IATLocationDto = trafficEvent.startLocation || new ATLocation();

    if (
        (startLocation) && 
            ( 
                trafficEvent.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                startLocation.displayRouteDesignator?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                startLocation.displayCrossStreet?.toLowerCase().includes(searchTerm.toLowerCase())
            )
    ) return true;

    return false;
};

export const checkForStringMatch = (
    trafficReport: IATTrafficEventDto, 
    stringList: string[], 
    matchFunction: (object: any, str: string
) => boolean) => {

    // first, we check the strings for a match
    // first, assume the strings do not match
    let stringsMatch: boolean = false;
    // if there are no cities selected, then consider it a match
    if (stringList.length === 0) stringsMatch = true;
    else {  // if you have cities selected
        // then check if ANY of those selected cities are a match
        for (let i = 0; i < stringList.length; i++){
            if (matchFunction(trafficReport, stringList[i])) {
                // if the city was a match, set the boolean and break the loop
                stringsMatch = true;
                break;
            }
        }
    }

    return stringsMatch;
};

export const filterBySelections = (
    sliceName: EAlgoApiObjectType,
    actionCreator: any,
    reportTypes: string[],
    severities: string[],
    cityNames: string[],
    countyNames: string[],
    regionNames: string[],
    searchText: string
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let data: any[] = getState()[EAlgoApiObjectType[sliceName]].data;

        if (!data) return;

        if (data.length === 0) {
            dispatch(actionCreator({dataFilteredBySelection: data}));
            return;
        }

        let filteredData: any[] = [];

        // if there is data...
        if (data.length > 0){
            // iterate over each travel time...
            for (let i = 0; i < data.length; i++){
                
                let trafficReport = data[i];

                let eventTypeMatch = checkForStringMatch(trafficReport, reportTypes, typeMatch);
                if (!eventTypeMatch) continue;

                let severityTypeMatch = checkForStringMatch(trafficReport, severities, severityMatch);
                if (!severityTypeMatch) continue;

                let citiesMatch = checkForStringMatch(trafficReport, cityNames, cityMatch);
                if (!citiesMatch) continue;

                let countiesMatch = checkForStringMatch(trafficReport, countyNames, countyMatch);
                if (!countiesMatch) continue;

                let regionsMatch = checkForStringMatch(trafficReport, regionNames, regionMatch);
                if (!regionsMatch) continue;

                // last, we check that search also matches
                // if all matches are true, add this travelTime to the filteredList
                if (
                    eventTypeMatch &&
                    severityTypeMatch &&
                    citiesMatch &&
                    countiesMatch &&
                    regionsMatch &&
                    searchMatch(trafficReport, searchText)
                ){
                    filteredData.push(trafficReport);
                }
            }
        }

        dispatch(actionCreator({dataFilteredBySelections: filteredData}));
    }
}

export const isTrafficEvent = (type: EAlgoApiObjectType): boolean => {

    let eventTypes: string[] = getEnumStrings(EATEventType);

    for (let i = 0; i < eventTypes.length; i++){
        if (eventTypes[i].toLowerCase().replaceAll("-", "") === type.toLowerCase().replaceAll("-", "")){
            return true;
        }
    }

    return false;
};