// libraries
import * as React from "react";
import { extractIntsFromStr, nuon } from "@caps-mobile/common-lib";
// hooks & context
import { useBreakpoint } from "~/library/useBreakpoint";
// types & models
import { ICWAlertDto, ICWCollatedAlertDto } from "@algo/network-manager/models/v3/cw";
import { EAlgoApiObjectType } from "~/interfaces";
import { 
    EATFacilityType, EATPlaceType, EATSeverity, IATAleaAlertDto, IATAleaAlertImageDto, 
    IATFacilityDto, IATMessageSignDto, IATMessageSignPageDto, 
    IATTrafficEventDto, IATTravelerInformationSystemDto, IATTravelTimeDto 
} from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// resource
import { 
    cameraPin, ferryPin, restAreaPin, signPin, signOffPin, welcomeCenterPin, restAreaClosedPin, welcomeCenterClosedPin, asapPin
} from "~/resources/icons/algo-traffic-icons/map-pin";
import Crash from "~/resources/icons/algo-traffic-icons/event-types/Crash";
import RoadWork from "~/resources/icons/algo-traffic-icons/event-types/RoadWork";
import RegionalEvent from "~/resources/icons/algo-traffic-icons/event-types/RegionalEvent";
import Incident from "~/resources/icons/algo-traffic-icons/event-types/Incident";
import WeatherAlert from "~/resources/icons/algo-traffic-icons/weather/WeatherAlert";
import RoadClosed from "~/resources/icons/algo-traffic-icons/lanes/RoadClosed";
import { RoadShield } from "@caps-mobile/traffic-graphics";
import noImage from "~/resources/ui/graphics/no-image-available.png";
import RoadCondition from "~/resources/icons/algo-traffic-icons/event-types/RoadCondition";
import ImgWithDefault from "../../image-with-default/ImgWithDefault";

export type IProps = {
    object: any;
    type: EAlgoApiObjectType;
};

export const ApiObjectIcon: React.FC<IProps> = (props) => {

    const { object, type } = props;
    const breakpoints = useBreakpoint();

    let icon: React.ReactNode | null = null;

    switch(type){
        case EAlgoApiObjectType["other511"]:
            icon = mapStateIcon(object, breakpoints);
            break;
        case EAlgoApiObjectType["state-facility"]:
            icon = mapFacilityIcon(object, breakpoints);
            break;
        case EAlgoApiObjectType["alea-alert"]:
            let alert: IATAleaAlertDto = object;
            let images: IATAleaAlertImageDto[] | undefined = alert.images;
            icon = (images && images.length > 0) 
                ? <ImgWithDefault width={"128px"} height={"128px"} src={images[0].url} fallbackSrc={noImage} />
                : null;
            break;
        default: 
            icon = mapTypeToIcon(type, breakpoints, object);
    }

    if (!icon) return null;

    return (
        <SC.StyledApiObjectIcon>
            {icon}
        </SC.StyledApiObjectIcon>
    )
};

const mapStateIcon = (object: IATTravelerInformationSystemDto, breakpoints: any) => {

    let icons: any[] = object.markerIcons || [];
    let iconUrl: string = (icons as any).png || "";
    let isSmallMax: boolean = breakpoints.sm;

    if (!iconUrl) return null;

    return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} src={iconUrl} />
}

const mapFacilityIcon = (object: IATFacilityDto, breakpoints: any) => {

    let isSmallMax: boolean = breakpoints.sm;

    switch(object.type){
        case EATFacilityType.RestArea:
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} 
                        src={ object.open ? restAreaPin : restAreaClosedPin} />
        case EATFacilityType.WelcomeCenter:
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} 
                        src={ object.open ? welcomeCenterPin : welcomeCenterClosedPin } />
        default: 
            return null;
    };
}

const mapTypeToIcon = (
    dataType: EAlgoApiObjectType,
    breakpoints: any,
    object?: any,
): React.ReactNode => {

    let isSmallMax: boolean = breakpoints.sm;

    let event: IATTrafficEventDto = object;
    let bgColor: string = "#"+event?.signStyle?.backgroundColor.hex;
    let textColor: string = "#"+event?.signStyle?.glyphColor.hex;

    if (event.severity === EATSeverity.Closed){
        return <RoadClosed bgColor={bgColor} textColor={textColor} width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
    }

    switch(EAlgoApiObjectType[dataType]){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} src={cameraPin} />
        case EAlgoApiObjectType.crash:
            return <Crash width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
        case EAlgoApiObjectType.ferry:
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} src={ferryPin} />
        case EAlgoApiObjectType["service-assistance-patrol"]:
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} src={asapPin} />
        case EAlgoApiObjectType.incident:
            return <Incident width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
        case EAlgoApiObjectType["message-sign"]:
            let pages: IATMessageSignPageDto[] = (object as IATMessageSignDto).pages || [];
            let signPinSrc: string = (pages && pages.length > 0) ? signPin : signOffPin;
            return <img width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} src={signPinSrc} />
        case EAlgoApiObjectType["regional-event"]:
            return <RegionalEvent width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
        case EAlgoApiObjectType["road-condition"]:
            return <RoadCondition width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
        case EAlgoApiObjectType.roadwork:
            return <RoadWork width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} />
        case EAlgoApiObjectType["travel-time"]:
            let travelTime: IATTravelTimeDto = object;
            let shieldText: string = "??";
            let shieldTextArray: string[] = [shieldText];

            let shieldType: EATPlaceType = EATPlaceType.Unknown;

            // it is possible that origin is null, if so skip this section
            if (nuon(travelTime.origin)){

                // pull out only integers from the place name string
                let shieldInts: number[] = 
                    extractIntsFromStr(travelTime.origin!.place?.name || "");
                
                // store the parsed integers as strings
                shieldTextArray = 
                    shieldInts.map((shieldInt: number) => `${shieldInt}`);

                // override unknown type with type found in origin
                shieldType = travelTime!.origin!.place?.type || EATPlaceType.Unknown;
            }

            return shieldTextArray.map((shieldText: string, index: number) => {
                return( 
                    <RoadShield 
                        key={shieldText + `${index}`}
                        text={shieldText} type={shieldType}
                    />
                );}
            );
            
        case EAlgoApiObjectType["weather-alert"]:
            let alert: ICWAlertDto | null = null;
            let alertType: ICWCollatedAlertDto | null = null;
            let collatedAlert: ICWCollatedAlertDto | null = null;

            if (object.properties && object.properties?.type !== "alert") collatedAlert = object.properties;
            else if(object.properties) alertType = object.properties;
            else alert = object;

            let alertFillColor: string = collatedAlert || alertType
                ? collatedAlert ? `#${collatedAlert.category?.fillColor?.hex}` : `#${alertType!.category?.fillColor?.hex}`
                : `#${alert!.fillColor?.hex}`;

            return <WeatherAlert width={isSmallMax ? 64 : 80} height={isSmallMax ? 64 : 80} color={alert || alertType ? `${alertFillColor}` : "rgba(34, 167, 240, 1)"} />
        default: 
            return null;
    }
}

export default ApiObjectIcon;