// libraries
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNearbyCameras } from "~/store/algo-api/slices/nearby-cameras";

export const useNearbyCameras = (object: any, typeString: string) => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            if (object && typeString !== "camera") {
                dispatch(getNearbyCameras(object, typeString) as any);
            }
        }, []
    );

};