// libraries
import { 
    commonFilterBySearch, commonGetAll_api, commonInit_api, 
    commonGetById_api, createAlgoSlice, commonGetCameras_test, commonGetCameras_api 
} from "./common";
import { filterBySelections as trafficEventFilterBySelections, searchMatch } from "./traffic-events";
// types & models
import { TrafficEventNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
// test data
import T_DATA from "~/store/algo-api/test-data/incidents/all-incidents-10-3-22.json";
import { EATEventType, IATLocationDto, IATTrafficEvent } from "@algo/network-manager/models/v3";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).trafficEvents}`;

// create list slice
export const incidentSlice = 
    createAlgoSlice(EAlgoApiObjectType.incident);

// get handles for the slice's actions
const { 
    begin, success, failure, 
    beginInit, successInit, failureInit,
    filterDataBySelections
} = incidentSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new TrafficEventNetworkManager(apiUrl), 
        { id: id, type: EATEventType.Incident}, 
        incidentSlice
    );
    
};

// export const getCameras = (id: number, mode?: string, test: boolean = isTesting): any => {

//     return (
//         dispatch: any,
//         getState: any
//     ) => {

//         if (getState()[EAlgoApiObjectType.incident].camerasLoading) return;

//         if (test)
//             dispatch(commonGetCameras_test(incidentSlice, {id}));
//         else 
//             dispatch(commonGetCameras_api(
//                 new TrafficEventNetworkManager(apiUrl),
//                 incidentSlice,
//                 { id }
//             ));
//     }
// }

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("incident: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.incident].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    if (mode === "init")
        return commonInit_api(
            new TrafficEventNetworkManager(apiUrl), 
            incidentSlice,
            { type: EATEventType.Incident }
        )
    else 
        return commonGetAll_api(
            new TrafficEventNetworkManager(apiUrl), 
            incidentSlice,
            { type: EATEventType.Incident }
        );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(incidentSlice, searchTerm, searchMatch);
    
};

export const filterBySelections = (
    reportTypes: string[],
    severities: string[],
    cityNames: string[],
    countyNames: string[],
    regionNames: string[],
    searchText: string
) => {

    return trafficEventFilterBySelections(
        EAlgoApiObjectType.incident,
        filterDataBySelections,
        reportTypes,
        severities,
        cityNames,
        countyNames,
        regionNames,
        searchText
    );
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const incidentReducer = incidentSlice.reducer;