// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
// constants
import { getTravelColor, ThemeContext } from "~/theme";
import { ATCongestionLevel } from "~/interfaces";

export type IProps = {
    modal?: boolean;
};

export const TravelTimesLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();
    const theme: any = React.useContext(ThemeContext);


    return (
        <SC.Legend>

            <SC.TrafficLegendItems isMediumMax={isMediumMax}>
                <SC.LegendText left={true}>No Delay</SC.LegendText>
                <SC.Colors>
                    <SC.ColorBox fillColor={getTravelColor({congestionLevel: ATCongestionLevel.Unaffected}, theme.mode)} />
                    <SC.ColorBox fillColor={getTravelColor({congestionLevel: ATCongestionLevel.Minor}, theme.mode)} />
                    <SC.ColorBox fillColor={getTravelColor({congestionLevel: ATCongestionLevel.Moderate}, theme.mode)} />
                    <SC.ColorBox fillColor={getTravelColor({congestionLevel: ATCongestionLevel.Major}, theme.mode)} />
                </SC.Colors>
                <SC.LegendText left={false}>Major Delay</SC.LegendText>
            </SC.TrafficLegendItems>

        </SC.Legend>
    );
};

export default TravelTimesLegend;