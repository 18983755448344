// libraries
import styled from "styled-components";

export const Header = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;

    padding-bottom: 20px;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const RequestAccessText = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
`;

export const RequestAccessLink = styled.span`
    color: ${props => props.theme.colors["primary"]["rgba"]};

    :hover {
        cursor: pointer;
    }
`;