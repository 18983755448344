/*
* <Author> Caps-Mobile-Team </Author>
* <Created> 6-24-2021 </Created>
* 
* <Description> Add a description of the component here. </Description>
* 
*/

 //libraries
 import * as React from 'react';
 import styled from "styled-components";
 
 // enums
 export enum EScrollOrientation {
     "horizontal",
     "vertical"
 };
 
 // interfaces
 export type IProps = {
     orientation?: EScrollOrientation;   // scrolling up or down
     intervalDelay?: number;             // delay between each step of the interval process
     increment?: number;                 // literal constant by which scrollHeight is incremented each interval
     incrementPercent?: number;          // causes an increment of scroll by percent of total scrollHeight rather than a constant
     initialDelay?: number;              // how long before scrolling begins
     finalDelay?: number;                // how long after scrolling finishes before repeating    
     cycleDuration?: number;             // describes the total duration of scroll action (including initial and final delays)
     styleOverwrite?: string;
     scrollSpeed?: number;
 } & React.AllHTMLAttributes<HTMLElement>;
 
 export const AutoScroller: React.FC<IProps> = (props) => {
         
     /*********************************************
         PROPS
      ********************************************/
     const {
        id, className, style, styleOverwrite,
        initialDelay = 0, finalDelay = 0,
        cycleDuration
     } = {...props};
 
     /*********************************************
         REFS
      ********************************************/
     const wrapperRef = React.useRef<HTMLDivElement>(null);
 
     /*********************************************
         EFFECTS
      ********************************************/
     // manages the timing of displaying children
     React.useEffect(
         () => { 
 
            let nextIntervalTimeoutId: NodeJS.Timeout | null = null;

            if (wrapperRef.current) {
            
                let wrapper = wrapperRef.current;

                const scrollDuration: number | null = (cycleDuration) 
                    ? cycleDuration - (initialDelay + finalDelay)
                    : null;

                // get total width of scrollable view
                const scrollMax = wrapper.scrollWidth - wrapper.clientWidth;
                // if the scrollMax value is 0, there is no scrolling to be done. Abort logic.
                if (scrollMax === 0) return;

                const scrollSpeed = props.scrollSpeed || 100;
                const scrollIncrement = (scrollDuration)
                    ? Math.ceil((scrollMax ? scrollMax : 1) / (scrollDuration / scrollSpeed))
                    : 1;

                const nextInterval = (delay: number, scroll: boolean) => {
                    nextIntervalTimeoutId = setTimeout(
                        () => {

                            if (wrapper.scrollLeft === 0 && !scroll){
                                nextInterval(initialDelay, true);
                            }
                            else if (wrapper.scrollLeft === scrollMax){
                                scrollAction(wrapper, scrollMax, scrollIncrement);
                                nextInterval(scrollSpeed || 1000, false);
                            }
                            else if ((wrapper.scrollLeft + scrollIncrement) >= scrollMax){
                                wrapper.scrollLeft = scrollMax;
                                nextInterval(finalDelay, true);
                            }
                            else {
                                scrollAction(wrapper, scrollMax, scrollIncrement);
                                nextInterval((scrollSpeed || 1000), true);
                            }

                        }, (delay)
                    )
                }

                nextInterval((scrollSpeed || 1000), false);

            }
 
            // react cleanup
            return () => {
                if (nextIntervalTimeoutId)
                    clearTimeout(nextIntervalTimeoutId);
            }

         }, [wrapperRef.current?.clientWidth, props.children]
     );
 
     return (
         <StyledAutoScroller 
             id={id} className={className} ref={wrapperRef} 
             style={style} styleOverwrite={styleOverwrite}
         >
             {props.children}
         </StyledAutoScroller>
     );
 }
 
 export default AutoScroller;
 
 /*********************************************
         HELPERS
 ********************************************/
 
 const scrollAction = (wrapper: any, scrollMax: number, scrollIncrement: number) => {
 
    if ((wrapper.scrollLeft >= scrollMax) || scrollMax <= 0) {
        wrapper.scrollLeft = 0;                
    }
    else {
        wrapper.scrollLeft =
            wrapper.scrollLeft + ((scrollIncrement >= 1) 
                ? scrollIncrement 
                : 1
            );
    }
 
 }
 
 const StyledAutoScroller = styled.div<{styleOverwrite?: string}>`
     background-color: white;
     overflow: auto;
 
     ${props => props.styleOverwrite ? props.styleOverwrite : ""}
 `;