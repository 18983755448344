// libraries
import { getAll as getAllAleaAlerts } from "~/store/algo-api/slices/alea-alerts";
// hooks & context
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import React from "react";

export const useLoadAleaAlerts = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(getAllAleaAlerts() as any);
        }, []
    );
}

// hook to get data for react viewport list
// need array to have two objects per index for styling purposes
export const useGetData = (filteredAleaAlerts: any[]) => {
    const [data, setData] = React.useState<[any, any][]>([]);

    useEffect(() => {
        if (filteredAleaAlerts) {
            const newData: [any, any][] = [];
            for (let i = 0; i < filteredAleaAlerts.length; i += 2) {
                const firstItem = filteredAleaAlerts[i];
                const secondItem = filteredAleaAlerts[i + 1];
                newData.push([firstItem, secondItem]);
            }
                
            setData(newData);
        } else {
            setData([]);
        }
    }, [JSON.stringify(filteredAleaAlerts)]);

    return data;
};