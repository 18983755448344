// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export const StyledSearchBar = styled.div<{styleOverwrite?: string}>`
    width: 100%;
    height: 39px;

    ${flexSmart("flex-start center")}

    padding: ${CARD_PADDING}px;
    box-sizing: border-box;

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["input-background"].rgba};

    :hover {
        cursor: pointer;
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const IconClickWrap = styled.div`
    :hover {
        cursor: pointer;
    }
`;

export const SearchText = styled.input`
    width: 100%;
    height: 32px;

    ${flexSmart("flex-start center")}

    margin-left: ${CARD_PADDING}px;
    box-sizing: border-box;

    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    border: none;

    color: ${props => props.theme.colors["text-primary"].rgba};

    background-color: transparent;

    &:focus {
        outline: none;
    }
`;