// libraries
import * as React from "react";
import { EMapTarget } from "~/interfaces";
// styles
import * as SC from "./Styled";

export type IProps = {
    title?: string;
    optionLabels: string[],
    currentSelection: number,
    selectCallback: (selectedIndex: number) => void,
    cancelCallback?: () => void,
    styleOverwrite?: string;
};

export const RadioOptionsCard: React.FC<IProps> = (props) => {

    const {
        title, optionLabels, currentSelection, 
        selectCallback, cancelCallback, styleOverwrite 
    } = props;

    return (
        <SC.StyledRadioOptionsCard styleOverwrite={styleOverwrite}>
            
            <SC.HeaderContainer>
            { title && 
                <SC.Header>
                    {title}              
                </SC.Header>
            }
            <SC.Cancel onClick={cancelCallback}>
                {`Close`}
            </SC.Cancel>
            </SC.HeaderContainer>
            
            <SC.OptionsContainer>
                {optionLabels.map(
                    (label: string, mapIndex: number) => {
                        
                        return ( 
                            <SC.OptionRow 
                                key={label+mapIndex}
                                onClick={() => selectCallback(mapIndex)}
                            >

                                <SC.RadioLabel>{label}</SC.RadioLabel>

                            </SC.OptionRow>
                        );
                        
                    }
                )}
            </SC.OptionsContainer>

        </SC.StyledRadioOptionsCard>
    )
};

export default RadioOptionsCard;