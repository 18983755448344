// libraries
import * as React from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { filterGroupedBySearch as filterCameraGroups } from "~/store/algo-api/slices/camera-group";
import { filterBySearch as filterCrashes } from "~/store/algo-api/slices/crashes";
import { filterBySearch as filterFerries } from "~/store/algo-api/slices/ferries";
import { filterBySearch as filterIncidents } from "~/store/algo-api/slices/incidents";
import { filterBySearch as filterMessageSigns } from "~/store/algo-api/slices/message-signs";
import { filterBySearch as filterTravelerInformationSystems } from "~/store/algo-api/slices/traveler-information-systems";
import { filterBySearch as filterRegionalEvents } from "~/store/algo-api/slices/regional-events";
import { filterBySearch as filterRoadConditions } from "~/store/algo-api/slices/road-conditions";
import { filterBySearch as filterRoadwork } from "~/store/algo-api/slices/roadwork";
import { filterBySearch as filterServiceAssistancePatrol } from "~/store/algo-api/slices/service-assistance-patrols";
import { filterBySearch as filterStateFacilities } from "~/store/algo-api/slices/state-facilities";
import { filterBySearch as filterWeatherAlerts } from "~/store/algo-api/slices/weather-alerts-layer";
// styles
import * as SC from "./Styled";
import * as PageStyles from "~/components/views/page-template/Styled";
// types & models
import { EAlgoLayerType } from "~/interfaces";
// hooks & context
import { useDispatch, useSelector } from "react-redux";
import DataSearchOverlayContext from "~/contexts/DataSearchOverlayContext";
// components
import SearchBar from "../search-bar/SearchBar";
import DataTypeSection from "./data-type-sections/DataTypeSection";
import BackButton from "../back-button/BackButton";
// constants
const algoLayerStrings: string[] = getEnumStrings(EAlgoLayerType);

export type IProps = {
    //
};

export const DataSearchOverlay: React.FC<IProps> = (props) => {

    const overlayContext = React.useContext(DataSearchOverlayContext);

    const dispatch = useDispatch();

    // watch map layers for change in selected layers, we only want results in currently SELECTED layers
    const mapLayersStore: any = useSelector( (store: any) => store["map-layers"]);
    const mapLayers: any = mapLayersStore.mapLayers;

    return (
        <SC.StyledDataSearchOverlay id={"data-search-overlay"}
            showOverlay={overlayContext.showOverlay}
            tickerCount={overlayContext.tickerCount}
        >

            <SC.OverlayContent>

                <PageStyles.StyledPageTemplate>

                    <SC.SearchRow>

                        <BackButton
                            callback={() => overlayContext.setShowOverlay(false)} />

                        <SC.SearchBarWrap>
                            <SearchBar autoSubmit={true}
                                submitCallback={
                                    (evt: any, searchText: string) => { 
                                        dispatch(filterCameraGroups(searchText) as any);
                                        dispatch(filterCrashes(searchText) as any);
                                        dispatch(filterFerries(searchText) as any);
                                        dispatch(filterIncidents(searchText) as any);
                                        dispatch(filterMessageSigns(searchText) as any);
                                        dispatch(filterTravelerInformationSystems(searchText) as any);
                                        dispatch(filterRegionalEvents(searchText) as any);
                                        dispatch(filterRoadConditions(searchText) as any);
                                        dispatch(filterRoadwork(searchText) as any);
                                        dispatch(filterServiceAssistancePatrol(searchText) as any);
                                        dispatch(filterStateFacilities(searchText) as any);
                                        dispatch(filterWeatherAlerts(searchText) as any);
                                    }
                                }
                            />
                        </SC.SearchBarWrap>

                    </SC.SearchRow>

                    <PageStyles.FilteredContentSection>

                        {   algoLayerStrings.filter((str: string) => { if (mapLayers[str] && str !== "all") return true}).map(
                                (key: any) => {
                                    return (
                                        <DataTypeSection key={key} 
                                            isCollapseable={true} showTitle={true}
                                            dataType={key}
                                        />
                                    )
                                }
                            )
                        }

                        <PageStyles.NoResults> {"No Matching Results"} </PageStyles.NoResults>

                    </PageStyles.FilteredContentSection>

                </PageStyles.StyledPageTemplate>

            </SC.OverlayContent>

        </SC.StyledDataSearchOverlay>
    );

};

export default DataSearchOverlay;