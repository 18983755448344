// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
// constants
import { useSelector } from "react-redux";
import { IRGBAObject, rgbaBuilder } from "@caps-mobile/styled-lib";

export type IProps = {
    modal?: boolean;
};

export const PrecipitationLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();

    const weatherRadarStore: any = useSelector( (state: any) => state["weather-radar"]);

    const isLoading: boolean = weatherRadarStore.loadingPrecipitationLegend;

    const legendColors: any = weatherRadarStore?.precipitationLegend;


    return (
        <SC.Legend precipitation={props.modal}>
                <SC.Title isMediumMax={isMediumMax} modal={props.modal}>
                    {"Precipitation"}
                </SC.Title>
                <SC.LegendWrapper>
                    <SC.LegendText left={true}>Light</SC.LegendText>
                    { isLoading &&
                        <SC.Loading />
                    }
                    { !isLoading &&
                        <SC.Colors>
                            { legendColors && 
                                legendColors.map(
                                    (legend: any, index: number) => {
                                        const color: IRGBAObject = {
                                            red: legend.color.red, 
                                            green: legend.color.green, 
                                            blue: legend.color.blue, 
                                            alpha: legend.color.alpha};

                                        return(<SC.ColorBox key={index} fillColor={rgbaBuilder(color) ?? 'black'} />)
                                    }
                                )
                            }
                        </SC.Colors>
                    }
                    <SC.LegendText left={false}>Heavy</SC.LegendText>
                </SC.LegendWrapper>
        </SC.Legend>
    );
};

export default PrecipitationLegend;