// libraries
import styled from "styled-components";
import { RemoveScrollbar, flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS, BOX_SHADOW, BOX_SHADOW_HOVER, SS_HEIGHT, SS_WIDTH } from "~/constants";
import { FixedSizeGrid } from "react-window";
import { List } from 'react-virtualized';

export const SnapshotRow = styled.div`
    width: 100%;

    box-sizing: border-box;
    
    display: grid;
    grid-template-columns: repeat(auto-fill, ${SS_WIDTH}px);
    justify-content: space-around;
    grid-gap: 20px;

    margin-bottom: 20px;
`;

export const SnapshotWrap = styled.div`
    display: flex;
    justify-content: space-around;

    height: ${SS_HEIGHT}px;

    margin-bottom: 20px;
`;

export const DetailsCard = styled.div<{showPointer?: boolean, columnSize?: number, extraStyling?: boolean, travel?: boolean, color?: string}>`

    ${flexSmart("flex-start center")}

    padding: 20px;
    margin: 10px;
    box-sizing: border-box;
    flex-direction: column;

    width: ${props => props.columnSize ? `calc(${props.columnSize}% - 20px)` : ""};

    ${props => props.travel && props.color !== "black" ? `border: 5px solid ${props.color};` : ``};

    border-radius: ${BORDER_RADIUS}px;
    box-shadow: ${BOX_SHADOW};

    ${props => !props.extraStyling ? 
        '' 
        :
        `:hover{
            box-shadow: ${BOX_SHADOW_HOVER};
            cursor: ${props.showPointer ? "pointer" : "default"};
        }`
    }
`;

export const StyledDetailCardsSection = styled.div`
    & > :not(:last-child){
        margin-bottom: 20px;
    }

    :has(div){
        padding-bottom: 20px;
        box-sizing: border-box;
    }
`;

export const ObjectDiv = styled.div<{columnSize?: number}>`
    display: flex;
    flex-direction: ${props => props.columnSize == 100 ? `column;` : ``};
    width: 100%;
`;

export const ObjectsDiv = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledGrid = styled(FixedSizeGrid)`
    ${RemoveScrollbar}
`;

export const StyledList = styled(List)`
    ${RemoveScrollbar}
`;

export const StyledAutoSizer = styled.div`
    flex: 1;
    position: relative;
`;

export const CameraDisclaimer = styled.div`
    padding-bottom: 20px;
    font-size: .7em;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;