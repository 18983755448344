// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledAccordion = styled.div`
    height: 100%;
    width: 100%;
`;

export const Head = styled.div<{styleOverwrite?: string, hide?: boolean, styleHideOverwrite?: string}>`
    ${flexSmart("space-between center")}

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: ${props => props.theme.colors["text-primary"].rgba};

    :hover{
        cursor: pointer;
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
    ${props => (props.styleHideOverwrite && props.hide) ? props.styleHideOverwrite : ""}
`;

export const Body = styled.div<{hide?: boolean, styleOverwrite?: string}>`
    ${props => props.hide 
        ? `
            display: none;
        `
        : ``
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;