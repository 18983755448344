// libraries
import * as React from "react";
import { dehyphenate } from "~/library/string-tools";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "./Styled";

export type IToggleOption = {
    name: string;
    displayName: string;
    icon: any;
    isActive?: boolean;
    activeColor?: string;
    toggleCallback: (targetName: string) => any;
    
}

export type IProps = {
    title?: string;
    options: IToggleOption[];
    closeCallback: () => any;
};

export const IconToggleList: React.FC<IProps> = (props) => {

    const { title, options, closeCallback } = props;

    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();
    const { xxs: isXXSmallMax } = useBreakpoint();

    return (
        <SC.StyledIconToggleList isMediumMax={isMediumMax} isSmallMax={isSmallMax} isXXSmallMax={isXXSmallMax}>
            
            <SC.HeaderContainer>
                { title && <SC.Header> {title} </SC.Header> }
                <SC.Close onClick={closeCallback}> {"Close"} </SC.Close>
            </SC.HeaderContainer>


            { options && 
                <SC.ToggleOptionList>
                    { options.map(
                            (option: IToggleOption, mapIndex: number) => {
                                return (
                                    <SC.ToggleOption 
                                        key={option.name+mapIndex}
                                        onClick={() => option.toggleCallback(option.name)}
                                    >

                                        <SC.ToggleOptionIconWrapper 
                                            isActive={option.isActive} bgColor={option.activeColor}
                                        >
                                            {option.icon}
                                        </SC.ToggleOptionIconWrapper>

                                        <SC.ToggleOptionLabel>
                                            {dehyphenate(option.displayName)}
                                        </SC.ToggleOptionLabel>

                                    </SC.ToggleOption>
                                )
                            }
                        )
                    }
                </SC.ToggleOptionList>
            }

        </SC.StyledIconToggleList>
    )
};

export default IconToggleList;