// libraries
import styled from "styled-components";

export const StyledDataTypeSection = styled.div`
    :not(:first-child){
        margin-top: 20px;
    }
`;

export const DataTypeHeader = styled.div`
    margin-bottom: 20px;
`;

export const DetailTitle = styled.div`
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;

    text-transform: capitalize;
`;

export const CountText = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
`;