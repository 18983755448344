// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledApiObjectView = styled.div`
    width: 100%;
    height: 100%;

    ${flexSmart("center center")}
    flex-direction: column;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};
`;

export const DetailsAndChevronRow = styled.div`
    width: 100%;
    ${flexSmart("flex-start center")}
    flex-direction: row;
`;

export const BasicDetailRow = styled.div<{lawEnforcement?: boolean}>`
    ${flexSmart("flex-start center")}
    width: 100%;

    ${props => props.lawEnforcement ? 
        `
        flex-direction: column;
        `
        : 
        `
            flex-direction: row;
        `
    }
`;

export const ChevronWrap = styled.div`
    width: 22px;
    padding-left: 10px;
    box-sizing: border-box;

    font-size: 24px;
`;