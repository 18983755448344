// libraries 
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
import {
    ReactComponent as LogoSvg
} from "./resources/algo-traffic-light.svg";

export type IFloatingAlgoLogoProps = {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    styleOverwrite?: string;
};

export const AlgoTrafficLogo: React.FC<IFloatingAlgoLogoProps> = (props) => {

    return (
        <SC.StyledFloatingAlgoLogo {...props} >
            <SC.StyledLogoBackfill />
            <LogoSvg />
        </SC.StyledFloatingAlgoLogo>
    )
};

export default AlgoTrafficLogo;