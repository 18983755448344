// libraries
import * as React from "react";

export type ILocationSearchOverlayContext = {
    showOverlay: boolean;
    setShowOverlay: (newVal: boolean) => void;
    toggleShowOverlay: () => void;
    isStart: boolean;
    setIsStart: (newVal: boolean) => void;
    start: string;
    end: string;
    startLocation: [number | undefined, number | undefined];
    endLocation: [number | undefined, number | undefined];
    setNewStartLocation: (name: string, lat: number | undefined, lng: number | undefined) => void;
    setNewEndLocation: (name: string, lat: number | undefined, lng: number | undefined) => void;
};

// location search overlay ui state
export const LocationSearchOverlayContext = React.createContext({
    showOverlay: false,
    setShowOverlay: (newVal: boolean) => {},
    toggleShowOverlay: () => {},
    isStart: false,
    setIsStart: (newVal: boolean) => {},
    start: "",
    end: "",
    setNewStartLocation: (name: string, lat: number | undefined, lng: number | undefined) => {},
    setNewEndLocation: (name: string, lat: number | undefined, lng: number | undefined) => {}
});

export default LocationSearchOverlayContext;