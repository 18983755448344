// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledASAPDetails = styled.div`
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${flexSmart("space-around flex-start")}
    flex-direction: column;
`;

export const PropertyRow = styled.div`
    ${flexSmart("flex-start center")}
`;

export const List = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > :not(:last-child) {
        gap: 0px;
    }
`;

export const ListRow = styled.div`

`;

export const ListValue = styled.div`
    font-size: 17px;
    font-weight: 400;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;

export const PropertyName = styled.span`
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;

export const Bold = styled.span`
    font-size: 17px;
    font-weight: 700;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;

export const PropertyValue = styled.span`
    font-size: 17px;
    font-weight: 400;

    color: ${props => props.theme.colors["text-primary"]["rgba"]};
`;