// libraries
import * as React from "react";
import { filterBySearch } from "~/store/algo-api/slices/alea-alerts";
import { nuonoe } from "@caps-mobile/common-lib";
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
// hooks & context
import { useDispatch, useSelector } from "react-redux";
import { useGetData, useLoadAleaAlerts } from "./hooks";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "~/components/views/data-search-overlay/data-type-sections/detail-section/section-types/Styled";
import * as SC2 from "~/components/views/api-object-details/api-object-details/Styled";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
import { EAlgoApiObjectType } from "~/interfaces";
import ApiObjectView from "~/components/views/api-object-details/ApiObjectView";
import { useBreakpoint } from "~/library/useBreakpoint";
import { IATAleaAlertDto, ATAleaAlert } from "@algo/network-manager/models/v3";
import DateFormatter from "~/library/DateFormatter";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";

export type IProps = {
    //
};

export const LawEnforcementAlertsPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;
    
    const { xs: isXSmallMax } = useBreakpoint();
    const { lg: isLargeMax } = useBreakpoint();
    const columnCount: number = isLargeMax ? 1 : 2;

    const dispatch = useDispatch();

    const [ searchText, setSearchText ] = React.useState<string>("");

    const aleaAlertStore = useSelector( (state: any) => state["alea-alert"]);
    const filteredAleaAlerts = aleaAlertStore.dataFilteredBySearch;
    const data: [any, any][] = useGetData(filteredAleaAlerts);

    const isLoadingContent: boolean = 
        aleaAlertStore.loading;

    // load travel times if necessary
    useLoadAleaAlerts();

    React.useEffect(
        () => { dispatch(filterBySearch(searchText) as any); }, [searchText, aleaAlertStore.data]
    );
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 198,
    });

    const rowRenderer:React.FC<any> = ({ index, key, parent, style }) => {

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure, registerChild }) => {

                    const item = data[index]
                    const data1 = item[0];
                    let aleaAlert1: IATAleaAlertDto = new ATAleaAlert(data1);
                    let pubDate1: Date = new Date(aleaAlert1.publishDate);
                    let formattedDate1: string = "";
                    let Formatter1: DateFormatter = new DateFormatter(pubDate1);
                    formattedDate1 += Formatter1.defaultDateString(false);

                    const data2 = item[1];
                    let aleaAlert2: IATAleaAlertDto | undefined = undefined;
                    let pubDate2: Date;
                    let formattedDate2: string = "";
                    let Formatter2: DateFormatter ;
                    if(data2){
                        aleaAlert2 = new ATAleaAlert(data2);
                        pubDate2 = new Date(aleaAlert2.publishDate);
                        Formatter2 = new DateFormatter(pubDate2);
                        formattedDate2 += Formatter2.defaultDateString(false);
                    }

                    return (
                        <SC.ObjectDiv style={style} ref={(element: any) => { if (registerChild) registerChild(element); }} key={"Row-" + index} columnSize={100/columnCount}>
                            <SC.DetailsCard key={data1.id || index} columnSize={100/columnCount}>
                                <SC2.Header key={index} lawEnforcement={!isXSmallMax} isSmall={isXSmallMax}>{aleaAlert1.title}</SC2.Header>
                                <ApiObjectView lawEnforcement={isXSmallMax}
                                    object={data1} type={EAlgoApiObjectType["alea-alert"]} showChevron={false}/>
                                <SC2.Footer key={data1.id || index}>Published {formattedDate1}</SC2.Footer>
                            </SC.DetailsCard>
                            { data2 &&
                                <SC.DetailsCard key={data2.id || index} columnSize={100/columnCount}>
                                    <SC2.Header key={index} lawEnforcement={!isXSmallMax} isSmall={isXSmallMax}>{aleaAlert2?.title}</SC2.Header>
                                    <ApiObjectView lawEnforcement={isXSmallMax}
                                        object={data2} type={EAlgoApiObjectType["alea-alert"]} showChevron={false}/>
                                    <SC2.Footer key={data2.id || index}>Published {formattedDate2}</SC2.Footer>
                                </SC.DetailsCard>
                            }
                        </SC.ObjectDiv>
                    )
                }}
            </CellMeasurer>
        );
    }

    const handleResize = () => {
        cache.clearAll();
    };

    return (
        <PageTemplate 
            title={"Law Enforcement Alerts"}
            searchBar={{
                autoSubmit: true,
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >

            { nuonoe(filteredAleaAlerts) && 
                <SC.ObjectsDiv>
                    <AutoSizer onResize={handleResize}>
                        {({ height, width }: { height: number; width: number }) => { 
                            return (
                                <SC.StyledList
                                    height={height}
                                    rowCount={data.length}
                                    width={width}
                                    deferredMeasurementCache={cache}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={rowRenderer}
                                />
                            );
                        }}
                    </AutoSizer>
                </SC.ObjectsDiv>
            }

            { (!filteredAleaAlerts || !filteredAleaAlerts?.length || filteredAleaAlerts?.length <= 0) &&
                <NoResultsTemplate loading={isLoadingContent} pageType="Law Enforcement Alerts" />
            }

        </PageTemplate>
    );
};

export default LawEnforcementAlertsPage;