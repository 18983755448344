// libraries
import { useEffect } from "react";
// types & models
import { EAlgoLayerType, IMarkerTapObject } from "~/interfaces";
import { useMarkerGroup } from "./useMarkerGroup";

export const useAllMarkerGroups = (
    map: H.Map | undefined, 
    markerTapCallback?: (markerData: IMarkerTapObject) => void
) => {

    const trafficEventGroup = useMarkerGroup(map, markerTapCallback);

    useEffect(
        () => {

            if (map){
                trafficEventGroup && map.addObject(trafficEventGroup);
            }

            return () => {
                //
            }

        }, [
            map, trafficEventGroup
        ]
    );
};