// libraries
import { usePrevious } from "@algo/hooks";
import React from "react";
import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { unselected_route } from "~/constants";
import { ThemeContext } from "~/theme";

export const usePolylineGroup = (
    map: H.Map | undefined,
    selectedIndex: number,
    markerTapCallback?: (index: number) => void
) => {

    const theme: any = React.useContext(ThemeContext);
    const routeStore: any = useSelector( (store: any) => store.route );
    const routeData: any[] = routeStore.data?.slice(0, 3)
    ? [...routeStore.data?.slice(0, 3)] : [];
    const checksum: number = routeStore.lastChecksum;
    const prevChecksum = usePrevious(checksum);
    const selectedRouteIndex: number = selectedIndex;
    const prevSelectedRouteIndex = usePrevious(selectedRouteIndex);

    const [polylineGroup, setPolylineGroup] = useState<H.map.Group | null>(null);

    useEffect(
        () => {

            if (
                map &&
                routeData &&
                (
                    (checksum !== prevChecksum) ||
                    (selectedRouteIndex !== prevSelectedRouteIndex) ||
                    !polylineGroup
                )
            ) {

                let newPolylineGroup: H.map.Group = polylineGroup || new H.map.Group();
                // remove all the current group if anything exists
                newPolylineGroup.forEach( (object: H.map.Object) => {object.dispose();})
                newPolylineGroup.removeAll();

                if(routeData.length > 0 ){
                    routeData.map( (feature: any, index: number) => {
                        const isSelected: boolean = index + 1 === selectedRouteIndex;
                        let reader: H.data.geojson.Reader | null = 
                            new H.data.geojson.Reader(
                                undefined,  // optional url string (to a geojson file)
                                {
                                    style: function (mapObject) {
                                        if (mapObject instanceof H.map.Polyline) {
                                            mapObject.setStyle({
                                                strokeColor: isSelected ? theme.colors["primary"].rgba : unselected_route,
                                                lineWidth: 5,
                                            });
                                            mapObject.setZIndex(isSelected ? 2 : 1);
                                            mapObject?.addEventListener('tap', () => {
                                                markerTapCallback && markerTapCallback(index + 1)
                                            });
                                            newPolylineGroup.addObject(mapObject);
                                        }
                                    },
                                    // added this bit to stop warnings, if there are BUGS, perhaps consider this property
                                    disableLegacyMode: true
                                }
                            );

                        reader.parseData(feature);
                        reader = null;
                    });

                    setPolylineGroup(newPolylineGroup);
                }
                else setPolylineGroup(null);
            }

            // cleanup logic
            return () => {
                //
            }
            
        }, [map, routeData, checksum, polylineGroup, selectedIndex, markerTapCallback]
    );

    return polylineGroup;
};