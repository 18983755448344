import React from "react";
import { useEffect } from "react";
import { ICoord, IZoomToValue } from "~/interfaces";

export const useSetZoomAndCenter = (
    mapAndPlatform: [H.Map | undefined, H.service.Platform | undefined], 
    zoomTarget: IZoomToValue, 
    zoomToggled: boolean = false,
    isMediumMax?: boolean
) => {

    // lat and lng of current location
    const [lat, setLat] = React.useState<number>();
    const [lng, setLng] = React.useState<number>();
    navigator.geolocation.getCurrentPosition(
        (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }
    );

    useEffect(
        () => {
            let map: H.Map | undefined = mapAndPlatform[0];
            let platform: H.service.Platform | undefined = mapAndPlatform[1];

            if (map && platform){
                //If entire state
                if(zoomTarget.bounds){
                    var bbox = new H.geo.Rect(
                        isMediumMax ? zoomTarget.bounds?.top + 1 : zoomTarget.bounds?.top, 
                        zoomTarget.bounds?.left, 
                        zoomTarget.bounds?.bottom, 
                        zoomTarget.bounds?.right);
                    map.getViewModel().setLookAtData({
                        bounds: bbox
                    });
                }
                else if(zoomTarget.center){
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter(zoomTarget.center);
                }
                else if(lat && lng){
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter({lat: lat, lng: lng});
                }
            }

        }, [mapAndPlatform[0], mapAndPlatform[1], zoomTarget, zoomToggled]
    );
};