// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { CARD_PADDING, BORDER_RADIUS } from "~/constants";

export const CloseButtonTitleWrapper = styled.div`
    padding-bottom: 20px;
    width: 100%;

    ${flexSmart("space-betweeen center")}
`;

export const CloseButton = styled.div`
    box-sizing: border-box;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 700;
    font-size: 17px;
    line-height: 147%;

    :hover{
        cursor: pointer;
    }
`;

export const Title = styled.div`
    width: 100%;

    box-sizing: border-box;

    font-size: 48px;
    line-height: 108.36%;
    font-weight: 600;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledLegend = styled.div<{isMediumMax?: boolean, radar?: boolean, weather?: boolean}>`
    width: 100%;
    min-width: ${props => props.isMediumMax ? '100px' : '225px'};
    padding: ${props => props.radar ? '10' : CARD_PADDING}px;
    margin-bottom: 10px;
    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => (props.weather || props.radar) ? 'rgba(30,30,30,.75)' : props.theme.colors["bg-primary"].rgba};

    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
`;