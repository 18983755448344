// libraries
import { useEffect, useRef } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";

export const useInitializePlayer = (
    d_config: VideoJsPlayerOptions,
    playerCallback?: (player: VideoJsPlayer) => void
): React.RefObject<HTMLVideoElement> => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const playerInitialized = useRef<boolean>(false);

    useEffect(() => {
        if (!playerInitialized.current && videoRef.current) {
            const playerInstance = videojs(videoRef.current, d_config, () => { });
            playerCallback && playerCallback(playerInstance);
            playerInitialized.current = true;
        }
    }, [d_config, playerCallback]);

    return videoRef;
};
