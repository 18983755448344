// libraries// libraries
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EHereMapScheme } from "~/interfaces";
import { mapSchemePreview } from "~/resources/ui/map-scheme-previews";
import { updateMapSchemeString } from "~/store/map-layers/map-layers";
// styles
import * as SC from "./Styled";

export type IProps = {
    label?: string;
    value: EHereMapScheme;
};

export const SchemeSelectOption: React.FC<IProps> = (props) => {

    const {  } = props;

    const dispatch = useDispatch();
    const mapLayersStore = useSelector( (state: any) => state["map-layers"]);

    return (
        <SC.StyledSchemeSelectOption
            onClick={ 
                () => {
                    dispatch(updateMapSchemeString(props.value) as any);
                }
            }
        >

            <SC.ToggleOptionImgWrapper isActive={EHereMapScheme[mapLayersStore.mapScheme as keyof typeof EHereMapScheme] === props.value}>
                { mapSchemePreview(props.value) }
            </SC.ToggleOptionImgWrapper>

            <SC.SchemeOptionLabel>
                { props.label || "Scheme Option" }
            </SC.SchemeOptionLabel>
                
        </SC.StyledSchemeSelectOption>
    )
};

export default SchemeSelectOption;