// libraries
import { useEffect, useState } from "react";
// constants
import { breakpointConstants } from "~/theme/breakpoints";

export const useBreakpoint = (
    //
): {xxs: boolean, xs: boolean, sm: boolean, md: boolean, lg: boolean, xl: boolean} => {

    const { 
        xxs, xs,
        sm, md,
        lg, xl
    } = breakpointConstants.max;

    const [ isXXSmallMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${xxs}px)`));
    const [ isXSmallMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${xs}px)`));
    const [ isSmallMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${sm}px)`));
    const [ isMediumMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${md}px)`));
    const [ isLargeMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${lg}px)`));
    const [ isXLargeMax ] = 
        useState<MediaQueryList>(window.matchMedia(`(max-width: ${xl}px)`));

    const [ breakpointXxs, setBreakpointXxs ] = useState<boolean>(false);
    const [ breakpointXs, setBreakpointXs ] = useState<boolean>(false);
    const [ breakpointSm, setBreakpointSm ] = useState<boolean>(false);
    const [ breakpointMd, setBreakpointMd ] = useState<boolean>(false);
    const [ breakpointLg, setBreakpointLg ] = useState<boolean>(false);
    const [ breakpointXl, setBreakpointXl ] = useState<boolean>(false);

    useEffect(
        () => {

            setBreakpointXxs(checkMatch(isXXSmallMax) ? true : false);
            setBreakpointXs(checkMatch(isXSmallMax) ? true : false);
            setBreakpointSm(checkMatch(isSmallMax) ? true : false);
            setBreakpointMd(checkMatch(isMediumMax) ? true : false);
            setBreakpointLg(checkMatch(isLargeMax) ? true : false);
            setBreakpointXl(checkMatch(isXLargeMax) ? true : false);

            isXXSmallMax.addEventListener("change",
                () => setBreakpointXxs(checkMatch(isXXSmallMax) ? true : false)
            );
            isXSmallMax.addEventListener("change",
                () => setBreakpointXs(checkMatch(isXSmallMax) ? true : false)
            );
            isSmallMax.addEventListener("change",
                () => setBreakpointSm(checkMatch(isSmallMax) ? true : false)
            );
            isMediumMax.addEventListener("change",
                () => setBreakpointMd(checkMatch(isMediumMax) ? true : false)
            );
            isLargeMax.addEventListener("change",
                () => setBreakpointLg(checkMatch(isLargeMax) ? true : false)
            );
            isXLargeMax.addEventListener("change",
                () => setBreakpointXl(checkMatch(isXLargeMax) ? true : false)
            );

            return (
                () => {
                    isXXSmallMax.removeEventListener("change",
                        () => setBreakpointXxs(checkMatch(isXXSmallMax) ? true : false)
                    );
                    isXSmallMax.removeEventListener("change",
                        () => setBreakpointXs(checkMatch(isXSmallMax) ? true : false)
                    );
                    isSmallMax.removeEventListener("change",
                        () => setBreakpointSm(checkMatch(isSmallMax) ? true : false)
                    );
                    isMediumMax.removeEventListener("change",
                        () => setBreakpointMd(checkMatch(isMediumMax) ? true : false)
                    );
                    isLargeMax.removeEventListener("change",
                        () => setBreakpointLg(checkMatch(isLargeMax) ? true : false)
                    );
                    isXLargeMax.removeEventListener("change",
                        () => setBreakpointXl(checkMatch(isXLargeMax) ? true : false)
                    );
                }
            )

        }, [isXXSmallMax, isXSmallMax, isSmallMax, isMediumMax, isLargeMax, isXLargeMax]
    );

    return {
        xxs: breakpointXxs, xs: breakpointXs, sm: breakpointSm, 
        md: breakpointMd, lg: breakpointLg, xl: breakpointXl
    };
};

const checkMatch = (x: MediaQueryList) => {
    if (x.matches)
        return true;
    else return false;
}