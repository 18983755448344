// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { EATAlignment, IATMessageSignLine } from "@algo/network-manager/models/v3";

export type IProps = {
    line: IATMessageSignLine;
};

export const MessageSignLine: React.FC<IProps> = (props) => {

    const { line } = props;

    const [ lineIsOn, setLineIsOn ] = React.useState<boolean>(true);

    const lineFlashLoop = (
        lineOnTimeoutId: NodeJS.Timeout | null, lineOffTimeoutId: NodeJS.Timeout | null,
        lineOnSeconds: number, lineOffSeconds: number
    ) => {
        // wait for pageOnSeconds number of seconds...
        lineOnTimeoutId = setTimeout(
            () => {
                // then turn the page content off and wait for pageOffSeconds...
                setLineIsOn(false);
                lineOffTimeoutId = setTimeout(
                    () => {
                        // then increment the page list
                        setLineIsOn(true);
                        lineFlashLoop(lineOnTimeoutId, lineOffTimeoutId, lineOnSeconds, lineOffSeconds);
                    }, lineOffSeconds * 1000
                )
            }, 
            lineOnSeconds * 1000
        )
    }

    React.useEffect(
        () => {

            let lineOnTimeoutId: NodeJS.Timeout | null = null;
            let lineOffTimeoutId: NodeJS.Timeout | null = null;

            let lineOnSeconds: number = line.flashOnSeconds || 0;
            let lineOffSeconds: number = line.flashOffSeconds || 0;

            if (!lineOnSeconds){
                setLineIsOn(true);
            }

            else {
                lineFlashLoop(lineOnTimeoutId, lineOffTimeoutId, lineOnSeconds, lineOffSeconds);
            }

            // cleanup function
            return () => {
                if (lineOnTimeoutId) clearTimeout(lineOnTimeoutId);
                if (lineOffTimeoutId) clearTimeout(lineOffTimeoutId);
            }
        }, [line]
    );

    return (
        <SC.StyledMessageSignLine align={mapAlignToFlexString(line.alignment)} lineIsOn={lineIsOn}>
            {line.text}
        </SC.StyledMessageSignLine>
    );
};

export default MessageSignLine;

export const mapAlignToFlexString = (align: EATAlignment): string => {

    switch(align){
        case EATAlignment.Right:
            return "right";
        case EATAlignment.Full:
            return "justify";
        case EATAlignment.Center:
            return "center";
        case EATAlignment.Left:
        default:
            return "left";
    }
};