// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import { EThemeOptions, whenSmallMax } from "~/theme";

export const StyledCamerasSection = styled.div`
    height: 100%;
    width: 100%;

    margin-top: 20px;

    ${whenSmallMax(`
        width: 100%;
        height: auto;

        margin-top: 0;
        padding: 20px 0;
        box-sizing: border-box;
    `)}
`;

export const CamerasRow = styled.div`
    height: 300px;
    width: 100%;

    display: contents;
`;

export const SnapshotHeaderRow = styled.div`
    ${flexSmart("space-between center")}
    width: 100%;
`;
    
export const NearbyCamerasText = styled.span`
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const PlayAllText = styled.span<{noCameras?: boolean}>`
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color: ${props => props.noCameras ? '#999999;' : props.theme.colors["primary"].rgba};

    ${props => !props.noCameras ? 
        `:hover{
            cursor: pointer;
        }` : 
        ''
    }
`;

export const SnapshotRow = styled.div`
    width: 100%;
    height: 198px;
    
    ${flexSmart("flex-start center")}

    overflow: auto;

    ${RemoveScrollbar}

    > div {
        min-width: 350px;
        margin-right: 10px;
    }
`;

export const SnapshotWrap = styled.div<{width?: string}>`
    width: ${ props => props.width ? `${props.width}` : "auto" };

    :hover{
        cursor: pointer;
    }
`;

export const NoCameras = styled.div`
    width: 100%;
    height: 198px;
    color: ${props => props.theme.colors["text-primary"].rgba};
    
    ${flexSmart("center center")}
`;

export const LoadingCamerasWrap = styled.div<{width?: string}>`
    width: 100%;
    ${flexSmart("flex-start center")}
    overflow: auto;
    ${RemoveScrollbar}
`;

export const LoadingCameras = styled.div`
    min-width: 350px;
    height: 198px;
    background-color: ${props => props.theme.mode === EThemeOptions.Dark ? `#696969` : `#E0E0E0`};
    border-radius: 12px;
    margin-right: 10px;
    
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    
    @keyframes shimmer {
    100% {-webkit-mask-position:left}
    }
`;

export const cameraDisclaimer = styled.div`
    margin-top: 10px;
    font-size: .7em;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;