// libraries
import { IATTravelerInformationSystemDto } from "@algo/network-manager/models/v3";
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
    object: IATTravelerInformationSystemDto;
};

export const Other511Details: React.FC<IProps> = (props) => {

    const { state, playStoreUrl, webAppUrl } = props.object;

    return (
        <SC.StyledOther511Details>

            <SC.RedirectText>
                {`For ${state} related traveler information, please visit the ${state} 511 site.`}
            </SC.RedirectText>

            <SC.LinksRow>
                <SC.LinkText onClick={ () => window.open(webAppUrl)}>
                    {`Visit the Website`}
                </SC.LinkText>

                <SC.LinkText onClick={ () => window.open(playStoreUrl)}>
                    {`Download the App`}
                </SC.LinkText>
            </SC.LinksRow>

        </SC.StyledOther511Details>
    );
};

export default Other511Details;