// types & models
import { EHttpRequestMethod, IProcessedResponse, NetworkManager } from "@algo/network-manager/managers/v3";
import { nuonoe } from "@caps-mobile/common-lib";
import { createSlice } from "@reduxjs/toolkit";
import { EAlgoApiObjectType } from "~/interfaces";
import { buildLastResponse } from "../../library";
import { IAlgoObjectStore } from "./interfaces";
// constants
import camerasForEvent from "~/store/algo-api/test-data/cameras/cameras-for-event.json";

export const ALGO_OBJECT_STORE_INIT: IAlgoObjectStore = {

    // standard data 
    data: null,
    errorMessage: "",
    status: 0,
    noContent: false,

    lastData: null,
    lastErrorMessage: "",
    lastChecksum: "",
    lastCount: 0,
    lastStatus: 0,

    loading: false,

    // temporary profile section **remove this to a proper Users slice later
    profile: null,
    loadingProfile: false,

    // data by id
    dataById: {},

    camerasById: {},
    loadingCameras: false,

    // grouped data
    dataGrouped: null,
    errorMessageGrouped: "",
    statusGrouped: 0,
    noContentGrouped: false,

    lastDataGrouped: null,
    lastErrorMessageGrouped: "",
    lastChecksumGrouped: "",
    lastCountGrouped: 0,
    lastStatusGrouped: 0,

    loadingGrouped: false,

    // geojson data
    geometry: [],

    // filtered data
    dataFilteredBySearch: null,
    dataFilteredBySelections: null,
    dataGroupedFilteredBySearch: null,
};

// avoid having to add this large block of code to every algo api store file
export const createAlgoSlice = (sliceName: EAlgoApiObjectType | string) => {

    return createSlice(
        {
            name: sliceName,
            initialState: ALGO_OBJECT_STORE_INIT,
            reducers: {
                begin: (state: any) => {
                    state.loading = true;
                },
                success: (state: any, action: any) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;  
                },
                failure: (state: any, action: any) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastData = [];
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastChecksum = "";
                    state.lastCount = 0;
                    state.lastStatus = 0;

                    state.loading = false;
                },
                successRoute: (state: any, action: any) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount; 
                },
                successRouteLoading: (state: any, action: any) => {
                    state.loading = false;  
                },
                resetRoute: () => ALGO_OBJECT_STORE_INIT,
                beginRouteTrafficEvents: (state: any) => {
                    state.trafficEventsLoading = true;
                },
                successRouteTrafficEvents: (state: any, action: any) => {
                    state.trafficEvents = action.payload.data;
                    state.geometry = action.payload.geometry;
                    state.trafficEventsErrorMessage = action.payload.errorMessage;
                    state.trafficEventsStatus = action.payload.status;
                    state.trafficEventsNoContent = !nuonoe(action.payload.data);

                    state.trafficEventsLastData = action.payload.data;
                    state.trafficEventsLastErrorMessage = action.payload.errorMessage;
                    state.trafficEventsLastStatus = action.payload.status;
                    state.trafficEventsLastChecksum = action.payload.xChecksum;
                    state.trafficEventsLastCount = action.payload.xCount; 
                    state.trafficEventsLoading = false; 
                },
                failureRouteTrafficEvents: (state: any, action: any) => {
                    state.trafficEvents = [];
                    state.geometry = [];
                    state.trafficEventsErrorMessage = action.payload.errorMessage;
                    state.trafficEventsStatus = action.payload.trafficEventStatus;
                    
                    state.trafficEventsLastData = [];
                    state.lastTrafficEventsErrorMessage = action.payload.errorMessage;
                    state.lastTrafficEventsLastStatus = action.payload.trafficEventStatus;
                    state.trafficEventsLastChecksum = "";
                    state.trafficEventsLastCount = 0;

                    state.trafficEventsLoading = false;
                },
                beginRouteCameras: (state: any) => {
                    state.camerasLoading = true;
                },
                successRouteCameras: (state: any, action: any) => {
                    state.cameras = action.payload.data;
                    state.camerasErrorMessage = action.payload.errorMessage;
                    state.camerasStatus = action.payload.status;
                    state.camerasNoContent = !nuonoe(action.payload.data);

                    state.camerasLastData = action.payload.data;
                    state.camerasLastErrorMessage = action.payload.errorMessage;
                    state.camerasLastStatus = action.payload.status;
                    state.camerasLastChecksum = action.payload.xChecksum;
                    state.camerasLastCount = action.payload.xCount;
                    state.camerasLoading = false; 
                },
                failureRouteCameras: (state: any, action: any) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastData = [];
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastChecksum = "";
                    state.lastCount = 0;
                    state.lastStatus = 0;

                    state.camerasLoading = false;
                },
                beginProfile: (state: any) => {
                    state.loadingProfile = true;
                },
                successProfile: (state: any, action: any) => {
                    state.loadingProfile = false;
                    state.profile = action.payload.data;
                },
                failureProfile: (state: any, action: any) => {
                    state.loadingProfile = false;
                },
                beginPrecipitationLegend: (state: any) => {
                    state.loadingPrecipitationLegend = true;
                },
                successPrecipitationLegend: (state: any, action: any) => {
                    state.precipitationLegend = action.payload.data;
                    state.precipitationLegendErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendNoContent = !nuonoe(action.payload.data);

                    state.precipitationLegendLastData = action.payload.data;
                    state.precipitationLegendLastErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastStatus = action.payload.status;
                    state.precipitationLegendLastChecksum = action.payload.xChecksum;
                    state.precipitationLegendLastCount = action.payload.xCount; 

                    state.loadingPrecipitationLegend = false; 
                },
                failurePrecipitationLegend: (state: any, action: any) => {
                    state.precipitationLegend = [];
                    state.precipitationLegendErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendStatus = action.payload.trafficEventStatus;
                    
                    state.precipitationLegendLastData = [];
                    state.precipitationLegendLastErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastChecksum = action.payload.trafficEventStatus;
                    state.precipitationLegendLastChecksum = "";
                    state.precipitationLegendLastCount = 0;

                    state.trafficEventsLoading = false;
                },
                successPrecipitationLegendHead: (state: any, action: any) => {
                    state.precipitationLegendErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastStatus = action.payload.status;
                    state.precipitationLegendLastChecksum = action.payload.xChecksum;
                    state.precipitationLegendLastCount = action.payload.xCount; 

                    state.loadingPrecipitationLegend = false; 
                },
                failurePrecipitationLegendHead: (state: any, action: any) => {
                    state.precipitationLegendErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastErrorMessage = action.payload.errorMessage;
                    state.precipitationLegendLastChecksum = action.payload.trafficEventStatus;
                    state.precipitationLegendLastChecksum = "";
                    state.precipitationLegendLastCount = 0;

                    state.trafficEventsLoading = false;
                },
                beginInit: (state: any) => {
                    state.loading = true;
                },
                successInit: (state: any, action: any) => {
                    state.data = action.payload.data;
                    state.dataGrouped = action.payload.data;
                    state.dataFilteredBySearch = action.payload.data;
                    state.dataFilteredBySelections = action.payload.data;
                    state.dataGroupedFilteredBySearch = action.payload.data;

                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;  

                    state.geometry = action.payload.geometry;
                },
                failureInit: (state: any, action: any) => {
                    state.data = [];
                    state.dataGrouped = [];
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastData = [];
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastChecksum = "";
                    state.lastCount = 0;
                    state.lastStatus = 0;

                    state.loading = false;

                    state.geometry = [];
                },
                beginGrouped: (state: any) => {
                    state.loadingGrouped = true;
                },
                successGrouped: (state: any, action: any) => {
                    state.dataGrouped = action.payload.data;
                    //state.dataGroupedFilteredBySearch = action.payload.data;
                    state.errorMessageGrouped = action.payload.errorMessage;
                    state.statusGrouped = action.payload.status;
                    state.noContentGrouped = !nuonoe(action.payload.data);

                    state.lastDataGrouped = action.payload.data;
                    state.lastErrorMessageGrouped = action.payload.errorMessage;
                    state.lastStatusGrouped = action.payload.status;
                    state.lastChecksumGrouped = action.payload.xChecksum;
                    state.lastCountGroup = action.payload.xCount;

                    state.loadingGrouped = false;  
                },
                failureGrouped: (state: any, action: any) => {
                    state.dataGrouped = [];
                    state.errorMessageGrouped = action.payload.errorMessage;
                    
                    state.lastDataGrouped = [];
                    state.lastErrorMessageGrouped = action.payload.errorMessage;
                    state.lastChecksumGrouped = "";
                    state.lastCountGrouped = 0;
                    state.lastStatusGrouped = 0;

                    state.loadingGrouped = false;
                },
                beginGeojson: (state: any) => {
                    state.loading = true;
                },
                successGeojson: (state: any, action: any) => {
                    state.data = action.payload.data;
                    state.dataFilteredBySearch = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;

                    state.geometry = action.payload.geometry;
                },
                failureGeojson: (state: any, action: any) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastData = [];
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastChecksum = "";
                    state.lastCount = 0;
                    state.lastStatus = 0;

                    state.loading = false;

                    state.geometry = [];
                },
                beginGetCameras: (state: any) => {
                    state.loadingCameras = true;
                },
                successGetCameras: (state: any, action: any) => {
                    state.camerasById = {
                        ...state.camerasById, 
                        [action.payload.id]: {
                            data: action.payload.data,
                            lastData: action.payload.data,
                            lastErrorMessage: action.payload.errorMessage,
                            lastStatus: action.payload.status,
                            lastChecksum: action.payload.xChecksum,
                            lastCount: action.payload.xCount
                        }
                    };

                    state.loadingCameras = false;
                },
                failureGetCameras: (state: any, action: any) => {

                    state.camerasById = {
                        ...state.camerasById, 
                        [action.payload.id]: {
                            data: [],
                            lastData: [],
                            lastErrorMessage: action.payload.errorMessage,
                            lastStatus: 0,
                            lastChecksum: "",
                            lastCount: 0
                        }
                    };
                    
                    state.loadingCameras = false;
                },
                filterDataBySearch: (state: any, action: any) => {
                    state.dataFilteredBySearch = action.payload.filteredData;
                },
                filterDataBySelections: (state: any, action: any) => {
                    state.dataFilteredBySelections = action.payload.dataFilteredBySelections;
                },
                filterDataGroupedBySearch: (state: any, action: any) => {
                    state.dataGroupedFilteredBySearch = action.payload.filteredData;
                }
            }
        }
    )
};

export const commonGetById_api = (manager: any, params: any, slice: any) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.begin());

        manager.getById(buildLastResponse(getState()[slice.name]), {...params}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(slice.actions.failure({ errorMessage: response.error.message}))
                    else 
                        dispatch(slice.actions.success(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(slice.actions.failure({ errorMessage: error.message }))
            )
    }
}

export const commonGetCameras_api = (manager: any, slice: any, params?: any,) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.beginGetCameras());

        manager.getCameras(buildLastResponse(getState()[slice.name].camerasById[params.id]), {...params}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(slice.actions.failureGetCameras({ errorMessage: response.error.message}))
                    else 
                        dispatch(slice.actions.successGetCameras(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(slice.actions.failureGetCameras({ errorMessage: error.message }))
            )
    }
}

export const commonGetCameras_test = (slice: any, params?: any,) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.beginGetCameras());

        setTimeout(
            () => {
                dispatch(slice.actions.successGetCameras( { data: camerasForEvent, id: params.id }));
            }, 2000
        );
    }
}

export const commonGetAll_api = (manager: any, slice: any, params?: any) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.begin());

        manager.getAll(buildLastResponse(getState()), {...params}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(slice.actions.failure({ errorMessage: response.error.message, id: params?.id || 0}))
                    else 
                        dispatch(slice.actions.success({ ...response.getReduxObject(), id: params?.id || 0}))
                }
            ).catch(
                (error: Error) => dispatch(slice.actions.failure({ errorMessage: error.message, id: params?.id || 0 }))
            )
    }
}

export const commonInit_api = (manager: any, slice: any, params?: any) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.beginInit());

        manager.getAll(buildLastResponse(getState()), {...params}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(slice.actions.failureInit({ errorMessage: response.error.message}))
                    else 
                        dispatch(slice.actions.successInit(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(slice.actions.failureInit({ errorMessage: error.message }))
            )
    }
}

export const commonFilterBySearch = (
    slice: any, 
    searchTerm: string = "", 
    searchMatch: (dataItem: any, searchTerm?: string) => boolean
) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        let data: any[] = getState()[slice.name].data || [];
        let filteredData: any[] = data;

        if (!data || data.length === 0) dispatch(
            slice.actions.filterDataBySearch(
                {filteredData}
            )
        )

        if (!searchTerm) {
            dispatch(
                slice.actions.filterDataBySearch(
                    {filteredData: data}
                )
            )
            
            return;
        }

        filteredData = data.filter( 
            (dataItem: any) => { if (searchMatch(dataItem, searchTerm)) return true; }
        );

        dispatch(slice.actions.filterDataBySearch({filteredData}));   
    }
};

export const commonFilterGroupedBySearch = (
    slice: any, 
    searchTerm: string = "", 
    searchMatch: (dataItem: any, searchTerm?: string) => boolean
) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        let groupedData: any[] = getState()[slice.name].dataGrouped || [];
        let filteredData: any[] = groupedData;

        if (!searchTerm) {
            dispatch(
                slice.actions.filterDataGroupedBySearch(
                    {filteredData}
                )
            )
            
            return;
        }

        filteredData = groupedData.filter( 
            (dataGroup: any, filterIndex: number) => {

                let group: any = dataGroup;
                let pages: any[] = group.pages || [];

                let firstPage: any = pages[0];
                let firstItem: any | undefined = ( firstPage.items )
                    ? firstPage.items[0] 
                    : undefined;

                let dataObject: any = firstItem[slice.name];

                if (searchMatch(dataObject, searchTerm)) return dataGroup;
            }
        );

        dispatch(slice.actions.filterDataGroupedBySearch({filteredData}));   
    }
}

export const commonLocationsBySearch = (manager: any, slice: any, params?: any) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(slice.actions.beginInit());

        manager.get({...params})
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(slice.actions.failureInit({ errorMessage: response.error.message}))
                    else 
                        dispatch(slice.actions.successInit(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(slice.actions.failureInit({ errorMessage: error.message }))
            )
    }
}