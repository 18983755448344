// libraries
import * as React from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
// types & models
import { EMapTarget } from "~/interfaces";
// components
import FullPageModal from "~/components/views/modal/FullPageModal";
import RadioOptionsCard from "~/components/views/radio-options-card/RadioOptionsCard";
// constants
const TARGETS: string[] = getEnumStrings(EMapTarget);

export type IProps = {
    modalOpen: boolean;
    currentSelection: number;
    selectionCallback: (newValue: EMapTarget) => any;
    closeCallback: () => any;
    myLocationEnabled: boolean;
};

export const ZoomOptionsModal: React.FC<IProps> = (props) => {

    const { 
        modalOpen, currentSelection, 
        selectionCallback, closeCallback, myLocationEnabled
    } = props;

    const targetOptions: string[] = myLocationEnabled ? TARGETS : TARGETS.filter(x => x !== EMapTarget["my location"]);

    const handleZoomOptionSelected = (selectedIndex: number) => {
        closeCallback();
        if (selectedIndex >= 0 && selectedIndex < targetOptions.length){
            let selectedTarget: string = targetOptions[selectedIndex || 0];
            selectionCallback(selectedTarget as EMapTarget);
        }
    }

    if (!modalOpen) return null;

    return (
        <FullPageModal
            showCloseButton={false}
            closeCallback={closeCallback}
        >
            <RadioOptionsCard
                title={"Map Region"}
                optionLabels={targetOptions}
                currentSelection={currentSelection}
                selectCallback={handleZoomOptionSelected}
                cancelCallback={closeCallback}
            />

        </FullPageModal>
    );


};

export default ZoomOptionsModal;