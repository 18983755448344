import ATFerry, { ATCamera, ATFacility, ATMessageSign } from "@algo/network-manager/models/v3";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ObjectDetailModal from "~/components/views/modal/object-detail-modal/ObjectDetailModal";
import { OBJ_ID_QUERY_NAMES, OBJ_QUERY_TYPE } from "~/constants";
import { IDetailModalContext } from "~/contexts/DetailModalContext";
import { EAlgoApiObjectType, IMarkerTapObject } from "~/interfaces";
import { getById } from "~/store/algo-api/slices/weather-alerts";
import { getById as getTrafficEventById } from "~/store/algo-api/slices/traffic-events";

export const useQueryStringDetailModal = (
    detailModalContext: IDetailModalContext,
) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();

    // camera
    const cameraStore: any = useSelector( (store: any) => store[EAlgoApiObjectType.camera] ); 
    const cameraData: any[] = cameraStore.data || [];
    // weather alert
    const weatherAlertStore: any = useSelector( (store: any) => store["weather-alerts"] );
    // traffic event
    const trafficEventStore: any = useSelector( (store: any) => store["traffic-event"] );
    // facility
    const facilityStore: any = useSelector( (store: any) => store[EAlgoApiObjectType["state-facility"]] );
    const facilityData: any[] = facilityStore.data || [];
    // message sign
    const messageSignStore: any = useSelector( (store: any) => store[EAlgoApiObjectType["message-sign"]] );
    const messageSignData: any[] = messageSignStore.data || [];
    // ferry
    const ferryStore: any = useSelector( (store: any) => store[EAlgoApiObjectType["ferry"]] );
    const ferryData: any[] = ferryStore.data || [];

    useEffect(
        () => {
            let dataObject: any = null;
            let nextQueryValue: string | null = null;
            let nextQueryType: string = "Unknown";

            for (let i = 0; i < OBJ_ID_QUERY_NAMES.length; i++){

                nextQueryValue = searchParams.get(OBJ_ID_QUERY_NAMES[i]);

                if (nextQueryValue){

                    nextQueryType = OBJ_QUERY_TYPE[i];

                    switch(OBJ_QUERY_TYPE[i]){
                        case "traffic-event":
                            let trafficEvent: any = trafficEventStore[nextQueryValue as keyof typeof trafficEventStore];
                            if (trafficEvent){
                                dataObject = trafficEvent.event;
                                break;
                            }
                            else {
                                dispatch(getTrafficEventById(nextQueryValue) as any);
                            }
                            break;
                        case "camera":
                            for (let i = 0; i < cameraData.length; i++){
                                if (cameraData[i].id.toString() === nextQueryValue){
                                    dataObject = cameraData[i] || new ATCamera();
                                    break;
                                }
                            }
                            break;
                        case "weather-alert":
                            let weatherAlert: any = weatherAlertStore[nextQueryValue as keyof typeof weatherAlertStore];
                            if (weatherAlert){
                                dataObject = weatherAlert.alert;
                                break;
                            }
                            else {
                                dispatch(getById(nextQueryValue) as any);
                            }
                            break;
                        case "state-facility":
                            for (let i = 0; i < facilityData.length; i++){
                                if (facilityData[i].id.toString() === nextQueryValue){
                                    dataObject = facilityData[i] || new ATFacility();
                                    break;
                                }
                            }
                            break;
                        case "message-sign":
                            for (let i = 0; i < messageSignData.length; i++){
                                if (messageSignData[i].id.toString() === nextQueryValue){
                                    dataObject = messageSignData[i] || new ATMessageSign();
                                    break;
                                }
                            }
                            break;
                        case "ferry":
                            for (let i = 0; i < ferryData.length; i++){
                                if (ferryData[i].id.toString() === nextQueryValue){
                                    dataObject = ferryData[i] || new ATFerry();
                                    break;
                                }
                            }
                            break;
                        default:
                            dataObject = null;
                            nextQueryType = "Unknown";
                            break;
                    }

                    if (dataObject) break;
                }
            }

            let detailModalObject: IMarkerTapObject = {
                data: dataObject, 
                type: (nextQueryType === "traffic-event") 
                    ? dataObject?.type.toLowerCase() 
                    : nextQueryType
            }

            if (dataObject){                
                setSearchParams({});

                detailModalContext.setModalContent(
                    <ObjectDetailModal object={detailModalObject} 
                        doneCallback={() => detailModalContext.setShowModal(false)} />
                );
                detailModalContext.setShowModal(true);
            }
            else if (nextQueryValue){
                detailModalContext.setModalContent(
                    <ObjectDetailModal loading={true} object={detailModalObject} 
                        doneCallback={() => detailModalContext.setShowModal(false)} />
                );
                detailModalContext.setShowModal(true);
            }
        }, [
            searchParams, 
            cameraStore, facilityStore, messageSignStore, ferryStore, weatherAlertStore
        ]
    );
};