// libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { faQuestion } from "@fortawesome/pro-regular-svg-icons"; 
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type IProps = {
    callback: () => void;
    label?: string;
    iconProp?: IconProp;
    styledOverwrite?: string;
};

export const OverlayButton: React.FC<IProps> = (props) => {

    const { iconProp = faQuestion, label, callback } = props;

    const [showLabel, setShowLabel] = React.useState<boolean>(false);

    return (
        <SC.MouseOverWrapper
            showLabel={showLabel}
            onClick={ () => callback()}
            onMouseEnter={() => setShowLabel(true)} 
            onMouseLeave={ () => setShowLabel(false)}
        >

            <SC.StyledOverlayButton
                styleOverwrite={props.styledOverwrite}
                showLabel={showLabel}
            >

                { label &&
                    <SC.Label showLabel={showLabel}>
                        {label}
                    </SC.Label> 
                }

                <FontAwesomeIcon
                    className="fa-fw"
                    icon={iconProp}
                />
            </SC.StyledOverlayButton>

        </SC.MouseOverWrapper>
    )
};

export default OverlayButton;