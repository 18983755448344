// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import ProgressBar from "~/components/views/progress-bar/ProgressBar";
import WeatherRadarContext from "~/contexts/WeatherRadarContext";

export type IProps = {
    isSmallMax?: boolean;
};

export const WeatherRadarLegend: React.FC<IProps> = (props) => {

    const { sm: isMediumMax } = useBreakpoint();

    const weatherRadarContext: any = React.useContext(WeatherRadarContext);

    
        return (
            <SC.Legend>
                <SC.LegendItems isMediumMax={isMediumMax}>
                    <SC.WeatherRadarLegendItems>
                        <FontAwesomeIcon
                            className="fa-fw"
                            size={"sm"}
                            color="rgba(235,235,245, 1)"
                            icon={regular("radar")}
                        />
                        <SC.WeatherRadarProgressItems>
                            <SC.WeatherRadarTime>{getTime(weatherRadarContext.current)}</SC.WeatherRadarTime>
                            <ProgressBar 
                                color={"rgba(235,235,245, .6)"} 
                                bgColor={"rgba(235,235,245, .3)"}
                                height={"2px"}
                                progress={`${calculateElapsedPercent(weatherRadarContext.start, weatherRadarContext.end, weatherRadarContext.current)}`} 
                            />
                        </SC.WeatherRadarProgressItems>
                    </SC.WeatherRadarLegendItems>
                </SC.LegendItems>
            </SC.Legend>
        );

};

export default WeatherRadarLegend;

const getTime = (date: string) => {
    return new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });;
}

const calculateElapsedPercent = (start: string, end: string, current: string): number => {

    let startDate = new Date(start).valueOf();
    let endDate = new Date(end).valueOf();
    let currentDate = new Date(current).valueOf();

    return Math.round( 
        // time elapsed from start of event until now
        (currentDate - startDate) /
        // divided by the total duration of the event
        (endDate - startDate)
        // by 100 to convert to percent
        * 100
    );
};