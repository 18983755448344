// libraries
import * as React from "react";
// hooks & context
import { useLayerOptions } from "./hooks";
// styles
import * as SC from "./Styled";
// components
import FullPageModal from "~/components/views/modal/FullPageModal";
import IconToggleList from "~/components/views/icon-toggle-list/IconToggleList";
import SchemeSelectList from "~/components/views/scheme-select-list/SchemeSelectList";
import { useBreakpoint } from "~/library/useBreakpoint";
// constants
import { lane_closed, lane_median, lane_open } from "~/constants";
import { useSelector } from "react-redux";
import { IMapLayers } from "~/store/map-layers/map-layers";
import { EAlgoLayerType } from "~/interfaces";
import { PrecipitationLegend } from "../map-overlay-views/legend-section/legends/PrecipitationLegend";
import TrafficSpeedLegend from "../map-overlay-views/legend-section/legends/TrafficSpeedLegend";

export type IProps = {
    modalOpen: boolean;
    closeCallback: () => any;
};

export const MapLayersModal: React.FC<IProps> = (props) => {

    const { modalOpen, closeCallback } = props;

    const layerOptions = useLayerOptions();
    
    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();

    const mapLayers: IMapLayers = useSelector((state: any) => state["map-layers"].mapLayers);
    const isTrafficActive: boolean = mapLayers[EAlgoLayerType["traffic-layer"]];
    const isWeatherRadarActive: boolean = mapLayers[EAlgoLayerType["weather-radar"]];

    if (!modalOpen) return null;

    return (
        <FullPageModal showCloseButton={false} closeCallback={closeCallback}>

            <SC.ModalCard large={true}>
                <IconToggleList title={"Map Layers"} options={layerOptions} closeCallback={closeCallback} />

                <SchemeSelectList title={"Map Types"} />

                {!isSmallMax && 
                    <SC.Wrapper>
                        {isTrafficActive && 
                            <TrafficSpeedLegend modal={true} />
                        }
                        {isWeatherRadarActive && 
                            <PrecipitationLegend modal={true} />
                        }
                    </SC.Wrapper>
                }
            </SC.ModalCard>

        </FullPageModal>
    );

};

export default MapLayersModal;