// libraries
import styled from "styled-components";
import { flexSmart, absoluteBuilder } from "@caps-mobile/styled-lib";
import { whenSmallMax } from "~/theme";

export const StyledFullPageModal = styled.div`
    ${absoluteBuilder(null, [0, "", "", 0])}
    z-index: 999;

    height: 100%;
    width: 100%;

    padding: 20px;
    box-sizing: border-box;

    ${flexSmart("center center")}

    background-color: rgba(0,0,0, 0.65);

    ${whenSmallMax(`
        padding: 0 0;
    `)}
`;

export const CloseButtonWrapper = styled.div`
    ${absoluteBuilder(null, ["25px", "25px", "", ""])}
    z-index: 1000;

    height: 32px;
    width: auto;

    ${flexSmart("center center")}
`;

export const CloseButton = styled.button`
    width: auto;
    height: 100%;

    ${flexSmart("center center")}

    border: thin solid rgba(255,255,255, 0.5);
    border-radius: 4px;

    color: rgba(0,0,0,0.75);
    background-color: rgba(255,255,255, 1);

    :hover{
        cursor: pointer;
    }
`;
