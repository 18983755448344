// libraries
import * as React from "react";
// styles
// components
// context
import { hasAuthParams, useAuth } from "react-oidc-context";
// constants


export type IProps = {
    //
};

export const SilentRenew: React.FC<IProps> = (props) => {
    
    const auth = useAuth();
    //alert("reached silent renew comp");
    React.useEffect(
        () => auth.startSilentRenew(),
        []
    );

    return (
        <div>Silent Rewnew Comp</div>
    )
};

export default SilentRenew;

