import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";
import { whenXLargeMin } from "./breakpoints";

export const UI = (colors: any) => {
    return {
        "button-type": "text-button",
        "nav-button-type": "nav-text-button",
        "text-button": {
            default: `
                ${flexSmart("center center")}

                height: 24px;
                margin: 0 2px;
                padding: 11px 26px;

                border-radius: 10px;

                color: #000000;
                
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 500;
                font: SF Pro Text;

                text-transform: capitalize;

                :hover {
                    color: ${colors["algo-green"]["rgba"]};
                    cursor: pointer;
                }

                ${whenXLargeMin(`
                    padding: 11px 20px;
                `)}
            `,
            selected: `
                ${flexSmart("center center")}

                height: 24px;
                margin: 0 8px;
                padding: 11px 26px;

                border-radius: ${BORDER_RADIUS}px;

                color: #FFFFFF;
                background-color: ${colors["algo-green"]["rgba"]};
                
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 500;
                font: SF Pro Text;

                text-transform: capitalize;

                :hover {
                    cursor: pointer;
                }

                ${whenXLargeMin(`
                    padding: 11px 20px;
                `)}
            `
        },
        "nav-text-button": {
            default: `
                ${flexSmart("center center")}

                height: 24px;
                margin: 0 8px;
                padding: 11px 26px;

                border-radius: ${BORDER_RADIUS}px;

                color: #000000;
                
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 500;
                font: SF Pro Text;

                text-transform: capitalize;

                :hover {
                    color: ${colors["algo-green"]["rgba"]};
                    cursor: pointer;
                }

                ${whenXLargeMin(`
                    padding: 11px 20px;
                `)}
            `,
            selected: `
                ${flexSmart("center center")}

                height: 24px;
                margin: 0 8px;
                padding: 11px 26px;

                border-radius: ${BORDER_RADIUS}px;

                color: #FFFFFF;
                background-color: ${colors["algo-green"]["rgba"]};
                
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 500;
                font: SF Pro Text;

                text-transform: capitalize;

                :hover {
                    cursor: pointer;
                }

                ${whenXLargeMin(`
                    padding: 11px 20px;
                `)}
            `,
        }
};
};

export default UI;