// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { blue_ticker } from "~/constants";
// constants

export type ITickerProps = {
    color?: string;
    panelOpen?: boolean;
    isSmallMax?: boolean;
};

export const StyledTicker = styled.div<ITickerProps>`
    height: 21px;
    width: 100%;

    padding: 0 4px 0 4px;

    box-sizing: border-box;

    ${flexSmart("flex-start center")}
    white-space: nowrap;

    overflow: auto;

    background-color: ${props => props.color || blue_ticker};

    color: white;

    ${RemoveScrollbar}
`;

export const TickerItem = styled.span`

    : hover{
        cursor: pointer;
    }
`;