// libraries
import { useEffect } from "react";
import { createHmac } from 'crypto-browserify';
import React from "react";
import { useSelector } from "react-redux";
import { IMapLayers } from "~/store/map-layers/map-layers";
import { EAlgoLayerType } from "~/interfaces";
// consts
import { weather_radar_number_layers, weather_radar_base_url, 
  weather_radar_configuration, weather_radar_product, weather_radar_products,
  weather_radar_animation_interval } from "~/constants";
import WeatherRadarContext from "~/contexts/WeatherRadarContext";


export const useWeatherRadar = (
    map: H.Map | undefined
) => {
    const weatherRadarKeys: any = useSelector((state: any) => state["weather-radar"]);
    const [layers, setLayers] = React.useState<any[]>([]);
    const [providers, setProviders] = React.useState<any[]>([]);
    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
    const [data, setData] = React.useState<any[]>([]);

    const weatherRadarContext: any = React.useContext(WeatherRadarContext);

    const mapLayers: IMapLayers = useSelector((state: any) => state["map-layers"].mapLayers);
    const isActive: boolean = mapLayers[EAlgoLayerType["weather-radar"]];

    useEffect(() => {
        if (map && isActive && weatherRadarKeys.data) {
            let cancelled = false; // To handle cleanup in async operations
            const fetchData = async () => {
                try {
                    const metaurl = getMetaUrl(weatherRadarKeys);
                    if(metaurl){
                        const response = await fetch(metaurl);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        const text = await response.text();
                        const match = text.match(/\?\((.*)\);/);
                        if (!match) throw new Error('invalid JSONP response');
                        const JSONData = JSON.parse(match[1]);
                        if (!cancelled) {
                            setData(JSONData);
                        }
                    }
                    else{
                        console.error('Error fetching metaUrl');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();

            return () => {
                cancelled = true; // Cleanup to avoid setting state on unmounted component
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null; // Clear the ref value
                }
            };
        }
    }, [map, isActive, weatherRadarKeys]);

    useEffect(() => {
        if (map && isActive && data.length > 0) {
            // Clean up previous layers and providers
            layers.forEach(layer => map?.removeLayer(layer));
            providers.forEach(provider => provider.dispose());

            const newProviders: any[] = [];
            const newOverlays: any[] = [];

            const timeData = getTimes(data);
            const times = timeData?.times;
            const instanceTime = timeData?.instanceTime;

            weatherRadarContext.setStart(times[0]?.toString());
            weatherRadarContext.setEnd(times[times.length - 1]?.toString());

            times.forEach((time: any ) => {
                let H: any = (window as any).H;
                const tileProvider = new H.map.provider.ImageTileProvider({
                    opacity: 0,
                    engineType: H.Map.EngineType.HARP,
                    getURL: (row: number, column: number, zoom: number) => {
                        const numTilesY = (1 << zoom) - 1;
                        const newY = numTilesY - column;
                        return getUrl(weatherRadarKeys, instanceTime, zoom, row, newY, time);
                    }
                });

                const layer = new H.map.layer.TileLayer(tileProvider, { opacity: 1 });
                newProviders.push(tileProvider);
                newOverlays.push(layer);
                map.addLayer(layer);
            });

            setProviders(newProviders);
            setLayers(newOverlays);

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            if (newProviders.length > 0) {
                let i = 0;
                const interval = setInterval(() => {
                    newProviders.forEach((provider, index) => {
                        if (i === index) {
                            weatherRadarContext.setCurrent(times[i]);
                            provider.setOpacity(0.5);
                        } else {
                            provider.setOpacity(0);
                        }
                    });
                    i = (i + 1) % newProviders.length;
                }, weather_radar_animation_interval);

                intervalRef.current = interval;
            }
        } else if (!isActive) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            layers.forEach(layers => map?.removeLayer(layers));
            providers.forEach(provider => provider.dispose());
            setLayers([]);
            setProviders([]);
        }
    }, [map, isActive, data, weatherRadarKeys]);
};

export const getMetaUrl = (
    weatherRadarKeys: any
) => {
    const signature = get_signature(weatherRadarKeys.data.key, weatherRadarKeys.data.secret);
    switch(weather_radar_product) { 
        case weather_radar_products.future: { 
            return `${weather_radar_base_url}/${weatherRadarKeys.data.key}/meta/tiles/product-instances/${weather_radar_product}/${weather_radar_configuration}.jsonp?${signature}&page_size=${1}&callback=?`;
        } 
        case weather_radar_products.past: { 
            return `${weather_radar_base_url}/${weatherRadarKeys.data.key}/meta/tiles/product-instances/${weather_radar_product}/${weather_radar_configuration}.jsonp?${signature}&page_size=${weather_radar_number_layers - 1}&callback=?`;
        } 
        default: { 
           break; 
        } 
    }
}

export const getTimes = (
    data: any
) => {
    switch(weather_radar_product) { 
        case weather_radar_products.future: { 
            const times = data[0].valid_times.reverse().slice(0, weather_radar_number_layers);
            const instanceTime = data[0].time;
            return {times: times, instanceTime: instanceTime}
        } 
        case weather_radar_products.past: { 
            const times = data.reverse().map((instance: { time: any; }) => instance.time);
            return {times: times, instanceTime: null}
        } 
        default: { 
           break; 
        } 
    }
}

export const getUrl = (
    weatherRadarKeys: any,
    instanceTime: any,
    zoom: any,
    row: any,
    newY: any,
    time: any
) => {
    const signature = get_signature(weatherRadarKeys.data.key, weatherRadarKeys.data.secret);
    switch(weather_radar_product) { 
        case weather_radar_products.future: { 
            return `${weather_radar_base_url}/${weatherRadarKeys.data.key}/tms/1.0.0/${weather_radar_product}+${weather_radar_configuration}+${instanceTime}/${zoom}/${row}/${newY}.png?${signature}&valid_time=${time}`;
        } 
        case weather_radar_products.past: { 
            return `${weather_radar_base_url}/${weatherRadarKeys.data.key}/tms/1.0.0/${weather_radar_product}+${weather_radar_configuration}+${time}/${zoom}/${row}/${newY}.png?${signature}`;
        } 
        default: { 
           break; 
        } 
    }
}

//get timestamp and signature for baron weather api call
const get_signature = (key: string, secret: string) => {
    const ts = Math.round(new Date().getTime() / 1000); // Unix timestamp
    const stringToSign = key + ':' + ts;
    const hmac = createHmac('sha1', secret);
    hmac.update(stringToSign);
    const hmacResult = hmac.digest('base64');

    // Convert HMAC result to Base64 and replace characters
    let sig = hmacResult.toString();
    sig = sig.replace(/\+/g, '-').replace(/\//g, '_');

    return `ts=${ts}&sig=${sig}`;
};